import { Injectable ,Inject } from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerInformationService {
  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

 
  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl1;
    } else {
      return recoveryConfig.recoveryServiceUrl1;
    }
  }


  //Customer Information
  getCustomer_information(contractNo:string): Observable<any>{
      const url = `${this.apiUrl}/contract-details/getClientsByfacNo/${contractNo}`;
      return this.http.get<any>(url);
  }
}
