<div class="beautiful-border">
  <mat-card class="custom-card">
    <mat-card-header>
      <mat-icon class="summary-icon">summarize</mat-icon>
      <mat-card-title class="summary-title">Report Summary</mat-card-title>
    </mat-card-header>

    <!-- <hr /> -->
    <!-- <div *ngFor="let topping of toppingList; let i = index">
      <input type="checkbox" [(ngModel)]="checkboxStatuses[i]" />
      <span>{{ topping.label }}</span>
    </div> -->

    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-4">
          <!-- First column -->

          <div class="form-group">
            <label for="facilityNo">Facility No</label>
            <input class="mb-3 form-control" type="text" placeholder="Facility No" formControlName="facilityNo"
              style="background-color: #f0f0f0; cursor: pointer;" readonly />
          </div>

          <div class="form-group">
            <label for="subject" [class.required-label]="myForm.get('subject').hasError('required')">
              Subject
            </label>
            <input formControlName="subject" class="mb-3 form-control" type="text" placeholder="Arreas Reports" />
          </div>

          <!-- <div class="form-group">
            <label for="location" >Location</label>
            <input formControlName="location" class="mb-3 form-control" type="text" placeholder="Location" />
          </div> -->

          <div class="form-group">
            <mat-label>Activity Type</mat-label>
            <mat-select class="mb-3 form-control" formControlName="act_type" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="call">Call</mat-option>
              <mat-option value="Chat">Chat</mat-option>
              <mat-option value="Demo">Demo</mat-option>
              <mat-option value="E-mail">E-mail</mat-option>
              <mat-option value="Meeting">Meeting</mat-option>
              <mat-option value="SMS">SMS</mat-option>
              <mat-option value="Text">Text</mat-option>
              <mat-option value="Visit">Visit</mat-option>
              <mat-option value="Web">Web</mat-option>
            </mat-select>
          </div>

          <div class="form-group">
            <label for="business_unit">Business Unit</label>
            <input formControlName="business_unit" class="mb-3 form-control" type="text" placeholder="Leasing" />
          </div>
          <!-- <div class="form-group">
            <label for="business_unit">Business Unit</label>
            <input class="mb-3 form-control" type="text" placeholder="Leasing" formControlName="business_unit"/>
          </div> -->

          <!-- <div class="form-group">
            <section class="example-section">
              <mat-checkbox class="example-margin" formControlName="all_day">All Day</mat-checkbox>
            </section>
          </div> -->

          <div class="form-group">
            <label for="dateRange">Start Date & End Date</label>
            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="start_date" placeholder="Start date" />
                <input matEndDate formControlName="end_date" placeholder="End date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <!-- <div class="form-group">
            <label for="start_time">Start Time</label>
            <input formControlName="start_time" class="mb-3 form-control" type="text" placeholder="{{ getCurrentTime() }}" readonly>
          </div> -->
          <div class="form-group">
            <label for="start_time">Call Start Time</label>
            <input formControlName="start_time" class="mb-3 form-control" type="text"
              style="background-color: #f0f0f0; cursor: pointer;" readonly>
          </div>

          <!-- <div class="form-group">
            <mat-label [class.required-label]="myForm.get('owner').hasError('required')">Owner</mat-label>
            <mat-select class="mb-3 form-control" formControlName="owner" placeholder="-- Select --">
              <mat-option >-- Select --</mat-option>
              <mat-option value="cfl_admin_1">CFL Admin 1</mat-option>
              <mat-option value="cfl_admin_2">CFL Admin 2</mat-option>
              <mat-option value="cfl_admin_3">CFL Admin 3</mat-option>
              <mat-option value="cfl_admin_4">CFL Admin 4</mat-option>
            </mat-select>
          </div> -->

          <div class="form-group">
            <label for="primaryContact">Primary Contact</label>
            <input class="mb-3 form-control" type="text" placeholder="Primary Contact"
              formControlName="primaryContact" />
          </div>
          <div class="form-group">
            <label for="account">Account</label>
            <input class="mb-3 form-control" type="text" placeholder="Account" formControlName="account" />
          </div>
          <!-- <div class="examplebutton">
            <button mat-raised-button color="warn" (click)="facilityInquiry()">Facility Details</button>
          </div>
          <br> -->
        </div>

        <div class="col-md-4">
          <!-- Second column -->

          <!-- <div class="form-group">
            <mat-label>Lead</mat-label>
            <mat-select class="mb-3 form-control" formControlName="lead" placeholder="-- Select --">
              <mat-option >-- Select --</mat-option>
              <mat-option value="lead_1">CFL Lead 1</mat-option>
              <mat-option value="lead_2">CFL Lead 2</mat-option>
              <mat-option value="lead_3">CFL Lead 3</mat-option>
              <mat-option value="lead_4">CFL Lead 4</mat-option>
            </mat-select>
          </div> -->



          <div class="form-group">
            <label for="phone">Phone</label>
            <input class="mb-3 form-control" type="text" placeholder="Phone" formControlName="phone" />
          </div>
          <!-- 
          <div class="form-group">
            <mat-label [class.required-label]="myForm.get('language').hasError('required')">Preferred Language</mat-label>
            <mat-select class="mb-3 form-control" formControlName="language" placeholder="-- Select --">
              <mat-option >-- Select --</mat-option>
              <mat-option value="sinhala">Sinhala</mat-option>
              <mat-option value="english">English</mat-option>
              <mat-option value="tamil">Tamil</mat-option>
            </mat-select>
          </div> -->

          <!-- <div class="form-group">
            <mat-label>Qustion List</mat-label>
             <mat-select  class="mb-3 form-control" placeholder="Select Question" formControlName ="Qustion_list"  multiple>
            <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
           </mat-select>
          </div> -->

          <!-- <div class="form-group">
            <label for="objective">Objective</label>
            <input
              class="mb-3 form-control"
              type="text"
              placeholder="None"
              formControlName="objective"
            />
          </div> -->

          <!-- <div class="form-group">
            <label class="recording_st" for="recording" >Recording</label>
          </div> -->

          <!-- <div class="form-group">
            <section class="example-section">
              <mat-checkbox class="example-margin" formControlName="recovery">Recovery</mat-checkbox>
            </section>
          </div> -->

          <!-- <div class="form-group">
            <section class="example-section">
              <mat-checkbox class="example-margin" formControlName="multipleContract">Multiple Contract</mat-checkbox>
            </section>
          </div> -->

          <div class="form-group">
            <mat-label>Status</mat-label>
            <mat-select class="mb-3 form-control" formControlName="status" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Not Started">Not Started</mat-option>
              <mat-option value="Complete">Complete</mat-option>
              <mat-option value="On Hold">On Hold</mat-option>
              <mat-option value="Canceled">Canceled</mat-option>
              <mat-option value="In progress">In progress</mat-option>
            </mat-select>
          </div>



          <div class="form-group">
            <mat-label [class.required-label]="myForm.get('callStatus').hasError('required')">Call Status</mat-label>
            <mat-select class="mb-3 form-control" formControlName="callStatus" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Branch_follow-up">Branch follow-up</mat-option>
              <mat-option value="Reachable">Reachable</mat-option>
              <mat-option value="Not_Reachable">Not Reachable</mat-option>
            </mat-select>
          </div>

          <div class="form-group">
            <mat-label [class.required-label]="myForm.get('outcome').hasError('required')">Outcome</mat-label>
            <mat-select class="mb-3 form-control" formControlName="outcome" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>


              <!-- Options for Reachable -->
              <ng-container *ngIf="myForm.get('callStatus').value === 'Reachable'">
                <mat-option value="Client promise to pay">Client promise to pay</mat-option>
                <mat-option value="PDC given to branch">PDC given to branch</mat-option>
                <mat-option value="3rd party promise to pay">3rd party promise to pay</mat-option>
                <mat-option value="Left a message">Left a message</mat-option>
                <mat-option value="Left a message with guarantor">Left a message with guarantor</mat-option>
                <mat-option value="Client convinced to visit the branch">Client convinced to visit the
                  branch</mat-option>
                <mat-option value="Used by 3rd party">Used by 3rd party</mat-option>
                <mat-option value="Customer not agreeable">Customer not agreeable</mat-option>
                <mat-option value="Pending INS Claim">Pending INS Claim</mat-option>
                <mat-option value="Client need to contact collection manager">Client need to contact collection
                  manager</mat-option>
                <mat-option value="Do not call for 10 Days">Do not call for 10 Days</mat-option>
              </ng-container>

              <!-- Options for Not Reachable -->
              <ng-container *ngIf="myForm.get('callStatus').value === 'Not_Reachable'">
                <mat-option value="Ringing no answer">Ringing no answer</mat-option>
                <mat-option value="Switched off">Switched off</mat-option>
                <mat-option value="Number engaged">Number engaged</mat-option>
                <mat-option value="Number not in use">Number not in use</mat-option>
                <mat-option value="Wrong No">Wrong No</mat-option>
                <mat-option value="Cut the line">Cut the line</mat-option>
              </ng-container>

              <!-- Other options -->
              <ng-container *ngIf="myForm.get('callStatus').value === 'Branch_follow-up'">
                <mat-option value="other">Other</mat-option>
              </ng-container>
            </mat-select>
          </div>
          <div class="form-group">
            <mat-label>Additional Outcome</mat-label>
            <mat-select class="mb-3 form-control" formControlName="additionalOutcome" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Complaint">Complaint</mat-option>
            </mat-select>
          </div>
          <div class="form-group">
            <mat-label>Meeting Minutes</mat-label>
            <textarea class="form-control" id="exampleFormControlTextarea1" style="height: 80px;   resize: vertical;"
              formControlName="meetingMinutes"></textarea>
          </div>

          <div class="form-group">
            <mat-label>Results</mat-label>
            <mat-select class="mb-3 form-control" formControlName="results" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Lead Pass">Lead Pass</mat-option>
              <mat-option value="Follow-up">Follow-up</mat-option>
              <mat-option value="Lead Lost">Lead Lost</mat-option>
              <mat-option value="Not interested">Not interested</mat-option>
            </mat-select>
          </div>
          <div class="form-group">
            <mat-label>Payment method</mat-label>
            <!-- <label for="Payment method">Payment method</label>
            <input class="mb-3 form-control" type="text" placeholder="Payment method" formControlName="payment" /> -->
            <mat-select class="mb-3 form-control" formControlName="payment" placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Cash">Cash</mat-option>
              <mat-option value="Bank">Bank</mat-option>
              <mat-option value="Centrix">Centrix</mat-option>
              <mat-option value="Cf click">CF CLICK</mat-option>
              <mat-option value="CDM">CDM</mat-option>
            </mat-select>
          </div>


        </div>

        <!-- third column -->
        <div class="col-md-4">




          <div class="form-group">
            <label for="exampleFormControlTextarea1">First Promise Amount</label>
            <input class="mb-3 form-control" type="number"  placeholder="" formControlName="firstPromiseAmount"
              onblur="this.value = parseFloat(this.value).toFixed(2)" />
          </div>

          <div class="form-group">
            <label for="firstPromiseDate">First Promise Date</label>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker1"  formControlName="firstPromiseDate" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">Second Promise Amount</label>
            <input class="mb-3 form-control" type="number" placeholder="" formControlName="secondPromiseAmount"
              onblur="this.value = parseFloat(this.value).toFixed(2)" />
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">Second Promise Date</label>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker2" formControlName="secondPromiseDate" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-group">
            <label for="Likelihood">Likelihood </label>
            <mat-select class="mb-3 form-control" formControlName="likelihood" [required]="  myForm.get('firstPromiseAmount').value "  placeholder="-- Select --">
              <mat-option>-- Select --</mat-option>
              <mat-option value="Low">Low</mat-option>
              <mat-option value="Medium">Medium</mat-option>
              <mat-option value="High"> High</mat-option>
            </mat-select>
          </div>

          <div class="form-group">
            <mat-label>Question List</mat-label>
            <mat-select class="mb-3 form-control" placeholder="Select Question" formControlName="qType" multiple>
              <mat-option *ngFor="let topping of toppingList" [value]="topping.value">{{topping.label}}</mat-option>
            </mat-select>
          </div>

        </div>
      </div>

      <button class="custom-button" mat-raised-button color="primary" type="submit" [disabled]="myForm.invalid" id="btn">
        Save
      </button>
    </form>

  </mat-card>
</div>