<app-dialog-header
  [title]="headerTitle"
  (closeDialogEvent)="hanldeClose()"
></app-dialog-header>
<!-- <div class="py-4" style="position: relative"> -->
  <!-- Detail -->
  <div class="card-content p-2 w-100">
    <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
      <div class="col-12 col-md-4 py-md-2">
        <mat-card class="" id="test_card_style_id">
          <div class="col-12 card-content-leading">
            <mat-card-content class="">
              <div class="d-flex justify-content-between">
                <p class="textstyle text-uppercase" id="textstyle">
                  Facility No:
                </p>
                <p class="textstyle text-uppercase" id="contractNo">
                  {{ contractNo }}
                </p>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>

      <div class="col-12 col-md-4 py-md-2">
        <mat-card class="" id="test_card_style_id">
          <div class="col-12 card-content-leading">
            <mat-card-content class="test_card">
              <div class="d-flex justify-content-between">
                <p class="textstyle text-uppercase" id="textstyle">Branch:</p>
                <p class="textstyle text-uppercase" id="branch_name"></p>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>

      <div class="col-12 col-md-4 py-md-2">
        <mat-card class="" id="test_card_style_id">
          <div class="col-12 card-content-leading">
            <mat-card-content class="test_card">
              <div class="d-flex justify-content-between">
                <p class="textstyle text-uppercase" id="textstyle">Date:</p>
                <p class="textstyle text-uppercase" id="cfSysDate"></p>
              </div>
            </mat-card-content>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <!-- <div class="spacing"></div> -->
  <!-- Detail end -->
  <div class="frame">
    <mat-accordion>
      <mat-expansion-panel
        class="bordered-panel"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
      >
        <mat-expansion-panel-header>
          <mat-panel-description>
            View Additional Information
            {{ panelOpenState ? "closed" : "open" }}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- Centered container for input fields -->
        <div class="centered-container">
          <!-- First Row -->
          <form class="example-form">
            <div class="form-row">
              <label for="vat" class="view_detail"
                >Vat On Default Chargers:</label
              >
              <input type="float" id="vat" name="vat" disabled />
            </div>
            <div class="form-row">
              <label for="futCap" class="view_detail">Stock Out On:</label>
              <input type="float" id="futCap" name="futCap" disabled />
            </div>
            <div class="form-row">
              <label for="collBal" class="view_detail"
                >Collection Balance:</label
              >
              <input type="float" id="collBal" name="collBal" disabled />
            </div>
            <div class="form-row">
              <label for="futint" class="view_detail">Future Interrest:</label>
              <input type="float" id="futint" name="futint" disabled />
            </div>
          </form>
        </div>
        <br />

        <div class="centered-container">
          <!-- Second Row -->
          <form class="example-form">
            <div class="form-row">
              <label for="totdef" class="view_detail"
                >Total Default Chagers:</label
              >
              <input type="float" id="totdef" name="totdef" disabled />
            </div>
            <div class="form-row">
              <label for="gst" class="view_detail">Future BTT/GST/VAT:</label>
              <input type="text" id="gst" name="gst" disabled />
            </div>
            <div class="form-row">
              <label for="stikBal" class="view_detail">Stock Balance:</label>
              <input type="float" id="stikBal" name="stikBal" disabled />
            </div>
            <div class="form-row">
              <label for="fulbal" class="view_detail">Full Balance:</label>
              <input type="float" id="fulbal" name="fulbal" disabled />
            </div>
          </form>
        </div>
        <br />
        <div class="centered-container">
          <!-- Therd Row -->
          <form class="example-form">
            <div class="form-row">
              <label for="perIod" class="view_detail">Total Rentals:</label>
              <input type="float" id="perIod" name="perIod" disabled />
            </div>
            <div class="form-row">
              <label for="totdebit" class="view_detail">Debited:</label>
              <input type="number" id="totdebit" name="totdebit" disabled />
            </div>
            <div class="form-row">
              <label for="clientName" class="view_detail">Settled:</label>
              <input type="number" id="settledd" name="settledd" disabled />
            </div>
            <div class="form-row">
              <label for="arraes" class="view_detail">Outstanding:</label>
              <input type="number" id="arraes" name="arraes" disabled />
            </div>
          </form>
        </div>
        <br />

        <div class="centered-container">
          <!-- fourth Row -->
          <form class="example-form">
            <div class="form-row">
              <label for="month22" class="view_detail">Month:</label>
              <input
                type="number"
                id="month22"
                name="month22"
                placeholder="Month"
                disabled
              />
            </div>
            <div class="form-row">
              <label for="balanceRental" class="view_detail"
                >Outstanding:</label
              >
              <input
                type="text"
                id="balanceRental"
                name="balanceRental"
                disabled
              />
            </div>
            <div class="form-row">
              <label for="month" class="view_detail">Months(B/W):</label>
              <input type="number" id="month" name="month" disabled />
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- <br /> -->
    <div class="spacing"></div>
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Type Column -->
        <ng-container matColumnDef="dtype">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let transaction">
            {{ transaction.dtype }}
          </td>
          <td class="tot_style" mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>

        <!-- Due Amount -->
        <ng-container matColumnDef="dueAmt">
          <th mat-header-cell *matHeaderCellDef>Due Amount</th>
          <td mat-cell *matCellDef="let transaction">
            {{ transaction.dueAmt | number : "1.2-2" }}
          </td>
          <td class="tot_style" mat-footer-cell *matFooterCellDef>
            {{ getTotalDueAmount() | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- Total Paid Column -->
        <ng-container matColumnDef="totPaid">
          <th mat-header-cell *matHeaderCellDef>Total Paid</th>
          <td mat-cell *matCellDef="let transaction">
            {{ transaction.totPaid | number : "1.2-2" }}
          </td>
          <td class="tot_style" mat-footer-cell *matFooterCellDef>
            {{ getTotalPaid() | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- Waived Column -->
        <ng-container matColumnDef="waived">
          <th mat-header-cell *matHeaderCellDef>Waived</th>
          <td mat-cell *matCellDef="let transaction">
            {{ transaction.waived | number : "1.2-2" }}
          </td>
          <td class="tot_style" mat-footer-cell *matFooterCellDef>
            {{ getTotalWaived() | number : "1.2-2" }}
          </td>
        </ng-container>

        <!-- Outstanding Balance Column -->
        <ng-container matColumnDef="totOutBalance">
          <th mat-header-cell *matHeaderCellDef>Outstanding</th>
          <td mat-cell *matCellDef="let transaction">
            {{ transaction.totOutBalance | number : "1.2-2" }}
          </td>
          <td class="tot_style" mat-footer-cell *matFooterCellDef>
            {{ getTotalOutstandingBalance() | number : "1.2-2" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>
  </div>
<!-- </div> -->
