import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogContent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from '../utils/DurationUtil';

@Component({
  selector: 'app-pop-up-contact-details',
  templateUrl: './pop-up-contact-details.component.html',
  styleUrls: ['./pop-up-contact-details.component.scss']
})
export class PopUpContactDetailsComponent implements OnInit {

  contact_type: string = null;
  priCN: string = null;
  secCN: string = null;
  tpCN: string = null;
  gauCN: string = null;

  constructor(@Inject(MAT_DIALOG_DATA ) data: any, private dialogRef: MatDialogRef<PopUpContactDetailsComponent>, private _snackBar: MatSnackBar) { 

    this.priCN = data?.primary;
    this.secCN = data?.secondary;
    this.tpCN = data?.thirdParty;
    this.gauCN =data?.gaurante;
  }

  ngOnInit(): void {

  }

  dial(){
    if (!this.contact_type) {
      this._snackBar.open('Select a contact number', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      window.open(`tel:${this.contact_type}`);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}

