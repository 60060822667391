export class eqplotByFacnodetailsResponseModel{
    lotno?: string;
    facno?: string;
    eqtype?: string;
    descr?: string;
    noeqpt?: string;
    valeqpt?: string;
    totlamt?: string;
    porderno?: string;
    podate?: string;
    posts?: string;
    supcode?: string;
    appno?: string;
    insadded?: string;
    gstamt?: string;
    cl_title_name?: string;
    facamt?: string;

}