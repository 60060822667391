<ng-template mat-tab-label>
  <mat-icon class="example-tab-icon custom-icon-color">transfer_within_a_station</mat-icon>
  <span id="textstyle">Active</span>
</ng-template>

<div>
  <div>
    <div class="w-100 card-container my-2">
      <div class="card-content w-100">
        <div class="row card-content-row w-100 px-5 px-md-4 ">
          <div class=" col-12 col-md-12 py-md-2">
            <table class="table">
              <thead>
                <tr class="table-primary ">
                  <th scope="col" class="table_col">Description</th>
                  <th scope="col" class="table_col">Details</th>
                  <th scope="col" class="table_col">Values</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="td_name">NO OF CONTRACTS</td>
                  <td class="td_name">
                    <input type="text" id="NOOFCONTRACTS" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="NOOFCONTRACTSValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name1">TOTAL AMOUNT FINANCED</td>
                  <td class="td_name1">
                    <input type="text" id="TOTALAMOUNTFINANCED" name="name" disabled readonly />
                  </td>
                  <td class="td_name1">
                    <input type="text" id="TOTALAMOUNTFINANCEDValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name">MONTHLY COMMITMENT</td>
                  <td class="td_name">
                    <input type="text" id="MONTHLYCOMMITMENT" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="MONTHLYCOMMITMENTValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name1">STOCK BALANCE</td>
                  <td class="td_name1">
                    <input type="text" id="STOCKBALANCE" name="name" disabled readonly />
                  </td>
                  <td class="td_name1">
                    <input type="text" id="STOCKBALANCEValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name">TOTAL AREARS</td>
                  <td class="td_name">
                    <input type="text" id="TOTALARREARS" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="TOTALARREARSValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name1">RENTAL AREARS</td>
                  <td class="td_name1">
                    <input type="text" id="RENTALARREARS" name="name" disabled readonly />
                  </td>
                  <td class="td_name1">
                    <input type="text" id="RENTALARREARSValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name">DEFAULT AREARS</td>
                  <td class="td_name">
                    <input type="text" id="DEFAULTARREARS" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="DEFAULTARREARSValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name1">INSURANCE AREARS</td>
                  <td class="td_name1">
                    <input type="text" id="INSURANCEARREARS" name="name" disabled readonly />
                  </td>
                  <td class="td_name1">
                    <input type="text" id="INSURANCEARREARSValues" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name">AREARS 3 < 6 </td>
                  <td class="td_name">
                    <input type="text" id="ARREARS<3" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="ARREARS<3Values" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name1">AREARS 3 - 6 </td>
                  <td class="td_name1">
                    <input type="text" id="ARREARS3-6" name="name" disabled readonly />
                  </td>
                  <td class="td_name1">
                    <input type="text" id="ARREARS3-6Values" name="name" disabled readonly />
                  </td>
                </tr>
                <tr>
                  <td class="td_name">AREARS 3 > 6 </td>
                  <td class="td_name">
                    <input type="text" id="ARREARS>6" name="name" disabled readonly />
                  </td>
                  <td class="td_name">
                    <input type="text" id="ARREARS>6Values" name="name" disabled readonly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
      </div>
</div>
<br>

<mat-dialog-content class="mat-typography">
  <div class="mat-elevation-z8">
    <table class="custom-table" mat-table [dataSource]="dataSource">
      <!-- SerNo Column -->
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef>Contract</th>
        <td mat-cell *matCellDef="let element">{{ element.contract }}</td>
      </ng-container>

      <!-- User ID  Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Note Date Column -->
      <ng-container matColumnDef="facsts">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.facsts }}</td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Facility Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="noarrs">
        <th mat-header-cell *matHeaderCellDef>Arrears</th>
        <td mat-cell *matCellDef="let element">{{ element.noarrs }}</td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="month">
        <th mat-header-cell *matHeaderCellDef>Months</th>
        <td mat-cell *matCellDef="let element">{{ element.month }}</td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="dpd">
        <th mat-header-cell *matHeaderCellDef>DPD</th>
        <td mat-cell *matCellDef="let element">{{ element.dpd }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
        [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</mat-dialog-content>
