import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { GoogleLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  animations: [
  trigger('fadeInOut', [
    transition(':enter', [
      style({opacity:0}),
      animate(500, style({opacity:1}))
    ]),
    transition(':leave', [
      animate(300, style({opacity:0})) 
    ])
  ])
]
})
export class UserLoginComponent implements OnInit {

  loginForm!: FormGroup;
  errorMsg?: boolean = true;
  user?: SocialUser;
  loggedIn?: boolean;
  contractNo?:string;
  loginFormFromAD: FormGroup = null
  otp: FormGroup = null
  logID: string = null

  constructor(private route: ActivatedRoute,private router: Router, private formBuilder: FormBuilder, private authService: SocialAuthService, private auth: UserAuthenticationService, private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
   
    // Retrieve contractNo from local storage if available
    this.contractNo = localStorage.getItem('contractNo');
    
  
    this.otp = this.formBuilder.group({
      first: [null, Validators.required],
      second: [null, Validators.required],
      third: [null, Validators.required],
      forth: [null, Validators.required],
      fifth: [null, Validators.required],
      sixth: [null, Validators.required]
    })

    this.loginFormFromAD = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    })

    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.authService.authState.subscribe((user) => {
      this.checkLoggedInState(user)
    });

    this.checkLoggedInState(this.auth.currentUserValue)
 
  }

  checkLoggedInState(user: any) {
    this.user = user;
      this.loggedIn = (user != null);
      console.log(this.user);
      if (this.loggedIn) {
        const returnUrl = this.getReturnUrl();
        if (returnUrl.includes('facilityDetails')) {
          this.navigateToDetailsPage();
        } else if (returnUrl.includes('reportSummary')) {
          this.navigateToSummaryPage();
          
        }else if (returnUrl.includes('leadCreation')){
          this.navigateToLeedCreation();
        }else if (returnUrl.includes('misscallleedCreation')){
          this. navigateToMisscallLeedCreation();
        }
        else {
          this.displayLoginError();
        }
      }
  }

  changeModel(): void {
    this.errorMsg = true;
  }

  get loginParams() {
    return this.loginForm.controls;
  }

  submitHandler(e: Event) {
    e.preventDefault();
    if (this.loginFormFromAD.valid) {
      let payload = this.loginFormFromAD.value
      this.auth.userLoginVerificationInit(payload.username, payload.password).subscribe(
        {
          next: value => {
            this.logID = value?.content[0]?.LOGID
            console.log(this.logID);
          },
          error: err => {
            this._snackBar.open("Failed to sign in", "Dismiss", {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            })
          }
        }
      )
    }
  }

  focusNextElement(e: Event, elementRef: HTMLInputElement) {
    elementRef.focus()
  }

  onSubmit(): any {
    if (this.loginForm.valid) {
      this.auth.userLogin(this.loginForm.get("userName").value, "")
        .pipe(first())
        .subscribe({
          next: value => {
            if (value) {
              const returnUrl = this.getReturnUrl();
              if (returnUrl.includes('facilityDetails')) {
                this.navigateToDetailsPage();
              } else if (returnUrl.includes('reportSummary')) {
                this.navigateToSummaryPage();
                
              }else if (returnUrl.includes('leadCreation')){
                this.navigateToLeedCreation();
              }else if (returnUrl.includes('misscallleedCreation')){
                this. navigateToMisscallLeedCreation();
              }
              else {
                this.displayLoginError();
              }
            } else {
              this.displayLoginError();
            }
          },
          error: err => {
            console.log(err);
            this.displayLoginError();
          }
        });
    } else {
      this.displayFormError();
    }
  }
  
  verifyOTP(e: Event) {
    e.preventDefault();
  
    if (this.otp.valid && this.logID && this.loginFormFromAD.valid) {
      let formValue = this.otp.controls;
      let otpCode = Object.entries(formValue).map(item => item[1].value).join("");
      let contractNo = this.contractNo; // Retrieve contractNo from local storage
      this.auth.userVerifyOTP(this.loginFormFromAD.value.username, otpCode, this.logID,).pipe(first()).subscribe({
        next: value => {
          console.log(value);
          const returnUrl = this.getReturnUrl();
          if (returnUrl.includes('facilityDetails')) {
            this.navigateToDetailsPage();
          } else if (returnUrl.includes('reportSummary')) {
            this.navigateToSummaryPage();
          }else if (returnUrl.includes('leadCreation')){
            this.navigateToLeedCreation();
          } else if (returnUrl.includes('misscallleedCreation')){
            this. navigateToMisscallLeedCreation();
          }
          else {
            this.displayVerificationError();
          }
        },
        error: err => {
          console.log(err);
          this.displayVerificationError();
        }
      });
    } else {
      this.displayFormError();
    }
  }
  
  clickLogin(): any {
    alert('clicked');
    const returnUrl = this.getReturnUrl();
    if (returnUrl.includes('facilityDetails')) {
      this.navigateToDetailsPage();
    } else if (returnUrl.includes('reportSummary')) {
      this.navigateToSummaryPage();
    } else if (returnUrl.includes('leadCreation')){
      this.navigateToLeedCreation();
    }else if (returnUrl.includes('misscallleedCreation')){
      this. navigateToMisscallLeedCreation();
    }
    else{

    }
  } 
  
  private navigateToDetailsPage() {
    this.router.navigateByUrl(`/facilityDetails/${this.contractNo}`);
  }
  private navigateToSummaryPage() {
    this.router.navigateByUrl(`/reportSummary/${this.contractNo}`);
  }
  private navigateToLeedCreation(){
    this.router.navigateByUrl(`/leadCreation/${this.contractNo}`);
  }
  private navigateToMisscallLeedCreation(){
    this.router.navigateByUrl(`/misscallleedCreation`);
  }
  private getReturnUrl(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('returnUrl');
  }
  private displayLoginError() {
    this._snackBar.open(`Could not log in to the system`, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  
  private displayVerificationError() {
    this._snackBar.open(`Verification failed`, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
  
  private displayFormError() {
    this._snackBar.open(`Please fill all fields`, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  cancelHandler() {
    this.logID = null
    this.otp.reset()
  }
  signOut(): void {
    this.authService.signOut();
  }
}
