import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { AdminAccessComponent } from '../admin-access/admin-access.component';
import { CallCenterDashboardAccessComponent } from '../call-center-dashboard-access/call-center-dashboard-access.component';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent implements OnInit {

  readonly title: string = 'Admin Portal';
  panelOpenState = false;

  constructor(private dialogRef: MatDialogRef<AdminPortalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: UserAuthenticationService) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  permission_adminAccess() {
    const user_name = this.auth.currentUserValue.username;
    // if (user_name === "ruwann" || user_name === "achalamr" || user_name === "bileshs" || user_name === "damikads" || user_name === "dulsaran" || user_name === "kalanag") {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';

    let dialogRef = this.dialog.open(AdminAccessComponent, {
      ...dialogConfig,
      // data: {
      // }
    })

    // } else {
    //   alert("You don't have access!!!");
    // }

  }

  permission_call_center_dashboard() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';

    let dialogRef = this.dialog.open(CallCenterDashboardAccessComponent, {
      ...dialogConfig,
      // data: {
      // }
    })

  }

  // permission_cashHandover() {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.closeOnNavigation = true;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.height = '100vh';
  //   dialogConfig.width = '100vw';
  //   dialogConfig.maxWidth = '100vw';

  //   let dialogRef = this.dialog.open(AdminAccessCashHandoverComponent, {
  //     ...dialogConfig,
  //     // data: {
  //     // }
  //   })

  //   // const data = {
  //   //   contractNo: this.selectedCallDeskTransaction.contractNo,
  //   //   cType: this.selectedCallDeskTransaction.cType,
  //   // };

  //   // const dialogRef = this.dialog.open(AdminAccessCashHandoverComponent, {
  //   // data: data,
  //   // });
  // }

}
