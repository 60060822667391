<app-dialog-header  [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<div class="spacing"></div>
<div class="beautiful-border">
  <mat-tab-group class="centered-tabs">
    <!-- Transaction Tab -->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon custom-icon-color">account_balance</mat-icon>
        <span style="font-weight: bold">Transaction</span>
      </ng-template>
      <div class="spacing"></div>

      <!-- Expansion panel -->
      <mat-accordion>
        <mat-expansion-panel class="bordered-panel" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- Self-aware panel -->
              <form class="example-form">
                <div class="form-row">
                  <label for="conractNo" style="font-weight: bold">Facility No:</label>

                  <input type="text" id="contractNo" name="contractNo" [(ngModel)]="contractNo" disabled />
                </div>
              </form>
            </mat-panel-title>
            <mat-panel-description>
              View additional information
              {{ panelOpenState ? "closed" : "open" }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Centered container for input fields -->
          <div class="centered-container">
            <!-- First Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="status">Status:</label>
                <input type="text" id="status_t" name="status_t" disabled />
              </div>
              <div class="form-row">
                <label for="vehicleNo">Vehicle No:</label>
                <input type="text" id="vehicleNo" name="vehicleNo" disabled />
              </div>
              <div class="form-row">
                <label for="date">Date:</label>
                <input type="text" id="date" name="date" disabled />
              </div>
              <div class="form-row">
                <label for="payMode">Pay Mode:</label>
                <input type="text" id="payMode" name="payMode" disabled />
              </div>
            </form>
          </div>
          <br />

          <div class="centered-container">
            <!-- Second Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="clientCode">Client Code:</label>
                <input type="text" id="clientCode" name="clientCode" disabled />
              </div>
              <div class="form-row">
                <label for="balanceRental">Balance Rental:</label>
                <input type="text" id="balanceRental" name="balanceRental" disabled />
              </div>
              <div class="form-row">
                <label for="clientName">Client Name:</label>
                <input type="text" id="clientName" name="clientName" disabled />
              </div>
              <div class="form-row">
                <label for="equipment">Equipment:</label>
                <input type="text" id="equipment" name="equipment" disabled />
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- End expansion panel -->
      <div class="spacing"></div>

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. All data" #input />
      </mat-form-field>

      <div class="transaction-container scrollable-container">
        <table class="custom-table" mat-table [dataSource]="dataSource">
          <!-- date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Date.</th>
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
          </ng-container>

          <!-- Time Column -->
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Time</th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>

          <!-- Trn.No Column -->
          <ng-container matColumnDef="trnno">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Trn.No</th>
            <td mat-cell *matCellDef="let element">{{ element.trnno }}</td>
          </ng-container>

          <!-- Receipt No Column -->
          <ng-container matColumnDef="receiptno">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Receipt No</th>
            <td mat-cell *matCellDef="let element">{{ element.receiptno }}</td>
          </ng-container>

          <!-- Trn.Code	 Column -->
          <ng-container matColumnDef="trncode">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Trn.Code</th>
            <td mat-cell *matCellDef="let element">{{ element.trncode }}</td>
          </ng-container>

          <!-- Pay.Mode		 Column -->
          <ng-container matColumnDef="paymode">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Pay.Mode</th>
            <td mat-cell *matCellDef="let element">{{ element.paymode }}</td>
          </ng-container>

          <!-- Cheq.No		 Column -->
          <ng-container matColumnDef="cheqno">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Cheq.No</th>
            <td mat-cell *matCellDef="let element">{{ element.cheqno }}</td>
          </ng-container>

          <!-- Debit	 Column -->
          <ng-container matColumnDef="debit">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Debit</th>
            <td mat-cell *matCellDef="let element">
              {{ element.debit | thousandSeparator }}
            </td>
          </ng-container>

          <!-- Credit Column -->
          <ng-container matColumnDef="credit">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Credit</th>
            <td mat-cell *matCellDef="let element">
              {{ element.credit | thousandSeparator }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
            [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 30, 40]" #paginator1 showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </mat-tab>

    <!-- Rental Debited -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="handleTabClickRental()">
          <mat-icon class="example-tab-icon custom-icon-color">credit_card</mat-icon>
          <span style="font-weight: bold">Rental Debited</span>
        </div>
      </ng-template>
      <div class="spacing"></div>

      <!-- Expansion panel -->
      <mat-accordion>
        <mat-expansion-panel class="bordered-panel" (opened)="panelOpenState_rentDebit = true"
          (closed)="panelOpenState_rentDebit = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- Self-aware panel -->
              <form class="example-form">
                <div class="form-row">
                  <label for="contractNo" style="font-weight: bold">Facility No:</label>
                  <input type="text" id="contractNo" name="contractNo" [(ngModel)]="contractNo" disabled />
                </div>
              </form>
            </mat-panel-title>
            <mat-panel-description>
              View additional information
              {{ panelOpenState_rentDebit ? "closed" : "open" }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Centered container for input fields -->
          <div class="centered-container">
            <!-- First Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="status_r">Status:</label>
                <input type="text" id="status_r" name="status_r" disabled />
              </div>
              <div class="form-row">
                <label for="vehicleNo_r">Vehicle No:</label>
                <input type="text" id="vehicleNo_r" name="vehicleNo_r" disabled />
              </div>
              <div class="form-row">
                <label for="date_r">Date:</label>
                <input type="text" id="date_r" name="date_r" disabled />
              </div>
              <div class="form-row">
                <label for="payMode_r">Pay Mode:</label>
                <input type="text" id="payMode_r" name="payMode_r" disabled />
              </div>
            </form>
          </div>
          <br />

          <div class="centered-container">
            <!-- Second Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="clientCode_r">Client Code:</label>
                <input type="text" id="clientCode_r" name="clientCode_r" disabled />
              </div>
              <div class="form-row">
                <label for="balanceRental_r">Balance Rental:</label>
                <input type="text" id="balanceRental_r" name="balanceRental_r" disabled />
              </div>
              <div class="form-row">
                <label for="clientName_r">Client Name:</label>
                <input type="text" id="clientName_r" name="clientName_r" disabled />
              </div>
              <div class="form-row">
                <label for="equipment_r">Equipment:</label>
                <input type="text" id="equipment_r" name="equipment_r" disabled />
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- End expansion panel -->
      <div class="spacing"></div>

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter_rentDebit($event)" placeholder="Ex. All data" #input />
      </mat-form-field>
      <div class="rental-container scrollable-container">
        <table class="custom-table" mat-table [dataSource]="dataSource_rentDebit">
          <!-- date Column -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Date.</th>
            <td mat-cell *matCellDef="let element">{{ element.dueDate }}</td>
          </ng-container>

          <!-- rental Column -->
          <ng-container matColumnDef="dueAmount">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Rental</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dueAmount | thousandSeparator }}
            </td>
          </ng-container>

          <!-- Capital Due Column -->
          <ng-container matColumnDef="cap">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Capital Due</th>
            <td mat-cell *matCellDef="let element">{{ element.cap }}</td>
          </ng-container>

          <!-- Int Btt Due Column -->
          <ng-container matColumnDef="mintbttDue">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Int Btt Due</th>
            <td mat-cell *matCellDef="let element">{{ element.mintbttDue }}</td>
          </ng-container>

          <!-- Add Chg Due Column -->
          <ng-container matColumnDef="std">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Add Chg Due</th>
            <td mat-cell *matCellDef="let element">{{ element.std }}</td>
          </ng-container>

          <!-- Rental Def Column -->
          <ng-container matColumnDef="rno">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Rental Def</th>
            <td mat-cell *matCellDef="let element">{{ element.rno | thousandSeparator }}</td>
          </ng-container>

          <!-- Rental Def Due Column -->
          <ng-container matColumnDef="rnoArr">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Rental Def Due</th>
            <td mat-cell *matCellDef="let element">
              {{ element.rnoArr }}
            </td>
          </ng-container>

          <!-- Rental Def Waved Column -->
          <ng-container matColumnDef="defwOff">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Rental Def Waved</th>
            <td mat-cell *matCellDef="let element">
              {{ element.defwOff }}
            </td>
          </ng-container>

          <!-- Deffault Beg Date Column -->
          <ng-container matColumnDef="defbigdte">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Deffault Beg Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.defbigdte }}
            </td>
          </ng-container>

          <!-- Next Acc Date Column -->
          <ng-container matColumnDef="nxtOdiDte">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Next Acc Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.nxtOdiDte }}
            </td>
          </ng-container>

          <!-- Good Bad Column -->
          <ng-container matColumnDef="perfNon">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Good Bad</th>
            <td mat-cell *matCellDef="let element">{{ element.perfNon }}</td>
          </ng-container>

          <!-- Ref No Column -->
          <ng-container matColumnDef="refNo">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Ref No</th>
            <td mat-cell *matCellDef="let element">{{ element.refNo }}</td>
          </ng-container>

          <!-- Due Type Column -->
          <ng-container matColumnDef="rnt">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Due Type</th>
            <td mat-cell *matCellDef="let element">{{ element.rnt }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns_rentDebit"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_rentDebit; let i = index;"
            [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 30, 40]" #paginator_rentDebit showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </mat-tab>

    <!-- && (click)="" -->
    <!-- Payment -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="handleTabClickPayment()">
          <mat-icon class="example-tab-icon custom-icon-color">attach_money</mat-icon>
          <span style="font-weight: bold">Payment</span>
        </div>
      </ng-template>
      <div class="spacing"></div>

      <!-- Expansion panel -->
      <mat-accordion>
        <mat-expansion-panel class="bordered-panel" (opened)="panelOpenState_payment = true"
          (closed)="panelOpenState_payment = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- Self-aware panel -->
              <form class="example-form">
                <div class="form-row">
                  <label for="conractNo" style="font-weight: bold">Facility No:</label>
                  <input type="text" id="contractNo" name="contractNo" [(ngModel)]="contractNo" disabled />
                </div>
              </form>
            </mat-panel-title>
            <mat-panel-description>
              View additional information
              {{ panelOpenState_payment ? "closed" : "open" }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Centered container for input fields -->
          <div class="centered-container">
            <!-- First Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="status_p">Status:</label>
                <input type="text" id="status_p" name="status_p" disabled />
              </div>
              <div class="form-row">
                <label for="vehicleNo_p">Vehicle No:</label>
                <input type="text" id="vehicleNo_p" name="vehicleNo_p" disabled />
              </div>
              <div class="form-row">
                <label for="date_p">Date:</label>
                <input type="text" id="date_p" name="date_p" disabled />
              </div>
              <div class="form-row">
                <label for="payMode_p">Pay Mode:</label>
                <input type="text" id="payMode_p" name="payMode_p" disabled />
              </div>
            </form>
          </div>
          <br />

          <div class="centered-container">
            <!-- Second Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="clientCode_p">Client Code:</label>
                <input type="text" id="clientCode_p" name="clientCode_p" disabled />
              </div>
              <div class="form-row">
                <label for="balanceRental_p">Balance Rental:</label>
                <input type="text" id="balanceRental_p" name="balanceRental_p" disabled />
              </div>
              <div class="form-row">
                <label for="clientName_p">Client Name:</label>
                <input type="text" id="clientName_p" name="clientName_p" disabled />
              </div>
              <div class="form-row">
                <label for="equipment_p">Equipment:</label>
                <input type="text" id="equipment_p" name="equipment_p" disabled />
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- End expansion panel -->

      <div class="spacing"></div>

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter_payment($event)" placeholder="Ex. All data" #input />
      </mat-form-field>
      <div class="payment-container scrollable-container">
        <table class="custom-table" mat-table [dataSource]="dataSource_payment">
          <!-- Date Column -->
          <ng-container matColumnDef="payDate">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Date</th>
            <td mat-cell *matCellDef="let element">{{ element.payDate }}</td>
          </ng-container>

          <!-- Tot Paid Column -->
          <ng-container matColumnDef="totPay">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Tot Paid</th>
            <td mat-cell *matCellDef="let element">{{ element.totPay | thousandSeparator }}</td>
          </ng-container>

          <!-- Time Due Column -->
          <ng-container matColumnDef="payTime">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Time</th>
            <td mat-cell *matCellDef="let element">{{ element.payTime }}</td>
          </ng-container>

          <!-- User Column -->
          <ng-container matColumnDef="payUser">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">User</th>
            <td mat-cell *matCellDef="let element">{{ element.payUser }}</td>
          </ng-container>

          <!-- Trn.No Column -->
          <ng-container matColumnDef="transNo">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Trn.No</th>
            <td mat-cell *matCellDef="let element">{{ element.transNo }}</td>
          </ng-container>

          <!-- Receipt No Column -->
          <ng-container matColumnDef="rcpNo">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Receipt No</th>
            <td mat-cell *matCellDef="let element">{{ element.rcpNo }}</td>
          </ng-container>

          <!-- PayMode Column -->
          <ng-container matColumnDef="payMode">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">PayMode</th>
            <td mat-cell *matCellDef="let element">
              {{ element.payMode }}
            </td>
          </ng-container>

          <!-- Cheq.No Column -->
          <ng-container matColumnDef="chqNo">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Cheq.No</th>
            <td mat-cell *matCellDef="let element">
              {{ element.chqNo }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.status }}
            </td>
          </ng-container>

          <!-- Rental Column -->
          <ng-container matColumnDef="rental">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Rental</th>
            <td mat-cell *matCellDef="let element">{{ element.rental | thousandSeparator }}</td>
          </ng-container>

          <!-- Ren Def Column -->
          <ng-container matColumnDef="rentDef">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Ren Def</th>
            <td mat-cell *matCellDef="let element">{{ element.rentDef | thousandSeparator }}</td>
          </ng-container>

          <!-- Vat Column -->
          <ng-container matColumnDef="gst">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Vat</th>
            <td mat-cell *matCellDef="let element">{{ element.gst }}</td>
          </ng-container>

          <!-- VatOnDeff Column -->
          <ng-container matColumnDef="gstDef">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">VatOnDeff</th>
            <td mat-cell *matCellDef="let element">{{ element.gstDef }}</td>
          </ng-container>

          <!-- Ins Column -->
          <ng-container matColumnDef="ins">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Ins</th>
            <td mat-cell *matCellDef="let element">{{ element.ins | thousandSeparator }}</td>
          </ng-container>

          <!-- InsDef Column -->
          <ng-container matColumnDef="insDef">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">InsDef</th>
            <td mat-cell *matCellDef="let element">{{ element.insDef }}</td>
          </ng-container>

          <!-- Other Column -->
          <ng-container matColumnDef="other">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Other</th>
            <td mat-cell *matCellDef="let element">{{ element.other | thousandSeparator }}</td>
          </ng-container>

          <!-- ChqRetPan Column -->
          <ng-container matColumnDef="chr">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">ChqRetPan</th>
            <td mat-cell *matCellDef="let element">{{ element.chr | thousandSeparator }}</td>
          </ng-container>

          <!-- Gsd Column -->
          <ng-container matColumnDef="gsd">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Gsd</th>
            <td mat-cell *matCellDef="let element">{{ element.gsd }}</td>
          </ng-container>

          <!-- Overpayment Column -->
          <ng-container matColumnDef="overPay">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Overpayment</th>
            <td mat-cell *matCellDef="let element">{{ element.overPay }}</td>
          </ng-container>

          <!-- Refund Column -->
          <ng-container matColumnDef="refund">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Refund</th>
            <td mat-cell *matCellDef="let element">{{ element.refund }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns_payment"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_payment; let i = index;"
            [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 30, 40]" #paginator_payment showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </mat-tab>

    <!-- Contract Settlement -->
    <mat-tab>
      <ng-template mat-tab-label>
        <div (click)="handleTabClickContract_sett()">
          <mat-icon class="example-tab-icon custom-icon-color">assignment_turned_in</mat-icon>
          <span style="font-weight: bold">Contract Settlement</span>
        </div>
      </ng-template>
      <div class="spacing"></div>

      <!-- Expansion panel -->
      <mat-accordion>
        <mat-expansion-panel class="bordered-panel" (opened)="panelOpenState_contractSett = true"
          (closed)="panelOpenState_contractSett = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- Self-aware panel -->
              <form class="example-form">
                <div class="form-row">
                  <label for="conractNo" style="font-weight: bold">Facility No:</label>
                  <input type="text" id="contractNo" name="contractNo" [(ngModel)]="contractNo" disabled />
                </div>
              </form>
            </mat-panel-title>
            <mat-panel-description>
              View additional information
              {{ panelOpenState_contractSett ? "closed" : "open" }}
            </mat-panel-description>
          </mat-expansion-panel-header>

          <!-- Centered container for input fields -->
          <div class="centered-container">
            <!-- First Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="status_c">Status:</label>
                <input type="text" id="status_c" name="status_c" disabled />
              </div>
              <div class="form-row">
                <label for="vehicleNo_c">Vehicle No:</label>
                <input type="text" id="vehicleNo_c" name="vehicleNo_c" disabled />
              </div>
              <div class="form-row">
                <label for="date_c">Date:</label>
                <input type="text" id="date_c" name="date_c" disabled />
              </div>
              <div class="form-row">
                <label for="payMode_c">Pay Mode:</label>
                <input type="text" id="payMode_c" name="payMode_c" disabled />
              </div>
            </form>
          </div>
          <br />

          <div class="centered-container">
            <!-- Second Row -->
            <form class="example-form">
              <div class="form-row">
                <label for="clientCode_c">Client Code:</label>
                <input type="text" id="clientCode_c" name="clientCode_c" disabled />
              </div>
              <div class="form-row">
                <label for="balanceRental_c">Balance Rental:</label>
                <input type="text" id="balanceRental_c" name="balanceRental_c" disabled />
              </div>
              <div class="form-row">
                <label for="clientName_c">Client Name:</label>
                <input type="text" id="clientName_c" name="clientName_c" disabled />
              </div>
              <div class="form-row">
                <label for="equipment_c">Equipment:</label>
                <input type="text" id="equipment_c" name="equipment_c" disabled />
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- End expansion panel -->

      <div class="spacing"></div>

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter_contractSett($event)" placeholder="Ex. All data" #input />
      </mat-form-field>
      <div class="contract-container scrollable-container">
        <table class="custom-table" mat-table [dataSource]="dataSource_contractSett">
          <!-- Discription Column -->
          <ng-container matColumnDef="descr">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Discription</th>
            <td mat-cell *matCellDef="let element">{{ element.descr }}</td>
          </ng-container>

          <!-- Values Column -->
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef class="sticky-header">Values</th>
            <td mat-cell *matCellDef="let element">{{ element.value | thousandSeparator }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns_contractSett"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns_contractSett; let i = index;"
            [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[20, 30, 40]" #paginator_contractSett showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
