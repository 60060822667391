import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerContractListComponent } from '../customer-contract-list/customer-contract-list.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FacilityInquiryService } from 'src/app/services/facility-inquiry/facility-inquiry.service';
import { Transaction } from 'src/app/models/facility-inquiry/transaction.model';

// export interface Transaction {
//   dtype: string;
//   dueAmt: string;
//   totPaid: string;
//   waived: string;
//   totOutBalance: string;
// }

@Component({
  selector: 'app-facility-inquiry',
  templateUrl: './facility-inquiry.component.html',
  styleUrls: ['./facility-inquiry.component.scss']
})

export class FacilityInquiryComponent implements OnInit {

  // contractNo = "4733018839";
  // strdate = "08-Jan-23"
  contractNo: string;
  strdate: string;
  In_Bal_Nof_Rentls: string;

  readonly headerTitle: string = "Facility Inquiry";
  panelOpenState = false;

  displayedColumns: string[] = ['dtype', 'dueAmt', 'totPaid', 'waived', 'totOutBalance'];
  // dataSource = new MatTableDataSource<Transaction>(transactions);
  dataSource = new MatTableDataSource<Transaction>([]);

  //Total in table value
  getTotalDueAmount(): number {
    return this.dataSource.data.map(t => parseFloat(t.dueAmt) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalPaid(): number {
    return this.dataSource.data.map(t => parseFloat(t.totPaid) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalWaived(): number {
    return this.dataSource.data.map(t => parseFloat(t.waived) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalOutstandingBalance(): number {
    return this.dataSource.data.map(t => parseFloat(t.totOutBalance) || 0)
      .reduce((acc, value) => acc + value, 0);
  }


  constructor(private facilityInquiryService: FacilityInquiryService,
    private dialogRef: MatDialogRef<FacilityInquiryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.contractNo = this.data.contractNo;
    this.strdate = this.data.strdate;
    this.In_Bal_Nof_Rentls = this.data.In_Bal_Nof_Rentls;

    this.getFacilityInquiryDetails();
    this.getFacilityInquiryBalance();
    this.getFacilityInquiryFlag();
    this.getFacilityInquiryOutstanding();

    this.getFacilityInquiryBranch();
  }

  hanldeClose() {
    this.dialogRef.close();
  }

  // Facility Inquiry Details
  getFacilityInquiryDetails(): void {
    this.facilityInquiryService.getFacilityInquiryDetails(this.contractNo).subscribe(
      (data: any) => {

        console.log("Facility Inquiry Details data is: ", data);
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('vat')?.setAttribute('value', firstItem.vat);
          document.getElementById('month')?.setAttribute('value', firstItem.month);
          document.getElementById('perIod')?.setAttribute('value', firstItem.perIod);
          document.getElementById('arraes')?.setAttribute('value', firstItem.arraes);
          document.getElementById('totdebit')?.setAttribute('value', firstItem.totdebit);
          document.getElementById('settledd')?.setAttribute('value', firstItem.settled);
          const cfSysDate = document.getElementById('cfSysDate');
          cfSysDate.innerText = firstItem.cfSysDate;

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //Facility Inquiry Balance
  getFacilityInquiryBalance(): void {
    this.facilityInquiryService.getFacilityInquiryBalance(this.contractNo, this.strdate).subscribe(
      (data: any) => {

        console.log("Facility Inquiry Balance data is: ", data);
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('collBal')?.setAttribute('value', firstItem.collBal);
          document.getElementById('futint')?.setAttribute('value', firstItem.futint);
          document.getElementById('stikBal')?.setAttribute('value', firstItem.stkBal);
          document.getElementById('futCap')?.setAttribute('value', firstItem.futCap);
          document.getElementById('gst')?.setAttribute('value', firstItem.gst);
          document.getElementById('totdef')?.setAttribute('value', firstItem.totdef);
          document.getElementById('fulbal')?.setAttribute('value', firstItem.fulbal);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //Facility Inquiry Flag
  getFacilityInquiryFlag(): void {
    this.facilityInquiryService.getFacilityInquiryFlag(this.contractNo).subscribe(
      (data: any) => {
        console.log("Facility Inquiry Flag data is: ", data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //Facility Inquiry Outstanding
  getFacilityInquiryOutstanding(): void {
    this.facilityInquiryService.getFacilityInquiryOutstanding(this.contractNo, this.strdate).subscribe(
      (data: any) => {
        console.log("Facility Inquiry Outstanding data is: ", data);
        this.dataSource.data = data as Transaction[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }


  //Facility Inquiry Branch
  getFacilityInquiryBranch(): void {
    this.facilityInquiryService.getFacilityInquiryBranch(this.contractNo).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          const branch = data[0];
          const branchNaemElement = document.getElementById('branch_name');
          if (branchNaemElement) {
            branchNaemElement.innerText = branch.branchname;
          } else {
            console.error("Element with id Branch not found.");
          }
        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
}

const transactions: Transaction[] = [];
