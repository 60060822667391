import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LeadCreationService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient)   {
    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }

  }


  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl;
    } else {
      return recoveryConfig.recoveryServiceUrl;
    }
  }
  //POST LeadCreation
  updateLeadCreation(selectedRows: any): Observable<any> {
    const url = `${this.apiUrl}/leadCreation/createLead`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }
    //POST LeadCreationCustomerInfo
    updateCustomerInfo(selectedRows: any): Observable<any> {
      const url = `${this.apiUrl}/leadCreation/create-customer-info-lead`;
      const requestBody = JSON.stringify(selectedRows);
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.post<any>(
        url,
        requestBody,
        { headers }
      );
    }

  // GET All Branches
  getBranchesList(userName: string): Observable<any> {
    const url = `${this.apiUrl}/leadCreation/getAllBranches/${userName}`;
    return this.http.get<any>(url);
  }
  // GET All Assignees
  getAssigneeList(branchCode: string): Observable<any> {
    const url = `${this.apiUrl}/leadCreation/getAllAssignees/${branchCode}`;
    return this.http.get<any>(url);
  }
    // GET All LmuOfficers
    getLmuOfficersList(userName: string): Observable<any> {
      const url = `${this.apiUrl}/leadCreation/getLmuOfficers/${userName}`;
      return this.http.get<any>(url);
    }
       // GET All channel
       getchannel(userName: string): Observable<any> {
        const url = `${this.apiUrl}/leadCreation/getChannel/${userName}`;
        return this.http.get<any>(url);
      }
       // GET ClientInfo by Phone number
       getClientInfo(phoneNumber: string): Observable<any> {
        const url = `${this.apiUrl}/leadCreation/getClientInfoByPhoneNumber/${phoneNumber}`;
        return this.http.get<any>(url);
      }
}
