<!-- loadingView -->
<!-- <app-dialog-header
  [title]="headerTitle"
  (closeDialogEvent)="hanldeClose()"
></app-dialog-header> -->

<nav class="navbar navbar-expand-lg navbar-dark bg-custom">
  <div class="container-fluid">
    <a
      class="navbar-brand"
      href="favicon.ico"
      rel="icon"
      type="image/x-icon"
    ></a>
    
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page"><mat-icon>home</mat-icon></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" >Contract Details</a>          
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openNotepad()">Notes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="facilityInquiry()">Facility Inquiry</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openTransaction()">Transaction Summary</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openOtherDetails()">Other Details</a>
        </li>
      </ul>
    </div>
    <div class="close-button-row">
      <button
        mat-dialog-close
        mat-mini-fab
        aria-label="Example icon-button with a heart icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</nav>

<br />
<div class="beautiful-border">
  <div style="position: relative">
    <mat-tab-group>
      <mat-tab label="Contract Information">
            <app-contract-information></app-contract-information>
      </mat-tab>
      <mat-tab label="Rental Information">
        <!-- <app-rental-information></app-rental-information>  -->

      <!-- test -->

        <table class="custom-table" mat-table [dataSource]="dataSource">
          <!-- rentals Column -->
          <ng-container matColumnDef="rentals">
            <th mat-header-cell *matHeaderCellDef>rentals</th>
            <td mat-cell *matCellDef="let element">{{ element.rentals }}</td>
          </ng-container>
  
          <!-- grossAmount Column -->
          <ng-container matColumnDef="grossAmount">
            <th mat-header-cell *matHeaderCellDef>grossAmount</th>
            <td mat-cell *matCellDef="let element">{{ element.grossAmount }}</td>
          </ng-container>
  
          <!-- capitalAmount Column -->
          <ng-container matColumnDef="capitalAmount">
            <th mat-header-cell *matHeaderCellDef>capitalAmount</th>
            <td mat-cell *matCellDef="let element">{{ element.capitalAmount }}</td>
          </ng-container>
  
          <!-- capitalPaid No Column -->
          <ng-container matColumnDef="capitaPaid">
            <th mat-header-cell *matHeaderCellDef>capitalPaid</th>
            <td mat-cell *matCellDef="let element">{{ element.capitaPaid }}</td>
          </ng-container>
  
          <!-- interestAmount Column -->
          <ng-container matColumnDef="interestAmount">
            <th mat-header-cell *matHeaderCellDef>interestAmount</th>
            <td mat-cell *matCellDef="let element">{{ element.interestAmount }}</td>
          </ng-container>
  
          <!-- interestPaid		 Column -->
          <ng-container matColumnDef="interestPaid">
            <th mat-header-cell *matHeaderCellDef>interestPaid</th>
            <td mat-cell *matCellDef="let element">{{ element.interestPaid }}</td>
          </ng-container>
  
          <!-- gstDue		 Column -->
          <ng-container matColumnDef="gstDue">
            <th mat-header-cell *matHeaderCellDef>gstDue</th>
            <td mat-cell *matCellDef="let element">{{ element.gstDue }}</td>
          </ng-container>
  
          <!-- gstPaid	 Column -->
          <ng-container matColumnDef="gstPaid">
            <th mat-header-cell *matHeaderCellDef>gstPaid</th>
            <td mat-cell *matCellDef="let element">
              {{ element.gstPaid }}
            </td>
          </ng-container>
  
          <!-- stampDuty Column -->
          <ng-container matColumnDef="stampDuty">
            <th mat-header-cell *matHeaderCellDef>stampDuty</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stampDuty}}
            </td>
          </ng-container>

           <!-- grossPaid Column -->
           <ng-container matColumnDef="grossPaid">
            <th mat-header-cell *matHeaderCellDef>grossPaid</th>
            <td mat-cell *matCellDef="let element">{{ element.grossPaid }}</td>
          </ng-container>
  
          <!-- dueDate		 Column -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>dueDate</th>
            <td mat-cell *matCellDef="let element">{{ element.dueDate }}</td>
          </ng-container>
  
          <!-- status		 Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>
  
          <!-- balance	 Column -->
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef>balance</th>
            <td mat-cell *matCellDef="let element">
              {{ element.balance }}
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumnsRentalInfor"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsRentalInfor; let i = index;" [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
        </table>


      </mat-tab>
      <!-- <mat-tab-group>
        <mat-tab>
          <div>
            <table>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Rental Information"> RentalInformation</mat-tab>
      </mat-tab-group> -->
    </mat-tab-group>
  </div>
</div>