import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { Config } from 'protractor';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilityInquiryService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl1;
    } else {
      return recoveryConfig.recoveryServiceUrl1;
    }
  }

  // Facility Inquiry
  getFacilityInquiryDetails(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/FacilityInquiry/getFacilityInquiryDetails/${contractNo}`;
    return this.http.get<any>(url);
  }

  getFacilityInquiryBalance(contractNo: string, strdate: string): Observable<any> {
    const url = `${this.apiUrl}/FacilityInquiry/getFacilityBalance/${contractNo}/${strdate}`;
    return this.http.get<any>(url);
  }

  getFacilityInquiryFlag(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/FacilityInquiry/getFacilityFlag/${contractNo}`;
    return this.http.get<any>(url);
  }

  getFacilityInquiryOutstanding(contractNo: string, strdate: string): Observable<any> {
    const url = `${this.apiUrl}/FacilityInquiry/getFacilityOutstanding/${contractNo}/${strdate}`;
    return this.http.get<any>(url);
  }

  //branch name
  getFacilityInquiryBranch(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getFacilityDetails/${contractNo}`;
    return this.http.get<any>(url);
  }

}

