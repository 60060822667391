import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';

interface PeriodicElement {
  USER_ID?: string;
  INV_ID?: string;
  INV_NAME?: string;
  NIC?: string;
  INV_BRANCH?: string;
  INV_ACCESS_ROUTE_DETAILS?: string;
  INV_ONBOARD_ACCESS?: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-admin-access',
  templateUrl: './admin-access.component.html',
  styleUrls: ['./admin-access.component.scss']
})
export class AdminAccessComponent implements OnInit {

  // [x: string]: any;
  @ViewChild('paginator') paginator: MatPaginator;

  USER_ID: string;
  dataSourceForSelected = new MatTableDataSource<any>();
  title: string = 'Access Portal - Admin Permission';

  constructor(
    private dialogRef: MatDialogRef<AdminAccessComponent>,
    //  private adminPortalService: AdminPortalService,
    private auth: UserAuthenticationService
  ) {
    this.dataSource = new MatTableDataSource<PeriodicElement>();
  }

  ngOnInit(): void {
    //  this.loadData()
    //  this.loadData2()
  }

  displayedColumns: string[] = ['select', 'status', 'USER_ID', 'INV_NAME', 'INV_ID', 'NIC', 'INV_BRANCH'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(false, []);


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.INV_ACCESS_ROUTE_DETAILS + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  //  loadData() {
  //    const user_name = this.auth.currentUserValue.username;
  //    this.adminPortalService.getAdminPortalAccess(user_name).subscribe((data: PeriodicElement[]) => {
  //      ELEMENT_DATA.length = 0;
  //      ELEMENT_DATA.push(...data);
  //      this.dataSource = new MatTableDataSource(data);

  //      this.selection.clear();

  //      // Filter and select rows based on the condition
  //      // const rowsToSelect = this.dataSource.data.filter(element => element.INV_ACCESS_ROUTE_DETAILS === "0");
  //      // this.selection.select(...rowsToSelect);

  //      this.dataSource.paginator = this.paginator;

  //    });
  //   }


  getStatusStyle(invAccessCashHandover: string): string {
    return invAccessCashHandover === '1' ? 'active-status' : 'inactive-status';
  }

  getStatusColor(invAccessCashHandover: string): string {
    return invAccessCashHandover === '1' ? 'active-color' : 'inactive-color';
  }

  // getStatusText(invAccessCashHandover: string): string {
  //   return invAccessCashHandover === '1' ? 'Active' : 'Inactive';
  // }
  getStatusText(invAccessCashHandover: string): string {
    if (invAccessCashHandover === '1') {
      this.activationActionText = 'Inactive';
      return 'Active';
    } else {
      this.activationActionText = 'Active';
      return 'Inactive';
    }
  }

  sendSelectedRowsToService(selectedRows: any[]) {
    const user_name = this.auth.currentUserValue.username;
    const postData = selectedRows.map(row => {
      return {
        GRANTED_BY: user_name,
        INV_ID: row.INV_ID,
        INV_BRANCH: row.INV_BRANCH,
        INV_ADMIN_PORTAL_ACCESS: row.INV_ACCESS_ROUTE_DETAILS,
        INV_ONBOARD_ACCESS: row.INV_ONBOARD_ACCESS
      };
    });

    //  this.adminPortalService.updateAdminPortalAccessGrant(postData)
    //    .subscribe((response: any) => {
    //      console.log('POST Response:', response);
    //    });
  }

  activationActionText = '';

  toggleActivation() {
    const selectedRows = this.selection.selected;

    console.log('Selected Rows:', selectedRows);
    const hasInactiveRows = selectedRows.some(row => row.INV_ACCESS_ROUTE_DETAILS === '0');

    selectedRows.forEach(row => {
      row.INV_ACCESS_ROUTE_DETAILS = hasInactiveRows ? '1' : '0';
    });

    // Update the button text based on the action
    this.activationActionText = hasInactiveRows ? 'Inactive' : 'Active';

    this.selection.clear();

    console.log('Action:', this.activationActionText);

    this.sendSelectedRowsToService(selectedRows);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
