<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>
<div class="deep-container">
  <!-- <div class="main-container">
        <div class="container-bg">
            <div class="title">
                <h3>Welcome back, {{user}}</h3>
            </div>
        </div>
    </div> -->
  <div class="main-container">
    <div class="container-bg">
      <div class="row">
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="permission_adminAccess()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-admin">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Admin Portal Access</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="permission_call_center_dashboard()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-primary">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div"
                    >Call Center Dashboard Access</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="permission_visitDistance()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-danger">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Tracking report access</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
                    <div class="card-view" >
                        <div class="card-view-inner-div">
                            <div class="card-content">
                                <div class="icon-container icon-container-bg-warning">
                                    <mat-icon class="card-icon" appearance="fill">admin_panel_settings</mat-icon>
                                </div>
                                <div class="card-text">
                                    <span class="card-text-sub-div">Admin Portal</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>
