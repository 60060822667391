import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContractNoService {
  
  private contractNo: string;
  constructor() { }
  setContractNo(contractNo: string) {
    this.contractNo = contractNo;
  }
  getContractNo() {
    return this.contractNo;
  }
}
