<!-- <h2 mat-dialog-title>Notepad Details</h2> -->
<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>
<div class="spacing"></div>
<div class="beautiful-border">
  <div class="spacing"></div>
  <form class="example-form">
    <div class="form-row">
      <label for="conractNo">Facility No: </label>
      <input
        type="text"
        id="conractNo"
        name="conractNo"
        [(ngModel)]="contractNo"
        disabled
      />
    </div>

    <div class="form-row">
      <label for="appNo">Application No:</label>
      <input type="text" id="appNo" name="appNo" [(ngModel)]="appNo" disabled />
    </div>
  </form>
  <div class="spacing"></div>

  <mat-dialog-content class="mat-typography">
    <div class="mat-elevation-z8">
      <table class="custom-table" mat-table [dataSource]="dataSource">
        <!-- SerNo Column -->
        <ng-container matColumnDef="serno">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Serial Number</th>
          <td mat-cell *matCellDef="let element">{{ element.serno }}</td>
        </ng-container>

        <!-- User ID  Column -->
        <ng-container matColumnDef="usrid">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">User ID</th>
          <td mat-cell *matCellDef="let element">{{ element.usrid }}</td>
        </ng-container>

        <!-- Note Date Column -->
        <ng-container matColumnDef="dte_of_nt">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Note Date</th>
          <td mat-cell *matCellDef="let element">{{ element.dte_of_nt }}</td>
        </ng-container>

        <!-- Note Column -->
        <ng-container matColumnDef="descr">
          <th mat-header-cell *matHeaderCellDef class="sticky-header">Note Description</th>
          <td mat-cell *matCellDef="let element">{{ element.descr }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;" [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 20, 30, 40]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <div class="py-3 d-flex">
      <button
        mat-raised-button
        mat-dialog-close
        color="warn"
        style="margin-right: 1rem"
      >
        Close
      </button>
    </div>
  </mat-dialog-actions> -->
</div>
