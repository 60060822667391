import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";

@Injectable()


export class TokenInjector implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem('branchless-token');
      let username = 'cfcrmcc';
      let password = 'cfpk123';

      // Get the current URL
      const url = window.location.href;

 
       if 
       (url.startsWith('http://harmony360.lk/login') ||
       url.startsWith('http://52.14.113.31/login') ||
       url.startsWith('https://harmony360.lk/login') ||
       url.startsWith('https://52.14.113.31/login') ||
       url.startsWith('http://localhost:4200/login') 
    )
       {
       // Set username and password to null
       username = null;
       password = null;
       }

      if (username !== null && password !== null) {
          const credentials = btoa(username + ':' + password);
          const authHeader = 'Basic ' + credentials;

          const clonedReq = req.clone({
              setHeaders: {
                  Authorization: authHeader,
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'GET, PUT, POST, OPTIONS, DELETE',
                  'Access-Control-Allow-Headers': '*'
              }
          });

          return next.handle(clonedReq);
      } else {
          return next.handle(req);
      }
  }
}

