<nav class="navbar navbar-expand-lg navbar-dark bg-custom">
  <div class="container-fluid">
    <a
      class="navbar-brand"
      href="favicon.ico"
      rel="icon"
      type="image/x-icon"
    ></a>

    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page"
            ><mat-icon>home</mat-icon></a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link">Contract Details</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openNotepad()">Notes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="facilityInquiry()">Facility Inquiry</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openTransaction()"
            >Transaction Summary</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="openOtherDetails()">Other Details</a>
        </li>
      </ul>
    </div>
    <!-- <div class="close-button-row">
      <button
        mat-dialog-close
        mat-mini-fab
        aria-label="Example icon-button with a heart icon"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div> -->
  </div>
</nav>

<div class="spacing"></div>

<div class="beautiful-border">
  <div style="position: relative">
    <mat-tab-group mat-align-tabs="start">
      <!-- Contract Information Tab-->
      <mat-tab label="Contract Information">
        <div class="card-content p-2 w-100">

          <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">


              <div class="col-12 col-md-4  ">
                <div class="col-12 col-md-12 ">
                  <mat-card class="test_card" id="test_card_style_id">
                    <div class="col-12 card-content-leading">
                      <mat-card-content>
                        <div class="d-flex justify-content-between">
                          <p class="textstyle text-uppercase" id="textstyle">
                            FACNO
                          </p>
                          <p class="textstyle text-uppercase mat-body-strong" id="contractNo">
                            {{ contractNo }}
                          </p>
                        </div>

                      </mat-card-content>
                    </div>
                  </mat-card>
                </div>
              </div>

              <div class="col-12 col-md-4 ">
                <div class="col-12 col-md-12 ">
                  <mat-card class="test_card" id="test_card_style_id">
                    <div class="col-12 card-content-leading">
                      <mat-card-content>
                        <div class="d-flex justify-content-between align-items-center">
                          <p class="textstyle text-uppercase " id="textstyle">
                            BRANCH
                          </p>
                          <p class="textstyle text-uppercase mat-body-strong" id="branchname"></p>
                        </div>

                      </mat-card-content>
                    </div>
                  </mat-card>
                </div>
              </div>

              <div class="col-12 col-md-4  ">
                <div class="col-12 col-md-12 ">
                  <mat-card class="test_card" id="test_card_style_id">
                    <div class="col-12 card-content-leading">
                      <mat-card-content>
                         <div class="d-flex justify-content-between align-items-center">
                          <p class="textstyle text-uppercase " id="textstyle">
                            NIC
                          </p>
                          <p class="textstyle text-uppercase mat-body-strong" id="idno"></p>
                        </div>
                      </mat-card-content>
                    </div>
                  </mat-card>
                </div>
              </div>

              <div class="col-12 col-md-6 ">
                <div class="col-12 col-md-12 py-md-2">
                  <mat-card class="test_card" id="test_card_style_id">
                    <div class="col-12 card-content-leading">
                      <mat-card-content>
                        <div class="d-flex justify-content-between align-items-center">
                          <p class="textstyle text-uppercase " id="textstyle">
                            TITLE
                          </p>

                          <p class="textstyle mat-body-strong" id="cltitle"></p>
                        </div>
                      </mat-card-content>
                    </div>
                  </mat-card>
                </div>
              </div>

              <div class="col-12 col-md-6 ">
                <div class="col-12 col-md-12 py-md-2">
                  <mat-card class="test_card" id="test_card_style_id">
                    <div class="col-12 card-content-leading">
                      <mat-card-content>
                        <div class="d-flex justify-content-between align-items-center">
                          <p class="textstyle text-uppercase " id="textstyle">CLIENT LAST NAME </p>
                          <p class="textstyle text-uppercase mat-body-strong " id="lname"></p>

                        </div>
                      </mat-card-content>
                    </div>
                  </mat-card>
                </div>
              </div>

            <div class="col-12 col-md-12 ">
              <mat-card class="test_card" id="test_card_style_id">
                <div class="col-12 card-content-leading">
                  <mat-card-content >
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle"> INITIALS IN FULL</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="initials"></p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

          </div>
        </div>
        <!-- <div class="w-100 card-container my-2">
          <div class="card-header w-100 p-2">
            <span>Qustion List</span>
          </div>
          <div class="card-content p-2 w-100">
            <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
              <div class="checkBoxs">
                <mat-checkbox
                  [(ngModel)]="checkbox1"
                  (change)="onCheckboxChange(1)"
                  >Educated regarding the available payment
                  channels</mat-checkbox
                >
                <br />
                <mat-checkbox
                  [(ngModel)]="checkbox2"
                  (change)="onCheckboxChange(2)"
                  >Customer requested to reschedule the contract</mat-checkbox
                >
                <br />
                <mat-checkbox
                  [(ngModel)]="checkbox3"
                  (change)="onCheckboxChange(3)"
                  >Client Knows about The Available Payment
                  Channels</mat-checkbox
                >
              </div>
              <br />
              <div></div>
              <div class="subCon">
                <button mat-raised-button class="custom-button" color="primary">
                  Save Answers
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <div>

        </div>

        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">

            <div class="col-12 col-md-3 ">
              <mat-card class="test_cardX" >
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle"> FACNO</p>
                      <p class="textstyle text-uppercase mat-body-strong" style="color: black;">
                        {{ contractNo }}
                      </p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-3 ">
              <mat-card class="test_cardX">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">FACILITY STATUS</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="facsts" style="color: black;"></p>
                      <!-- <input type="text" id="facsts" name="facsts" disabled /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-3 ">
              <mat-card class="test_cardX">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">FAC. AMOUNT</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="facamt" style="color: black;">{{facamt | thousandSeparator}}</p>
                      <!-- <input type="text" id="facamt" name="facamt" disabled /> -->
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-3 ">
              <mat-card class="test_cardX">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">DESCR</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="descr" style="color: black;"></p>
                      <!-- <input type="text" id="descr" name="descr" disabled /> -->
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

          </div>
        </div>
        <div class="spacing"></div>
   <div class="col-12">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="border-spacing: 0; border-collapse: collapse;">
    <!-- Type Column -->
    <ng-container matColumnDef="dtype">
      <th mat-header-cell *matHeaderCellDef class="tabel-row">Type</th>
      <td mat-cell *matCellDef="let transaction" style="height: 10px;">
        {{ transaction.dtype }}
      </td>
      <td class="tot_style" mat-footer-cell *matFooterCellDef class="tabel-row">Total</td>
    </ng-container>

    <!-- Due Amount -->
    <ng-container matColumnDef="dueAmt">
      <th mat-header-cell *matHeaderCellDef class="tabel-row">Due Amount</th>
      <td mat-cell *matCellDef="let transaction" class="tabel-row">
        {{ transaction.dueAmt | number : "1.2-2" }}
      </td>
      <td class="tot_style" mat-footer-cell *matFooterCellDef class="tabel-row">
        {{ getTotalDueAmount() | number : "1.2-2" }}
      </td>
    </ng-container>

    <!-- Total Paid Column -->
    <ng-container matColumnDef="totPaid">
      <th mat-header-cell *matHeaderCellDef class="tabel-row">Total Paid</th>
      <td mat-cell *matCellDef="let transaction" class="tabel-row">
        {{ transaction.totPaid | number : "1.2-2" }}
      </td>
      <td class="tot_style" mat-footer-cell *matFooterCellDef class="tabel-row">
        {{ getTotalPaid() | number : "1.2-2" }}
      </td>
    </ng-container>

    <!-- Waived Column -->
    <ng-container matColumnDef="waived">
      <th mat-header-cell *matHeaderCellDef class="tabel-row">Waived</th>
      <td mat-cell *matCellDef="let transaction"class="tabel-row">
        {{ transaction.waived | number : "1.2-2" }}
      </td>
      <td class="tot_style" mat-footer-cell *matFooterCellDef class="tabel-row">
        {{ getTotalWaived() | number : "1.2-2" }}
      </td>
    </ng-container>

    <!-- Outstanding Balance Column -->
    <ng-container matColumnDef="totOutBalance">
      <th mat-header-cell *matHeaderCellDef class="tabel-row">Outstanding</th>
      <td mat-cell *matCellDef="let transaction" class="tabel-row">
        {{ transaction.totOutBalance | number : "1.2-2" }}
      </td>
      <td class="tot_style" mat-footer-cell *matFooterCellDef class="tabel-row">
        {{ getTotalOutstandingBalance() | number : "1.2-2" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'" class="tabel-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
        <div class="spacing"></div>
        <!-- testst -->
        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Ceft Account Number</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="ceft_ACCNO"></p>
                      <!-- <input
                      type="text"
                      id="ceft_ACCNO"
                      name="ceft_ACCNO"
                      disabled
                      /> -->
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Last Pay Date</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="ldate"></p>
                      <!-- <input type="text" id="ldate" name="ldate" disabled /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 ">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Interest Rate</p>
                      <!-- <input type="text" id="intrte" name="intrte" disabled /> -->
                      <p class="textstyle text-uppercase mat-body-strong"  id="intrte"></p>
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 ">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Last Pay Amount</p>
                      <p class="textstyle text-uppercase mat-body-strong" id="lstPayAmount">{{lstPayAmount | thousandSeparator}}</p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Activated Date</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="activatedDate"></p>
                      <!-- <input
                      type="text"
                      id="activatedDate"
                      name="activatedDate"
                      disabled
                    /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Status</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="status"></p>
                      <!-- <input type="text" id="status" name="status" disabled /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 ">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">  Expiry Date</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="duedte"></p>
                      <!-- <input type="text" id="duedte" name="duedte" disabled /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 ">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Vehicle No.</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="vehicalNo"></p>
                      <!-- <input
                      type="text"
                      id="vehicalNo"
                      name="vehicalNo"
                      disabled
                    /> -->
                    </div>
                    <div class="col-12 card-content-description">

                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Rental</p>
                      <p class="textstyle text-uppercase mat-body-strong" id="netrntl">{{ netrntl | thousandSeparator}}</p>
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

            <div class="col-12 col-md-6 py-md-2">
              <mat-card class="test_cardZ">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="textstyle text-uppercase" id="textstyle">Eq Type</p>
                      <p class="textstyle text-uppercase mat-body-strong"  id="eqtype"></p>
                      <!-- <input type="text" id="eqtype" name="eqtype" disabled /> -->
                    </div>

                  </mat-card-content>
                </div>
              </mat-card>
            </div>

          </div>
        </div>
        <!-- testend -->

        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">Total Rentals</div>
                    <div class="col-12 card-content-description">
                      <!-- <input type="text" id="perIod_" name="perIod_" disabled /> -->
                      <p class="textstyle text-uppercase" id="perIod_">{{perIod_ | thousandSeparator}}</p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">Debited</div>
                    <div class="col-12 card-content-description">
                      <p class="textstyle text-uppercase" id="totdebit_">{{totdebit_ | thousandSeparator}}</p>
                      <!-- <input
                        type="text"
                        id="totdebit_"
                        name="totdebit_"
                        disabled
                      /> -->
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">Settled</div>
                    <div class="col-12 card-content-description">
                      <p class="textstyle text-uppercase" id="settled_">{{settled_ | thousandSeparator}}</p>
                      <!-- <input
                        type="text"
                        id="settled_"
                        name="settled_"
                        disabled
                      /> -->
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">Outstanding</div>
                    <div class="col-12 card-content-description">
                      <!-- <input type="text" id="arraes_" name="arraes_" disabled /> -->
                      <p class="textstyle text-uppercase" id="arraes">{{arraes| thousandSeparator}}</p>

                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">Months</div>
                    <div class="col-12 card-content-description">MONTH</div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
            <div class="col-12 col-md-2 ">
              <mat-card class="test_cardY">
                <div class="col-12 card-content-leading">
                  <mat-card-content>
                    <div class="col-12 card-content-leading">
                      Outstanding Amount
                    </div>
                    <div class="col-12 card-content-description">
                      <!-- <p class="textstyle text-uppercase" id="outstandingAmount">{{number: '1.2-2' }}</p> -->
                      <p class="textstyle text-uppercase" id="outstandingAmount">{{outstandingAmount| thousandSeparator}}</p>
                    </div>
                  </mat-card-content>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Rental Information Tab-->

      <mat-tab label="Rental Information">
        <table class="mat-elevation-z8" mat-table [dataSource]="dataSource2">
          <!-- rentals Column -->
          <ng-container matColumnDef="rentals">
            <th mat-header-cell *matHeaderCellDef>rentals</th>
            <td mat-cell *matCellDef="let element">{{ element.rentals }}</td>
          </ng-container>

          <!-- grossAmount Column -->
          <ng-container matColumnDef="grossAmount">
            <th mat-header-cell *matHeaderCellDef>grossAmount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.grossAmount }}
            </td>
          </ng-container>

          <!-- capitalAmount Column -->
          <ng-container matColumnDef="capitalAmount">
            <th mat-header-cell *matHeaderCellDef>capitalAmount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.capitalAmount }}
            </td>
          </ng-container>

          <!-- capitalPaid No Column -->
          <ng-container matColumnDef="capitaPaid">
            <th mat-header-cell *matHeaderCellDef>capitalPaid</th>
            <td mat-cell *matCellDef="let element">{{ element.capitaPaid }}</td>
          </ng-container>

          <!-- interestAmount Column -->
          <ng-container matColumnDef="interestAmount">
            <th mat-header-cell *matHeaderCellDef>interestAmount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.interestAmount }}
            </td>
          </ng-container>

          <!-- interestPaid		 Column -->
          <ng-container matColumnDef="interestPaid">
            <th mat-header-cell *matHeaderCellDef>interestPaid</th>
            <td mat-cell *matCellDef="let element">
              {{ element.interestPaid }}
            </td>
          </ng-container>

          <!-- gstDue		 Column -->
          <ng-container matColumnDef="gstDue">
            <th mat-header-cell *matHeaderCellDef>gstDue</th>
            <td mat-cell *matCellDef="let element">{{ element.gstDue }}</td>
          </ng-container>

          <!-- gstPaid	 Column -->
          <ng-container matColumnDef="gstPaid">
            <th mat-header-cell *matHeaderCellDef>gstPaid</th>
            <td mat-cell *matCellDef="let element">
              {{ element.gstPaid }}
            </td>
          </ng-container>

          <!-- stampDuty Column -->
          <ng-container matColumnDef="stampDuty">
            <th mat-header-cell *matHeaderCellDef>stampDuty</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stampDuty }}
            </td>
          </ng-container>

          <!-- grossPaid Column -->
          <ng-container matColumnDef="grossPaid">
            <th mat-header-cell *matHeaderCellDef>grossPaid</th>
            <td mat-cell *matCellDef="let element">{{ element.grossPaid }}</td>
          </ng-container>

          <!-- dueDate		 Column -->
          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>dueDate</th>
            <td mat-cell *matCellDef="let element">{{ element.dueDate }}</td>
          </ng-container>

          <!-- status		 Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>status</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>

          <!-- balance	 Column -->
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef>balance</th>
            <td mat-cell *matCellDef="let element">
              {{ element.balance }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsRentalInfor"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: displayedColumnsRentalInfor;
              let i = index
            "
            [ngClass]="i % 2 === 0 ? 'mat-row-even' : 'mat-row-odd'"
          ></tr>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
