import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OtherDetailsModel } from 'src/app/models/other-details/OtherDetailModel';
import { OtherDetailsService } from 'src/app/services/otherDetailsService/otherDetails.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reschedule-transferre',
  templateUrl: './reschedule-transferre.component.html',
  styleUrls: ['./reschedule-transferre.component.scss']
})
export class RescheduleTransferreComponent implements OnInit {

  constructor(private OtherDetailsService: OtherDetailsService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.getFacilityList();
  }

  contractNo = "198111304864";
  ContractData: any;
  dataSource = new MatTableDataSource<OtherDetailsModel>();

  displayedColumns: string[] = ['contract', 'type', 'facsts', 'month', 'noarrs', 'dpd', 'amount'];

  //facilty data list table feature
  // getFacilityList(): void {
  //   this.OtherDetailsService.getODFacListActive(this.contractNo).subscribe(
  //     (data: any) => {
  //       if (data.length > 0) {
  //         console.debug("otherdetails facility list loaded,", "Data:", data)
  //         this.dataSource.data = data as OtherDetailsModel[];
  //       }
  //       else if (data.length > 0) {
  //         alert("No records to load.")
  //       }
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }
}
