import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { TransactionSummaryService } from 'src/app/services/transaction-summary/transaction-summary.service';
import { PeriodicElement } from 'src/app/models/transaction-summary/periodic-element.model';
import { PeriodicElementPayment } from 'src/app/models/transaction-summary/periodic-element-payment.model';
import { PeriodicElementRentalDebited } from 'src/app/models/transaction-summary/periodic-element-rental-debited.model';
import { PeriodicElementContractSett } from 'src/app/models/transaction-summary/periodic-element-contract-sett.model';

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss']
})
export class TransactionSummaryComponent implements OnInit {

  title: string = 'Transaction Summary';
  // contractNo = "2243006059";
  // strdate = "08-Jan-23";
  // In_Bal_Nof_Rentls = "8";
  contractNo: string;
  strdate: string;
  In_Bal_Nof_Rentls: string;

  panelOpenState = false;
  panelOpenState_rentDebit = false;
  panelOpenState_gstDebit = false;
  panelOpenState_payment = false;
  panelOpenState_contractSett = false;

  displayedColumns: string[] = ['date', 'time', 'trnno', 'receiptno', 'trncode', 'paymode', 'cheqno', 'debit', 'credit'];
  displayedColumns_rentDebit: string[] = ['dueDate', 'dueAmount', 'cap', 'mintbttDue', 'std', 'rno', 'rnoArr', 'defwOff', 'defbigdte', 'nxtOdiDte', 'perfNon', 'refNo', 'rnt'];
  displayedColumns_gstDebit: string[] = ['date', 'rental', 'capital_due', 'int_btt_due', 'add_chg_due', 'rental_def', 'rental_def_due', 'rental_def_waved', 'deffault_beg_date', 'next_acc_date', 'good_bad', 'ref_no', 'due_type'];
  displayedColumns_payment: string[] = ['payDate', 'totPay', 'payTime', 'payUser', 'transNo', 'rcpNo', 'payMode', 'chqNo', 'status', 'rental', 'rentDef', 'gst', 'gstDef', 'ins', 'insDef', 'other', 'chr', 'gsd', 'overPay', 'refund'];
  displayedColumns_contractSett: string[] = ['descr', 'value'];

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource_rentDebit = new MatTableDataSource<PeriodicElementRentalDebited>(ELEMENT_DATA_RENTDEBIT);
  dataSource_gstDebit = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA_GSTDEBIT);
  dataSource_payment = new MatTableDataSource<PeriodicElementPayment>(ELEMENT_DATA_PAYMENT);
  dataSource_contractSett = new MatTableDataSource<PeriodicElementContractSett>(ELEMENT_DATA_CONTRACTSETT);

  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild('paginator_rentDebit') paginator_rentDebit: MatPaginator;
  @ViewChild('paginator_gstDebit') paginator_gstDebit: MatPaginator;
  @ViewChild('paginator_payment') paginator_payment: MatPaginator;
  @ViewChild('paginator_contractSett') paginator_contractSett: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource_rentDebit.paginator = this.paginator_rentDebit;
    this.dataSource_gstDebit.paginator = this.paginator_gstDebit;
    this.dataSource_payment.paginator = this.paginator_payment;
    this.dataSource_contractSett.paginator = this.paginator_contractSett;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilter_rentDebit(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_rentDebit.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_rentDebit.paginator) {
      this.dataSource_rentDebit.paginator.firstPage();
    }
  }

  applyFilter_gstDebit(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_gstDebit.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_gstDebit.paginator) {
      this.dataSource_gstDebit.paginator.firstPage();
    }
  }

  applyFilter_payment(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_payment.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_payment.paginator) {
      this.dataSource_payment.paginator.firstPage();
    }
  }

  applyFilter_contractSett(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource_contractSett.filter = filterValue.trim().toLowerCase();

    if (this.dataSource_contractSett.paginator) {
      this.dataSource_contractSett.paginator.firstPage();
    }
  }

  constructor(private transactionSummaryService: TransactionSummaryService,
    private dialogRef: MatDialogRef<TransactionSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.contractNo = this.data.contractNo;
    this.strdate = this.data.strdate;
    this.In_Bal_Nof_Rentls = this.data.In_Bal_Nof_Rentls;
    //Transaction
    this.getTransaction();
    this.getUpperDetailsTransaction();
  }

  //Rental Debited handleClick
  handleTabClickRental(): void {
    this.getRentalDebited();
    this.getUpperDetailsRental();
  }

  //Payment handleClick
  handleTabClickPayment(): void {
    this.getPayment();
    this.getUpperDetailsPayment();
  }

  //Contract Sett handleClick
  handleTabClickContract_sett(): void {
    this.getContractSett();
    this.getUpperDetailsContractSett();
  }


  // Transaction Summary Transaction Service
  getTransaction(): void {
    this.transactionSummaryService.getTransactionSummary_transaction(this.contractNo).subscribe(
      (data: any) => {
        this.dataSource.data = data as PeriodicElement[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Transaction Summary Rental Debited Service
  getRentalDebited(): void {
    this.transactionSummaryService.getTransactionSummary_rentalDebited(this.contractNo, this.strdate).subscribe(
      (data: any) => {
        this.dataSource_rentDebit.data = data as PeriodicElementRentalDebited[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Transaction Summary Payment Service
  getPayment(): void {
    this.transactionSummaryService.getTransactionSummary_payment(this.contractNo, this.strdate).subscribe(
      (data: any) => {
        this.dataSource_payment.data = data as PeriodicElementPayment[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Transaction Summary Contract Settlement Service
  getContractSett(): void {
    this.transactionSummaryService.getTransactionSummary_ContractSett(this.contractNo, this.strdate, this.In_Bal_Nof_Rentls).subscribe(
      (data: any) => {
        this.dataSource_contractSett.data = data as PeriodicElementContractSett[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //UpperDetails Transaction Service
  getUpperDetailsTransaction(): void {
    this.transactionSummaryService.getTransactionSummary_UpperDetails(this.contractNo).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('status_t')?.setAttribute('value', firstItem.status);
          document.getElementById('vehicleNo')?.setAttribute('value', firstItem.regNo);
          document.getElementById('date')?.setAttribute('value', firstItem.transDate);
          document.getElementById('payMode')?.setAttribute('value', firstItem.payMode);
          document.getElementById('clientCode')?.setAttribute('value', firstItem.clCode);
          document.getElementById('balanceRental')?.setAttribute('value', firstItem.balRental);
          document.getElementById('clientName')?.setAttribute('value', firstItem.clName);
          document.getElementById('equipment')?.setAttribute('value', firstItem.equimt);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //UpperDetails Rental Debited Service
  getUpperDetailsRental(): void {
    this.transactionSummaryService.getTransactionSummary_UpperDetails(this.contractNo).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('status_r')?.setAttribute('value', firstItem.status);
          document.getElementById('vehicleNo_r')?.setAttribute('value', firstItem.regNo);
          document.getElementById('date_r')?.setAttribute('value', firstItem.transDate);
          document.getElementById('payMode_r')?.setAttribute('value', firstItem.payMode);
          document.getElementById('clientCode_r')?.setAttribute('value', firstItem.clCode);
          document.getElementById('balanceRental_r')?.setAttribute('value', firstItem.balRental);
          document.getElementById('clientName_r')?.setAttribute('value', firstItem.clName);
          document.getElementById('equipment_r')?.setAttribute('value', firstItem.equimt);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //UpperDetails Payment Service
  getUpperDetailsPayment(): void {
    this.transactionSummaryService.getTransactionSummary_UpperDetails(this.contractNo).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('status_p')?.setAttribute('value', firstItem.status);
          document.getElementById('vehicleNo_p')?.setAttribute('value', firstItem.regNo);
          document.getElementById('date_p')?.setAttribute('value', firstItem.transDate);
          document.getElementById('payMode_p')?.setAttribute('value', firstItem.payMode);
          document.getElementById('clientCode_p')?.setAttribute('value', firstItem.clCode);
          document.getElementById('balanceRental_p')?.setAttribute('value', firstItem.balRental);
          document.getElementById('clientName_p')?.setAttribute('value', firstItem.clName);
          document.getElementById('equipment_p')?.setAttribute('value', firstItem.equimt);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //UpperDetails Contract Settlement Service
  getUpperDetailsContractSett(): void {
    this.transactionSummaryService.getTransactionSummary_UpperDetails(this.contractNo).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          const firstItem = data[0];

          document.getElementById('status_c')?.setAttribute('value', firstItem.status);
          document.getElementById('vehicleNo_c')?.setAttribute('value', firstItem.regNo);
          document.getElementById('date_c')?.setAttribute('value', firstItem.transDate);
          document.getElementById('payMode_c')?.setAttribute('value', firstItem.payMode);
          document.getElementById('clientCode_c')?.setAttribute('value', firstItem.clCode);
          document.getElementById('balanceRental_c')?.setAttribute('value', firstItem.balRental);
          document.getElementById('clientName_c')?.setAttribute('value', firstItem.clName);
          document.getElementById('equipment_c')?.setAttribute('value', firstItem.equimt);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

const ELEMENT_DATA: PeriodicElement[] = [];
const ELEMENT_DATA_RENTDEBIT: PeriodicElementRentalDebited[] = [];
const ELEMENT_DATA_GSTDEBIT: PeriodicElement[] = [];
const ELEMENT_DATA_PAYMENT: PeriodicElementPayment[] = [];
const ELEMENT_DATA_CONTRACTSETT: PeriodicElementContractSett[] = [];

//Transaction Data set
// interface PeriodicElement {
//   date: string;
//   time: string;
//   trnno: number;
//   receiptno: string;
//   trncode: string;
//   paymode: string;
//   cheqno: string;
//   debit: string;
//   credit: string;
// }

//Payment Data set
// interface PeriodicElementPayment {
//   payDate: string;
//   totPay: string;
//   payTime: number;
//   payUser: string;
//   transNo: string;
//   rcpNo: string;
//   payMode: string;
//   chqNo: string;
//   status: string;
//   rental: string;
//   rentDef: string;
//   gst: string;
//   gstDef: string;
//   ins: string;
//   insDef: string;
//   other: string;
//   chr: string;
//   gsd: string;
//   overPay: string;
//   refund: string;
// }

//Rental Debited Data set
// interface PeriodicElementRentalDebited {
//   dueDate: string;
//   dueAmount: string;
//   cap: number;
//   mintbttDue: string;
//   std: string;
//   rno: string;
//   rnoArr: string;
//   defwOff: string;
//   defbigdte: string;
//   nxtOdiDte: string;
//   perfNon: string;
//   refNo: string;
//   rnt: string;
// }
