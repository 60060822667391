import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  @Input() title: string = ""

  @Output() closeDialogEvent: EventEmitter<void> = null;

  constructor(@Inject(MAT_DIALOG_DATA) private propData: any) { 
    this.closeDialogEvent = new EventEmitter();
  }

  closeDialog() {
    this.closeDialogEvent.emit();
  }

  ngOnInit(): void {
    console.log(this.propData);
  }

}
