<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon custom-icon-color">person_add</mat-icon>
      <span id="textstyle">Client</span>
    </ng-template>
    <div class="card-content p-2 w-100">
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class=" col-12 col-md-3 py-md-2">
          <mat-card class="" id="test_card_style_id">
            <div class=" col-12 card-content-leading ">
              <mat-card-content class="">
                <mat-form-field class="example-full-width d-flex ">
                  <mat-label>NIC NO</mat-label>
                  <input matInput class="textstyle text-uppercase" type="text" id="idListddd" name="idListddd" disabled>
                </mat-form-field>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <mat-tab-group class="centered-tabs">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">transfer_within_a_station</mat-icon>
          <span (click)="handleNormalClosure()" id="textstyle">Active</span>
        </ng-template>
        <app-active></app-active>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">pinch </mat-icon>
          <span (click)="handleNormalClosure()" id="textstyle">Normal Closure</span>
        </ng-template>
        <app-normal-closure></app-normal-closure>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color"> local_offer</mat-icon>
          <span (click)="handleWriteOff()" id="textstyle">Write-Off</span>
        </ng-template>
        <app-write-off></app-write-off>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">attach_money</mat-icon>
          <span (click)="handleRepossess()" id="textstyle">ResPossess</span>
        </ng-template>
        <app-respossess></app-respossess>
      </mat-tab>

      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">sync</mat-icon>
          <span (click)="handleReschedule()" id="textstyle">Reschedule/ Transferred</span>
        </ng-template>
        <app-reschedule-transferre></app-reschedule-transferre>
      </mat-tab> -->

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">sync</mat-icon>
          <span (click)="handleReschedule()" id="textstyle">Reschedule</span>
        </ng-template>
        <app-reschedule></app-reschedule>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">sync</mat-icon>
          <span (click)="handleTransferred()" id="textstyle">Transferred</span>
        </ng-template>
        <app-transferred></app-transferred>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">pages</mat-icon>
          <span (click)="handleRefinance()" id="textstyle">Refinance</span>
        </ng-template>
        <app-refinance></app-refinance>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">stars</mat-icon>
          <span (click)="handleGuaranteed()" id="textstyle">Guaranteed</span>
        </ng-template>
        <app-guaranteed></app-guaranteed>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon custom-icon-color">people</mat-icon>
          <span (click)="handlePartner()" id="textstyle">Partner/Proprietor</span>
        </ng-template>
        <app-partner-proprietor></app-partner-proprietor>
      </mat-tab> -->
    </mat-tab-group>
  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <div (click)="getGroupRelated()" >
        <mat-icon class="example-tab-icon custom-icon-color">attach_money</mat-icon>
        <span style="font-weight: bold">Group Related</span>
      </div>
    </ng-template>.

    <!-- Filtering part start -->

    <div class="card-content p-2 w-100">
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">

        <div class=" col-12 col-md-3 py-md-2">
          <mat-card class="" id="test_card_style_id">
            <div class=" col-12 card-content-leading ">
              <mat-card-content class="">
                <div class="d-flex justify-content-between  ">
                  <p class="textstyle text-uppercase" id="facility">NIC:</p>
                  <!-- <p class="text-uppercase" id="idListddd">{{id_nic}}</p> -->
                  <input class="textstyle text-uppercase" type="text" id="idListddd" name="idListddd" disabled />

                </div>
              </mat-card-content>
            </div>
          </mat-card>
        </div>

        <div class=" col-12 col-md-3 py-md-2">
          <mat-card class="" id="test_card_style_id">
            <div class=" col-12 card-content-leading ">
              <mat-card-content class="">
                <div class="d-flex justify-content-between  ">
                  <p class="textstyle text-uppercase" id="facility">GROUP:</p>
                  <!-- <p class="text-uppercase" id="gp_code"></p> -->
                  <input class="textstyle text-uppercase" type="text" id="gp_code" name="gp_code" disabled />
                </div>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
        <div class="col-12 col-md-4 py-md-2">
          <mat-card class="" id="test_card_style_id">
            <div class="col-12 card-content-leading">
              <mat-card-content class="test_card">
                <div class="d-flex justify-content-between">
                  <p class="textstyle text-uppercase" id="textstyle">LIST</p>
                  <select [(ngModel)]="selectedOption" (change)="getGroupRelated()">
                    <option value="ALL">ALL</option>
                    <option *ngFor="let option of idListOptions" [value]="option.id">{{ option.name }}</option>
                  </select>
                </div>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      

        <!-- <div class="col-12 col-md-4 py-md-2">
          <mat-card class="" id="test_card_style_id">
            <div class="col-12 card-content-leading">
              <mat-form-field>
                <mat-card-content class="test_card">
                  <div class="d-flex justify-content-between">
                    <p class="textstyle text-uppercase" id="textstyle">LIST</p>
                    <mat-select disableRipple>
                      <mat-option *ngFor="let option of idListOptions" [value]="option">{{ option }}</mat-option>
                    </mat-select>
                  </div>
                </mat-card-content>
              </mat-form-field>
            </div>
          </mat-card>
        </div> -->




      </div>
    </div>
    <br>
    <!-- Filtering part End -->


    <mat-tab-group class="centered-tabs">
      <!--Group  Activity -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getGroupRelatedActive()"  (click)="getGroupRelatedActiveDet()">
            <mat-icon class="example-tab-icon custom-icon-color">transfer_within_a_station</mat-icon>
            <span id="textstyle">Active</span>
          </div>

        </ng-template>

        <div>
          <div>
            <div class="w-100 card-container my-2">
              <div class="card-content w-100">
                <div class="row card-content-row w-100 px-5 px-md-4">
                  <div class="col-12 col-md-6 py-md-2">
                    <table class="table">
                      <thead>
                        <tr class="table-primary">
                          <th scope="col" class="table_col">Description</th>
                          <th scope="col" class="table_col">Details</th>
                          <th scope="col" class="table_col">Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="td_name">NO OF CONTRACTS</td>
                          <td class="td_name">
                            <input type="text" id="GROUPNOOFCONTRACTS" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPNOOFCONTRACTSValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">TOTAL AMOUNT FINANCED</td>
                          <td class="td_name1">
                            <input type="text" id="GROUPTOTALAMOUNTFINANCED" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPTOTALAMOUNTFINANCEDValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">MONTHLY COMMITMENT</td>
                          <td class="td_name">
                            <input type="text" id="GROUPMONTHLYCOMMITMENT" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPMONTHLYCOMMITMENTValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">STOCK BALANCE</td>
                          <td class="td_name1">
                            <input type="text" id="GROUPSTOCKBALANCE" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPSTOCKBALANCEValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">TOTAL AREARS</td>
                          <td class="td_name">
                            <input type="text" id="GROUPTOTALARREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPTOTALARREARSValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">RENTAL AREARS</td>
                          <td class="td_name1">
                            <input type="text" id="GROUPRENTALARREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPRENTALARREARSValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">DEFAULT AREARS</td>
                          <td class="td_name">
                            <input type="text" id="GROUPDEFAULTARREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPDEFAULTARREARSValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">INSURANCE AREARS</td>
                          <td class="td_name1">
                            <input type="text" id="GROUPINSURANCEARREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPINSURANCEARREARSValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">
                           
                              AREARS < 3 
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPARREARS<3" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPARREARS<3Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">
                            AREARS 3 - 6
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPARREARS3-6" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUPARREARS3-6Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">
                              AREARS > 6
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPARREARS>6" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUPARREARS>6Values" name="name" disabled readonly />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 col-md-6 py-md-2">
                    <table mat-table [dataSource]="dataSources" class="mat-elevation-z8">
                      <!-- Contract Number Column -->

                      <!-- contract -->
                      <ng-container matColumnDef="contract" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> CONTRACT NO </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.contract }} </td>
                      </ng-container>

                      <ng-container matColumnDef="month" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AREARS </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.noarrs }} </td>
                      </ng-container>
                      <ng-container matColumnDef="amount" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AMOUNT</th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.amount }} </td>
                      </ng-container>



                      <!-- Row shown when there is no matching data -->
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns1111"></tr>  -->
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <!--Group  Activity End-->

      <!-- Group Normal Closure -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getGroupRelatedNormalClourses()" (click)="getGroupRelatedNormalCloursesDet()">
            <mat-icon class="example-tab-icon custom-icon-color">pinch </mat-icon>
            <span id="textstyle">Normal Closure</span>
          </div>

        </ng-template>
        <div>
          <div>
            <div class="w-100 card-container my-2">
              <div class="card-content w-100">
                <div class="row card-content-row w-100 px-5 px-md-4 ">
                  <div class=" col-12 col-md-6 py-md-2">
                    <table class="table">
                      <thead>
                        <tr class="table-primary ">
                          <th scope="col" class="table_col">Description</th>
                          <th scope="col" class="table_col">Details</th>
                          <th scope="col" class="table_col">Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="td_name">NO OF CONTRACTS</td>
                          <td class="td_name">
                            <input type="text" id="normalnumberOfContracts" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="normalnumberOfContractsValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">TOTAL AMOUNT FINANCED</td>
                          <td class="td_name1">
                            <input type="text" id="normaltotAmountFinanced" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="normaltotAmountFinancedValues" name="name" disabled readonly />
                          </td>
                        </tr>

                        <tr>
                          <td class="td_name">STOCK BALANCE</td>
                          <td class="td_name">
                            <input type="text" id="normalstockBalance" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="normalstockBalanceValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>

                          <td class="td_name1">DEFAULT PAID</td>
                          <td class="td_name1">
                            <input type="text" id="normaldefault" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="normaldefaultValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>

                          <td class="td_name">DEFAULT WAIVED</td>
                          <td class="td_name">
                            <input type="text" id="normaldefaultWaived" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="normaldefaultPaidValues" name="name" disabled readonly />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 col-md-6 py-md-2">
                    <table mat-table [dataSource]="dataSources" class="mat-elevation-z8">
                      <!-- Contract Number Column -->

                      <!-- contract -->
                      <ng-container matColumnDef="contract" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> CONTRACT NO </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.contract }} </td>
                      </ng-container>

                      <ng-container matColumnDef="month" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AREARS </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.noarrs }} </td>
                      </ng-container>
                      <ng-container matColumnDef="amount" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AMOUNT</th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.amount }} </td>
                      </ng-container>



                      <!-- Row shown when there is no matching data -->
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns1111"></tr>  -->
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <!-- Group Normal Closure End-->

      <!-- Group Repossess -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getGroupRelatedRepossess()" (click)="getGroupRelatedRepossessDet()">
            <mat-icon class="example-tab-icon custom-icon-color">attach_money</mat-icon>
            <span id="textstyle">Respossess</span>
          </div>

        </ng-template>
        <div>
          <div>
            <div class="w-100 card-container my-2">
              <div class="card-content w-100">
                <div class="row card-content-row w-100 px-5 px-md-4 ">
                  <div class=" col-12 col-md-6 py-md-2">
                    <table class="table">
                      <thead>
                        <tr class="table-primary ">
                          <th scope="col" class="table_col">Description</th>
                          <th scope="col" class="table_col">Details</th>
                          <th scope="col" class="table_col">Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="td_name">NO OF CONTRACTS</td>
                          <td class="td_name">
                            <input type="text" id="numberOfContractsrepossess" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="numberOfContractsrepossessValues" name="name" disabled readonly />
                          </td>
                        </tr>

                        <tr>
                          <td class="td_name1">STOCK BALANCE</td>
                          <td class="td_name1">
                            <input type="text" id="stockBalancerepossess" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="stockBalancerepossessValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">DEFAULT PAID</td>
                          <td class="td_name">
                            <input type="text" id="defaultPaidrepossess" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="defaultPaidrepossessValues" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">DEFAULT WAIVED</td>
                          <td class="td_name1">
                            <input type="text" id="defaultWaivedrepossess" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="defaultWaivedrepossessValues" name="name" disabled readonly />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 col-md-6 py-md-2">
                    <table mat-table [dataSource]="dataSources" class="mat-elevation-z8">
                      <!-- Contract Number Column -->

                      <!-- contract -->
                      <ng-container matColumnDef="contract" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> CONTRACT NO </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.contract }} </td>
                      </ng-container>

                      <ng-container matColumnDef="month" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AREARS </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.noarrs }} </td>
                      </ng-container>
                      <ng-container matColumnDef="amount" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AMOUNT</th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.amount }} </td>
                      </ng-container>



                      <!-- Row shown when there is no matching data -->
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns1111"></tr>  -->
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <!-- Group Respossess End -->

      <!-- Guaranteed -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="getGroupRelatedGuaranteedDet()" (click)="getGroupRelatedGuaranteed()">
            <mat-icon class="example-tab-icon custom-icon-color">stars</mat-icon>
            <span id="textstyle">Guaranteed</span>
          </div>

        </ng-template>
        <div>
          <div>
            <div class="w-100 card-container my-2">
              <div class="card-content w-100">
                <div class="row card-content-row w-100 px-5 px-md-4 ">
                  <div class=" col-12 col-md-6 py-md-2">
                    <table class="table">
                      <thead>
                        <tr class="table-primary ">
                          <th scope="col" class="table_col">Description</th>
                          <th scope="col" class="table_col">Details</th>
                          <th scope="col" class="table_col">Values</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="td_name">NO OF CONTRACTS</td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_NO_OF_CONTRACT" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_NO_OF_CONTRACT-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">TOTAL AMOUNT FINANCED</td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_TOTAL_AMOUNT_FINANCED" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_TOTAL_AMOUNT_FINANCED-Values" name="name" disabled
                              readonly />
                          </td>
                        </tr>

                        <tr>
                          <td class="td_name">STOCK BALANCE</td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_STOCK_BALANCE" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_STOCK_BALANCE-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">TOTAL AREARS</td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_TOTAL_AREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_TOTAL_AREARS-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">RENTAL AREARS</td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_RENTAL_AREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_RENTAL_AREARS-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">DEFAULT AREARS</td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_DEFAULT_AREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_DEFAULT_AREARS-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">INSURANCE AREARS</td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_INSURANCE_AREARS" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_INSURANCE_AREARS-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">
                         
                              AREARS < 3 
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_AREARS<3" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_AREARS<3-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name">
                            AREARS 3
                              - 6
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_AREARS-3-6" name="name" disabled readonly />
                          </td>
                          <td class="td_name">
                            <input type="text" id="GROUP_GRANT_AREARS-3-6-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                        <tr>
                          <td class="td_name1">
                        
                              AREARS > 6
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_AREARS>6" name="name" disabled readonly />
                          </td>
                          <td class="td_name1">
                            <input type="text" id="GROUP_GRANT_AREARS>6-Values" name="name" disabled readonly />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 col-md-6 py-md-2">
                    <table mat-table [dataSource]="dataSources" class="mat-elevation-z8">
                      <!-- Contract Number Column -->

                      <!-- contract -->
                      <ng-container matColumnDef="contract" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> CONTRACT NO </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.contract }} </td>
                      </ng-container>

                      <ng-container matColumnDef="month" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AREARS </th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.noarrs }} </td>
                      </ng-container>
                      <ng-container matColumnDef="amount" class="table_col">
                        <th class="table_col" mat-header-cell *matHeaderCellDef> AMOUNT</th>
                        <td mat-cell *matCellDef="let Arears"> {{ Arears.amount }} </td>
                      </ng-container>



                      <!-- Row shown when there is no matching data -->
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns1111"></tr>  -->
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </mat-tab>
      <!-- Guaranteed End -->

    </mat-tab-group>

  </mat-tab>

</mat-tab-group>
