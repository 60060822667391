import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { routes } from './app-recovery-routing.module';
import { AppRecoveryComponent } from './app-recovery.component';
import { AgmDirectionModule } from "agm-direction";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { AngularSlickgridModule } from "angular-slickgrid";
import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { UserLoginComponent } from './components/login/user-login/user-login.component';
import { RouterModule, Routes } from "@angular/router";
import { environment } from "../environments/environment";
import { RegisterUserComponent } from "./components/user-registration/register-user/register-user.component";
import { CreateUserRolesComponent } from "./components/user-registration/create-user-roles/create-user-roles.component";
import { AssignUserPrivilagesComponent } from "./components/user-registration/assign-user-privilages/assign-user-privilages.component";
import { MatSelectModule } from "@angular/material/select";
import { SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ClientDetailDialogComponent } from './components/client-detail-dialog/client-detail-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HomeComponentV2Component } from './components/home-component-v2/home-component-v2.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { DialogHeaderComponent } from './components/common/dialog-header/dialog-header.component';
import { WhitespacePipe } from './pipes/whitespace.pipe';
import {MatExpansionModule} from '@angular/material/expansion';
import {TokenInjector} from "./shared/interceptors/token-injector.interceptor";
import { IdleService } from './idle.service';
import { PopUpContactDetailsComponent } from './pop-up-contact-details/pop-up-contact-details.component';
import { TwoDigitDecimalNumberDirective } from './directive/two-digit-decimal-number.directive';
import { CustomerContractListComponent } from './components/customer-contract-list/customer-contract-list.component';
import { FacilityInquiryComponent } from './components/facility-inquiry/facility-inquiry.component';
import { TransactionSummaryComponent } from './components/transaction-summary/transaction-summary.component';
import { AdminPortalComponent } from './components/admin-portal/admin-portal.component';
import { AdminAccessComponent } from './components/admin-access/admin-access.component';
import { CallCenterDashboardAccessComponent } from './components/call-center-dashboard-access/call-center-dashboard-access.component';
import { PopUpOpenNotepadComponent } from './components/pop-up-open-notepad/pop-up-open-notepad.component';
import { OtherDetailsComponent } from './components/other-details/other-details.component';
import { SingleContractDetailViewComponent } from './components/single-contract-detail-view/single-contract-detail-view.component';


import { MatBadgeModule} from '@angular/material/badge'
import { MatSidenav } from '@angular/material/sidenav';
import { NormalClosureComponent } from './components/other-details/normal-closure/normal-closure.component';
import { WriteOffComponent } from './components/other-details/write-off/write-off.component';
import { RespossessComponent } from './components/other-details/respossess/respossess.component';
import { RescheduleTransferreComponent } from './components/other-details/reschedule-transferre/reschedule-transferre.component';
import { RefinanceComponent } from './components/other-details/refinance/refinance.component';
import { ActiveComponent } from './components/other-details/active/active.component';
import { GuaranteedComponent } from './components/other-details/guaranteed/guaranteed.component';
import { PartnerProprietorComponent } from './components/other-details/partner-proprietor/partner-proprietor.component';
import { ReportSummaryDashboardComponent } from './components/report-summary-dashboard/report-summary-dashboard.component';

import { RentalInformationComponent } from './components/single-contract-detail-view/rental-information/rental-information.component';
import { ContractInformationComponent } from './components/single-contract-detail-view/contract-information/contract-information.component';
import { RescheduleComponent } from './components/other-details/reschedule/reschedule.component';
import { TransferredComponent } from './components/other-details/transferred/transferred.component';
import { LeadCreationComponent } from './components/lead-creation/lead-creation.component';
import { InfacilideatailsComponent } from './components/infacilideatails/infacilideatails.component';
import { InleadCreationComponent } from './components/inlead-creation/inlead-creation.component';
import { InReportSummaryComponent } from './components/in-report-summary/in-report-summary.component';
import { FooterComponent } from './components/footer/footer.component';





const uiModules = [
  MatSidenavModule,
  MatIconModule,
  MatButtonModule,
  MatExpansionModule,
  
];

@NgModule({
  declarations: [
    AppRecoveryComponent,
    UserLoginComponent,
    RegisterUserComponent,
    CreateUserRolesComponent,
    AssignUserPrivilagesComponent,
    ClientDetailDialogComponent,
    HomeComponentV2Component,
    NavigationBarComponent,
    ThousandSeparatorPipe,
    DialogHeaderComponent,
    WhitespacePipe,
    PopUpContactDetailsComponent,
    TwoDigitDecimalNumberDirective,
    CustomerContractListComponent,
    FacilityInquiryComponent,
    TransactionSummaryComponent,
    SingleContractDetailViewComponent,
    AdminPortalComponent,
    AdminAccessComponent,
    CallCenterDashboardAccessComponent,
    PopUpOpenNotepadComponent,
    OtherDetailsComponent,
    SingleContractDetailViewComponent,
    SingleContractDetailViewComponent,
    NormalClosureComponent,
    WriteOffComponent,
    RespossessComponent,
    RescheduleTransferreComponent,
    RefinanceComponent,
    ActiveComponent,
    GuaranteedComponent,
    PartnerProprietorComponent,
    ReportSummaryDashboardComponent,
    RentalInformationComponent,
    ContractInformationComponent,
    RescheduleComponent,
    TransferredComponent,
    LeadCreationComponent,
    InfacilideatailsComponent,
    InleadCreationComponent,
    InReportSummaryComponent,
    FooterComponent,

    

  ],

  entryComponents:[PopUpContactDetailsComponent],

  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDeEoJMvLhIPoGEON-7nIHY3wQZytahyco',
      libraries: ['places']
    }),
    AgmDirectionModule,
    MatDialogModule,
    uiModules,
    MatInputModule,
    AngularSlickgridModule.forRoot(),
    MatSelectModule,
    SocialLoginModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    SocialLoginModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
  ],
  providers: [


    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false }
    },
    {
      provide: 'recoveryConfig',
      useValue: environment.recoveryConfig
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autologin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('465565472722-t7p7qk74hmtr4qm4qh54savc8gpvqd79.apps.googleusercontent.com',
              {
                scope: 'email',
                plugin_name: 'Branchless Banking'
              }
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppRecoveryComponent],
  exports: [AppRecoveryComponent, uiModules]
})
export class AppRecoveryModule {
  constructor(@Inject('recoveryConfig') private recoveryConfig = environment.recoveryConfig) {
  }
}


