import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RentalInformationModel } from 'src/app/models/single-contract-details/rental-information-model.model';
import { SingleContarctOutstandingService } from 'src/app/services/single-contract-details/single-contarct-outstanding.service';

@Component({
  selector: 'app-rental-information',
  templateUrl: './rental-information.component.html',
  styleUrls: ['./rental-information.component.scss']
})
export class RentalInformationComponent implements OnInit {

  contractNo = "2243006059";
  displayedColumns: string[] = ['rentals', 'grossAmount', 'capitalAmount', 'capitalPaid', 'interestAmount', 'interestPaid', 'gstDue', 'gstPaid', 'stampDuty', 'grossPaid', 'dueDate', 'status','balance'];


  dataSource = new MatTableDataSource<RentalInformationModel>(ELEMENT_DATA);

  constructor(private singleContarctOutstandingService : SingleContarctOutstandingService ) { }

  ngOnInit(): void {
    this.getRentalInformation();
  }

  getRentalInformation(): void {
    this.singleContarctOutstandingService.getRentalInformation(this.contractNo).subscribe(
      (data: any) => {
        this.dataSource.data = data as RentalInformationModel[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }


}
const ELEMENT_DATA: RentalInformationModel[] = [];
