import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IdleService } from './idle.service';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { SocialUser } from 'angularx-social-login';
import { UserAuthenticationService } from './services/user-authentication/user-authentication.service';


@Component({
  selector: 'app-root',
  templateUrl: './app-recovery.component.html',
  styleUrls: ['./app-recovery.component.scss']
})

export class AppRecoveryComponent {
  currentUserSubject: any;
  loginForm!: FormGroup;
  errorMsg?: boolean = true;
  user?: SocialUser;
  loggedIn?: boolean;
  loginFormFromAD: FormGroup = null
  logID: string = null

  constructor(private router: Router, private idleService: IdleService, private auth: UserAuthenticationService) {
  }

  ngOnInit(): void {
    this.initialIdleSettings();
  }

  checkLoggedInState(): boolean {
    console.log(this.auth.currentUserValue?.code);
    if (this.auth.currentUserValue?.code) {
      return true;
    }
    return false;
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.recoveryConfig.idleTimeInMinutes * 60;
    console.log(environment.recoveryConfig.idleTimeInMinutes);
    // const idleTimeoutInSeconds: number = 60 * 10;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut && this.checkLoggedInState()) {
        alert("Session timeout. It will redirect to login page.");
        this.auth.logout(this.router);
      }
    });
  }

}
