import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, NgModel } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FacilityInquiryComponent } from '../facility-inquiry/facility-inquiry.component';
import { SingleContractDetailViewComponent } from '../single-contract-detail-view/single-contract-detail-view.component';
import { DateAdapter } from '@angular/material/core';
import { ReportSummaryService } from 'src/app/services/report-summary/report-summary.service';
import { DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ContractInformationComponent } from '../single-contract-detail-view/contract-information/contract-information.component';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerInformationService } from 'src/app/services/customer-information/customer-information.service';


@Component({
  selector: 'app-report-summary-dashboard',
  templateUrl: './report-summary-dashboard.component.html',
  styleUrls: ['./report-summary-dashboard.component.scss'],
  providers: [DatePipe],
})

export class ReportSummaryDashboardComponent implements OnInit {

  // contractNo = "4733018839";
  // strdate = "08-Jan-23";
  // In_Bal_Nof_Rentls = "8";
  contractNo: string;
  strdate: string;
  In_Bal_Nof_Rentls: string;

  currentDate = new Date();
  currentDateByDay = this.datePipe.transform(new Date(), 'd/M/yyyy')!;
  clickTime: string;
  myForm: FormGroup;
  showRecordingLabel: boolean = false;
  title: string = 'Report Summary Dashboard';
  toppingList = [
    { value: 'q001', label: 'Educated regarding the available payment channels' },
    { value: 'q002', label: 'Customer requested to reschedule the contract' },
    { value: 'q003', label: 'Client knows about the available payment channels' }
  ];
  userId: string = 'Bilesh';
  i: any;

  constructor(
    private route: ActivatedRoute,
    // private dialogRef: MatDialogRef<ReportSummaryDashboardComponent>,
    private fb: FormBuilder, private dialog: MatDialog,
    private dateAdapter: DateAdapter<any>,
    private reportSummaryService: ReportSummaryService,
    private datePipe: DatePipe,
    private customerInformationService: CustomerInformationService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: UserAuthenticationService) {
    this.currentDate = new Date();

  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.contractNo = params.get('facNumber');
    });

    this.getQuestionList();
    this.dateAdapter.setLocale('en-US');

    // this.contractNo = this.data.contractNo;
    // this.strdate = this.data.strdate;
    // this.In_Bal_Nof_Rentls = this.data.In_Bal_Nof_Rentls;

    this.myForm = this.fb.group({
      facilityNo: [this.contractNo],
      subject: ['Arreas Reports' + "-" + this.currentDateByDay],
      // location: [''],
      act_type: [''],
      business_unit: [{ value: 'Leasing', disabled: false }],
      // all_day: [''],
      start_date: [this.currentDate, Validators.required],
      end_date: [this.currentDate, Validators.required],
      owner: [this.auth.currentUserValue.username],
      primaryContact: [''],
      account: [''],
      // lead: [''],
      phone: [''],
      language: [''],
      objective: [''],
      // recording: [''],
      // recovery: [false, Validators.requiredTrue],
      multipleContract: [true],
      status: [''],
      meetingMinutes: [''],
      callStatus: ['', Validators.required],
      outcome: ['', Validators.required],
      additionalOutcome: [''],
      firstPromiseAmount: [''],
      firstPromiseDate: [''],
      secondPromiseAmount: [{ value: '', disabled: true }],
      secondPromiseDate: [{ value: '', disabled: true }],
      results: [''],
      start_time: [this.getCurrentTime()],
      end_time: [],
      likelihood: [{ value: '', disabled: true }],
      payment: [''],

      qType: [''],
    });
    this.getContactAndAccountByFacNo(this.contractNo);
    this.myForm.get('firstPromiseAmount')?.valueChanges.subscribe(value => {
      this.checkFirstPromiseFields();
    });

    this.myForm.get('firstPromiseDate')?.valueChanges.subscribe(value => {
      this.checkFirstPromiseFields();
    });
    this.myForm.get('firstPromiseAmount')?.valueChanges.subscribe(value => {
      this.checkPromiseAmount();
    });

  }
  checkPromiseAmount() {
    const firstPromiseAmount = this.myForm.get('firstPromiseAmount')?.value;
    if (firstPromiseAmount != null) {
      this.myForm.get('likelihood')?.enable();
      const likelihood=this.myForm.get('likelihood')?.value;
     
    } else {
      this.myForm.get('likelihood')?.disable();
      this.myForm.get('likelihood')?.setValue(null); 
    }
  }
  checkFirstPromiseFields() {
    const firstPromiseAmount = this.myForm.get('firstPromiseAmount')?.value;
    const firstPromiseDate = this.myForm.get('firstPromiseDate')?.value;

    if (firstPromiseAmount && firstPromiseDate) {
      this.myForm.get('secondPromiseAmount')?.enable();
      this.myForm.get('secondPromiseDate')?.enable();
    } else {
      this.myForm.get('secondPromiseAmount')?.disable();
      this.myForm.get('secondPromiseDate')?.disable();
      this.myForm.get('secondPromiseAmount')?.reset();
      this.myForm.get('secondPromiseDate')?.reset();
    }
  }
  getCurrentTime(): string {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString();
  }
  convertDateFormat(start_date: string): string {
    const date = new Date(start_date);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }
  // toppingList = ['Educated regarding the available payment channels', 'Customer requested to reschedule the contract', 'Client Knows about The Available Payment Channels'];
  // selectedToppings: string[] = [];

  onSubmit() {
    if (this.myForm.valid) {
      const isConfirmed = window.confirm('Are you sure you want to submit the form?');
      if (isConfirmed) {
        const startDateFormatted = this.datePipe.transform(this.myForm.value.start_date, 'dd-MM-yyyy');
        this.clickTime = this.getCurrentTime();
       

        this.myForm.patchValue({
          end_time: this.clickTime,
          start_date: startDateFormatted
        });

        //Question List Post Service
        const user_name = this.auth.currentUserValue.username;
        const postData = {
          userId: user_name,
          qType: this.myForm.value.qType,
          facno: this.contractNo
        }
        this.reportSummaryService.updateQuestionList(postData)
          .subscribe(response => {
            console.log('POST Response:', response);
          });

        //Report Summary Post Service
        this.reportSummaryService.updateReportSummary(this.myForm.value)
          .subscribe(response => {
            if (response.statusCode === "true") {
              // console.log('POST Response:', response);

              console.log('Form submitted!', this.myForm.value);
              alert('Report Summary Form submitted');
              // clear field but keeping facilityNo
              const facilityNo = this.myForm.get('facilityNo').value;
              this.myForm.reset();
              this.myForm.patchValue({ facilityNo: facilityNo });

            } else {
              // Lead creation failed
              alert('Report Summary update failed. Please try again later.');
              console.error('Lead creation failed:', response?.message);
              // Optionally display an error message
            }
            console.log('POST Response:', response);
          });



      } else {
        console.log('Form submission canceled.');
      }

    } else {
      console.log('Form has validation errors.');
    }

  }


  selectedValues: string[] = [];

  getQuestionList(): void {

    this.reportSummaryService.getQuestionList(this.contractNo).subscribe(
      (data: any[]) => {
        console.log(data)
        if (data && data.length > 0) {

          this.selectedValues = data
            .filter(question => question.status === '1')
            .map(question => question.que_id);
          console.log(this.selectedValues);
          this.myForm.patchValue({ qType: this.selectedValues });
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }


  getContactAndAccountByFacNo(facNo: string): void {

    this.customerInformationService.getCustomer_information(facNo).subscribe(
      (data: any[]) => {

        if (data && data.length > 0) {

          const CustomerInformation = data[0];

          this.myForm.patchValue({ phone: CustomerInformation.tpno });
          this.myForm.patchValue({ account: CustomerInformation.ceft_ACCNO });


        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  facilityInquiry() {
    // const user_name = this.auth.currentUserValue.username;
    // const data = {
    //   contractNo: this.contractNo,
    //   strdate: this.strdate,
    //   In_Bal_Nof_Rentls: this.In_Bal_Nof_Rentls,
    // };

    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = '100vh';
    // dialogConfig.width = '99.5vw';
    // dialogConfig.maxWidth = '99.5vw';

    // const dialogRef = this.dialog.open(ContractInformationComponent, dialogConfig);
    // dialogRef.componentInstance.data = data;
  }

  // closeDialog(): void {
  //   this.dialogRef.close();
  // }

}


