import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// import { Config } from 'protractor';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionSummaryService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl1;
    } else {
      return recoveryConfig.recoveryServiceUrl1;
    }
  }

  // Transaction Summary Transaction
  getTransactionSummary_transaction(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/transaction-summary/getTransactionSummaryDetails/${contractNo}`;
    return this.http.get<any>(url);
  }

  // Transaction Summary Payment
  getTransactionSummary_payment(contractNo: string, strdate: string): Observable<any> {
    const url = `${this.apiUrl}/transaction-summary/getPaymentDetails/${contractNo}/${strdate}`;
    return this.http.get<any>(url);
  }

  // Transaction Summary Rental Debited
  getTransactionSummary_rentalDebited(contractNo: string, strdate: string): Observable<any> {
    const url = `${this.apiUrl}/transaction-summary/getRentalDebitDetails/${contractNo}/${strdate}`;
    return this.http.get<any>(url);
  }

  // Transaction Summary Contract Settlement
  getTransactionSummary_ContractSett(contractNo: string, strdate: string, In_Bal_Nof_Rentls: string): Observable<any> {
    const url = `${this.apiUrl}/transaction-summary/getContractSettlement/${contractNo}/${strdate}/${In_Bal_Nof_Rentls}`;
    return this.http.get<any>(url);
  }

  // Transaction Summary UpperDetails
  getTransactionSummary_UpperDetails(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/transaction-summary/getTransFacilityDetails/${contractNo}`;
    return this.http.get<any>(url);
  }

}
