import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OtherDetailsModel } from 'src/app/models/other-details/OtherDetailModel';
import { OtherDetailsService } from 'src/app/services/otherDetailsService/otherDetails.service';
import { DataService } from '../../../services/dataSendingService/data.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-refinance',
  templateUrl: './refinance.component.html',
  styleUrls: ['./refinance.component.scss']
})
export class RefinanceComponent implements OnInit {
  receivedData: any;
  contractNo: string;
  ContractData: any;
  dataSource = new MatTableDataSource<OtherDetailsModel>();
  displayedColumns: string[] = ['contract', 'type', 'facsts', 'month', 'noarrs', 'dpd', 'amount'];


  constructor(private OtherDetailsService: OtherDetailsService, @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService) {
    this.receivedData = this.dataService.getData();
    this.contractNo = this.receivedData.contractNo;
    // this.NIC = this.receivedData.idNo
  }

  ngOnInit(): void {
    this.getFacilityList();
  }

  //facilty data list table feature
  getFacilityList(): void {
    this.OtherDetailsService.getODFacListRefinance(this.contractNo).subscribe(
      (data: any) => {
        if (data.length > 0) {
          console.debug("otherdetails facility list loaded,", "Data:", data)
          this.dataSource.data = data as OtherDetailsModel[];
        }
        else if (data.length > 0) {
          alert("No records to load.")
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
}
