import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HomeComponentV2Component } from '../home-component-v2/home-component-v2.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  AngularGridInstance, Column, FieldType, Filters,
  Formatters,
  GridOption,
  GridStateChange,
  MultipleSelectOption,
  OperatorType,
  GridService
} from 'angular-slickgrid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { SingleContractDetailViewComponent } from '../single-contract-detail-view/single-contract-detail-view.component';
import { CustomerInformationService } from 'src/app/services/customer-information/customer-information.service';
import { ReportSummaryDashboardComponent } from '../report-summary-dashboard/report-summary-dashboard.component';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';


//customer contract details
interface CustomerContractDetails{

   NAME?:string;
   IDNO?:string;
   TPNO?:string;
   ADDRS1?:string;
   addrs2?:string;
   addrs3?:string;
   addrs4?:string;
   BRNCODE?:string;
   EMAIL?:string;

}


@Component({
  selector: 'app-customer-contract-list',
  templateUrl: './customer-contract-list.component.html',
  styleUrls: ['./customer-contract-list.component.scss']
})
export class CustomerContractListComponent implements OnInit {

  dataset: any[] = [];
  // contractNo = "2243006059";

  contractNo = "4733018839";
  strdate = "08-Jan-23";
  In_Bal_Nof_Rentls = "8";
  // UI related variables
  readonly headerTitle: string = "Customer Contract List";

  // slickgrid related variables

  // view mode for changing between all, balance and todays list
  mode: string = "ALL"
  enableSearch: boolean = false;

  // util data
  cType: string = "CR";
  date: string = null;
  todayDate: Date = null;
  selectedDate: Date = null;
  isCompatible: boolean = false;
  search: string = "";
  searchType: string = "Contract"; // search type default value is contract

  // slickgrid util
  todayCallsColumnDefinitions: Column[] = [];
  todayCallsgridOptions: GridOption = {};//CallDeskDataResponseRestModelInfo
  todayCallsDataset: any[] = []; //CallDeskDataResponseModelInfo
  todayCallsAngularGrid!: AngularGridInstance;
  todayCallsAngularGridObj!: any;

  columnDefinitions: Column[];
  gridOptions: GridOption;
  dataRaw: any[];

  // selectedCallDeskTransaction!: CallDeskDataResponseModelInfo ;
  selectedTableRow!: any;

  selectOptionMode: string = "CR"
  CR: string
  WO: string
  PL: string
  PW: string

  //loadingViewBoolValue
  isLoading: boolean = false;

  constructor(
    private customerInformationServices:CustomerInformationService,
    private dialogRef: MatDialogRef<HomeComponentV2Component>,
    private _snackBar: MatSnackBar, private dialog: MatDialog,
    private auth: UserAuthenticationService ) {

  }

  ngOnInit(): void {

    this.getCustomerInformation();

    this.gridOptions = {
      enableAutoSizeColumns: true,
      enableAutoResize: true,
      enableExcelExport: true,
      exportOptions: { exportWithFormatter: true },
      excelExportOptions: { exportWithFormatter: true },
      enableFiltering: true,
      showCustomFooter: true, // display some metrics in the bottom custom footer
      enableTreeData: true, // you must enable this flag for the filtering & sorting to work as expected
      treeDataOptions: {
        columnId: 'test',
        parentPropName: 'parent_naics_cd',
        indentMarginLeft: 15
      },
      multiColumnSort: false, // multi-column sorting is not supported with Tree Data, so you need to disable it
      headerRowHeight: 45,
      rowHeight: 40,
      pagination: {
        pageSizes: [5, 10, 25, 50],
        pageSize: 5,
        totalItems: 100,
      }
    };

    this.columnDefinitions = [
      {
        id: 'naics_lvl',
        name: 'Contract ID',
        field: 'naics_lvl',
        width: 220,
        cssClass: 'cell-title',
        filterable: true,
        sortable: true,
        exportWithFormatter: false,
        queryFieldSorter: 'id',
        formatter: Formatters.tree
        //exportCustomFormatter: Formatters.treeExport
      },{
        id: 'test',
        name: 'Title',
        field: 'naics_indus_title',
        width: 220,
        cssClass: 'cell-title',
        filterable: true,
        sortable: true,
        exportWithFormatter: false,
        queryFieldSorter: 'id',
        formatter: Formatters.tree
        //exportCustomFormatter: Formatters.treeExport
      },
      {
        id: 'naics_cd',
        name: 'NAICS Code',
        field: 'naics_cd',
        width: 220,
        cssClass: 'cell-title',
        filterable: true,
        sortable: true,
        exportWithFormatter: false,
        queryFieldSorter: 'id',

        //exportCustomFormatter: Formatters.treeExport
      }
    ];

    this.dataset = [
      {
        id: 56,
        children: [],
        naics_key: '89',
        naics_cd: '56',
        naics_indus_title:
          'Administrative and Support and Waste Management and Remediation Services',
        naics_lvl: '2',
        parent_naics_cd: null,
        treeLevel: 0
      },
      {
        id: 561,
        children: [],
        naics_key: '90',
        naics_cd: '561',
        naics_indus_title: 'Administrative and Support Services',
        naics_lvl: '2',
        parent_naics_cd: 56
      },
      {
        id: 562,
        children: [],
        naics_key: '91',
        naics_cd: '562',
        naics_indus_title: 'Waste Management and Remediation Services',
        naics_lvl: '3',
        parent_naics_cd: 56
      },
      {
        id: 76,
        children: [],
        naics_key: '89',
        naics_cd: '56',
        naics_indus_title:
          'Administrative and Support and Waste Management and Remediation Services',
        naics_lvl: '2',
        parent_naics_cd: null,
        treeLevel: 0
      },
      {
        id: 961,
        children: [],
        naics_key: '90',
        naics_cd: '561',
        naics_indus_title: 'Administrative and Support Services',
        naics_lvl: '3',
        parent_naics_cd: 76
      },
      {
        id: 540,
        children: [],
        naics_key: '91',
        naics_cd: '562',
        naics_indus_title: 'Waste Management and Remediation Services 540',
        naics_lvl: '3',
        parent_naics_cd: 76
      },
      {
        id: 542,
        children: [],
        naics_key: '92',
        naics_cd: '562',
        naics_indus_title: 'Waste Management and Remediation Services 540',
        naics_lvl: '3',
        parent_naics_cd: 76
      }
    ];
  }

  // close model function
  hanldeClose() {
    this.dialogRef.close();
  }

  // search feature
  handleSearchEnable(e: MatCheckboxChange) {
    if (e.checked) {

      this.changeMode("ALL")
    }
  }

  changeMode(value: string) {
    this.mode = value
    if (value == "TODAYS") {
      this.selectedDate = this.todayDate
    }

    if (value == "BALANCE" || value == "COMPLETED") {
      this.enableSearch = false
    }
    // this.processFiltersAndReloadData()
  }

  searchCallList() {
    // let proc_search = this.search;
    // let proc_search_type = this.searchType
    // let proc_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    // let proc_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
    // let proc_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
    // let proc_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()
    // this.service.searchCallListWithFilters(this.user, proc_search, proc_search_type, proc_type, proc_npl, proc_dpd, proc_not_payed, this.date, this.cType).subscribe({
    //   next: (value) => {
    //     if (value.hasOwnProperty("msg")) {
    //       this.dataset = [];
    //       this.updateTable([])
    //       // this.snackBar.open("Todays Call List failed to load", 'Dismiss', {
    //       this.snackBar.open("Call progress loading", 'Dismiss', {
    //         duration: DurationUtil.TWO_SEC,
    //         horizontalPosition: "right",
    //         verticalPosition: "top",
    //         panelClass: ['mat-toolbar', 'mat-primary']
    //       });
    //     } else {
    //       this.dataset = []
    //       this.updateTable(value)
    //     }
    //   },
    //   error: (err) => {
    //     console.log(err)
    //     this.updateTable([])
    //     this.snackBar.open("Could not retreive search results", 'Dismiss', {
    //       duration: DurationUtil.TWO_SEC,
    //       horizontalPosition: "right",
    //       verticalPosition: "top",
    //       panelClass: ['mat-toolbar', 'mat-primary']
    //     });
    //   }
    // })
  }

  openSingleContract(){
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.contractNo,
      strdate: this.strdate,
      In_Bal_Nof_Rentls: this.In_Bal_Nof_Rentls,

    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    // const dialogRef = this.dialog.open(ReportSummaryDashboardComponent, dialogConfig);

    const dialogRef = this.dialog.open(ReportSummaryDashboardComponent, dialogConfig);
    // dialogRef.componentInstance.data = data;
  }



     // Customer Information
     getCustomerInformation(): void {
      this.customerInformationServices.getCustomer_information(this.contractNo).subscribe(
        (data: any) => {
          console.log("dats is: ", data);
            if (data && data.length>0){
                 const CustomerInformation = data[0];
                 document.getElementById('name')?.setAttribute('value',CustomerInformation.name);
                 document.getElementById('nic')?.setAttribute('value',CustomerInformation.idno);
                 document.getElementById('contactNo')?.setAttribute('value',CustomerInformation.tpno);
                 document.getElementById('mail')?.setAttribute('value',CustomerInformation.email);
                 document.getElementById('address')?.setAttribute('value', `${CustomerInformation.addrs1} ${CustomerInformation.addrs2} ${CustomerInformation.addrs3}${CustomerInformation.addrs4}`);
                //  document.getElementById('testOccupation')?.setAttribute('value',CustomerInformation.NAME);
                document.getElementById('testPostal')?.setAttribute('value',CustomerInformation.arecode);
                 document.getElementById('branch')?.setAttribute('value',CustomerInformation.brncode);
            }else{
              console.error("Data array is empty or undefined");
            }

        },
        (error: any) => {
          console.error(error);
        }
      );
    }




}
