export class User {
    username: string;
    code: string;
    epf: string
    branch: string
    desc: string;
    user_id: string
    email: string

    constructor(invg_code: string, epf: string, branch: string, desc: string, user: string, email: string) {
        this.code = invg_code;
        this.epf = epf;
        this.branch = branch;
        this.desc = desc;
        this.user_id = user;
        this.email = email;
    }

    // public static fromJSON(response: any): User {
    //     const { INVG_CODE, EPFNO, BRNCODE, DESCR, USRID, EMAILADD } = response.content[0];
    //     return new User(INVG_CODE, EPFNO, BRNCODE, DESCR, USRID, EMAILADD);
    // }

    public static fromJSON(response: any): User {
        const { cc_USER_CODE, epfno, brncode, descr, resp_CODE, email } = response[0];
        return new User(cc_USER_CODE, epfno, brncode, descr, resp_CODE, email);
    }
}