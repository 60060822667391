<div class="deep-container">
  <app-navigation-bar></app-navigation-bar>
  <!-- <div class="main-container">
        <div class="container-bg">
            <div class="title">
                <h3>Welcome back, {{user}}</h3>
            </div>
        </div>
    </div> -->
  <div class="main-container">
    <div class="container-bg">
      <div class="row">
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view">
            <div class="card-view-inner-div">
              <div class="card-content" (click)="openCallCenterDash()">
                <div class="icon-container icon-container-bg-success">
                  <mat-icon class="card-icon" appearance="fill"
                    >phone_in_talk</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Call Center Dashboard</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="facilityDetails()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-facility">
                  <mat-icon class="card-icon" appearance="fill"
                    >view_week</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Facility Details </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="lead_creation()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Lead creation</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="adminPortel()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Admin Portal</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openNotepad()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-success">
                  <mat-icon class="card-icon" appearance="fill">info</mat-icon>
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Notes</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openTransaction()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >account_balance</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Transaction Summary</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openOtherDetails()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-primary">
                  <mat-icon class="card-icon" appearance="fill"
                    >assignment</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Other Details</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->



        <!-- <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openSummartDashboard()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-info">
                  <mat-icon class="card-icon" appearance="fill"
                    >dashboard</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">
                    Report Summary Dashboard</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

