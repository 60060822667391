<div class="beautiful-border">
    <mat-card class="custom-card">
        <!-- <mat-card class="section-4">

    <mat-card-header>
        <mat-card-title class="summary-title">LEAD CREATION</mat-card-title>


    </mat-card-header>

</mat-card> -->
        <div class="page-header w-100 p-2">
            <span>LEAD CREATION</span>
        </div>
        <!-- <app-dialog-header [title]="headerTitle" (closeDialogEvent)="hanldeClose()"></app-dialog-header> -->
        <form [formGroup]="leadForm" (ngSubmit)="onSubmit()">

            <mat-card class="section-1">
                <div class="card-header w-100 p-2">
                    <span>Client Information</span>
                </div>
                <div class="container-client-information">
                    <div class=" mt-3 form-row">

                        <div class="form-group">
                            <label for="clientName">Client's Name</label>
                            <input class="mb-3  form-control" type="text" placeholder="" formControlName="clientName"
                                style="background-color: #f0f0f0; cursor: pointer;" />
                        </div>

                        <div class="form-group">
                            <label for="contactNumber">Contact Number</label>
                            <input class="mb-3 form-control" type="text" placeholder="" formControlName="contactNo"
                                style="background-color: #f0f0f0; cursor: pointer;"  />
                        </div>
                        <div class="form-group">
                            <label for="nicNo">NIC Number</label>
                            <input class="mb-3 form-control" type="text" placeholder="" formControlName="nicNo"
                                style="background-color: #f0f0f0; cursor: pointer;" />
                        </div>


                    </div>

                    <div class="form-row">


                        <div class="form-group">
                            <label for="address">Address</label>
                            <input class="mb-3 form-control" type="text" placeholder="" formControlName="address"
                                style="background-color: #f0f0f0; cursor: pointer;" />
                        </div>


                        <div class="form-group">
                            <label for="email">Email</label>
                            <input class="mb-3 form-control" type="text" placeholder="" formControlName="email"
                                style="background-color: #f0f0f0; cursor: pointer;" />
                        </div>
                    </div>


                </div>

            </mat-card>


            <mat-card class="section-2">
                <div class="card-header w-100 p-2">
                    <span>Campaign Information</span>
                </div>
                <div class="container-campaign-information">
                    <div class="form-row mt-3">


                        <div class="form-group">
                            <label for="origin">Lead Type</label>
                            <mat-select class="mb-3 form-control" formControlName="leadType" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option value="Customer Lead">Customer Lead</mat-option>
                                <mat-option value="Supplier - Valuation Lead">Supplier - Valuation Lead</mat-option>


                            </mat-select>
                        </div>

                        <div class="form-group">
                            <label for="origin">Channel<span class="required-field">*</span></label>
                            <input type="text" class="mb-3 form-control" 
                            
                                style="background-color: #f0f0f0; cursor: not-allowed;"
                                [value]="channelShow" readonly> 
                           
                        </div>


                        <div class="form-group">
                            <label for="origin">Origin<span class="required-field">*</span></label>
                            <mat-select class="mb-3 form-control" formControlName="origin" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>



                                <ng-container *ngIf="leadForm.get('channel').value === 'CN'">
                                    <mat-option value="01">CF website</mat-option>
                                    <mat-option value="02">Careka.lk</mat-option>
                                    <mat-option value="03">Careka.lk Facebook</mat-option>
                                    <mat-option value="04">Careka.lk Twitter</mat-option>
                                    <mat-option value="05">Careka.lk Instagram</mat-option>
                                    <mat-option value="06">Tukeka.lk Facebook</mat-option>
                                    <mat-option value="07">Tukeka.lk</mat-option>
                                    <mat-option value="08">CF Facebook Page</mat-option>
                                    <mat-option value="09">CF Click Mobile App</mat-option>
                                    <mat-option value="10">Tukeka.lk Facebook Page</mat-option>
                                    <mat-option value="11">TV/ Advertisement</mat-option>
                                    <mat-option value="12">Radio/ Advertisements</mat-option>
                                    <mat-option value="13">Print Media</mat-option>
                                    <mat-option value="14">Eloqua Campaigns (Emails)</mat-option>
                                    <mat-option value="15">Other Sources</mat-option>
                                    <mat-option value="16">Park </mat-option>
                                    <mat-option value="17">Call Centre</mat-option>
                                    <mat-option value="18">Yard</mat-option>
                                    <mat-option value="19">Sales Point</mat-option>
                                    <mat-option value="20">Premature Lease Closure</mat-option>
                                    <mat-option value="21">Leasing.lk</mat-option>
                                    <mat-option value="22">CF Click App - Leasing Promotions</mat-option>
                                    <mat-option value="23">Riyasewana</mat-option>
                                    <mat-option value="24">CF Click App - Valuation Request</mat-option>
                                    <mat-option value="25">Call center - Incoming Calls</mat-option>
                                    <mat-option value="26">CF Click App - Apply for a lease</mat-option>
                                    <mat-option value="27">Call Center - Missed Calls</mat-option>
                                    <mat-option value="28">Riyasewana Website</mat-option>
                                    <mat-option value="29">CRU - Win back customers campaign -Colombo base</mat-option>
                                    <mat-option value="30">Win back customers campaign Kandy base -Predictive
                                        Dialling</mat-option>
                                    <mat-option value="31">Campaign</mat-option>
                                </ng-container>


                                <ng-container *ngIf="leadForm.get('channel').value === 'BR'">
                                    <mat-option value="01">Key Visit</mat-option>
                                    <mat-option value="02">Introducer Business</mat-option>
                                    <mat-option value="03">Direct (Non Introducer)</mat-option>
                                    <mat-option value="04">Repeat Business</mat-option>
                                    <mat-option value="05">Physical Campaign ***</mat-option>
                                    <mat-option value="06">Branch - Mobile Truck Promotion</mat-option></ng-container>


                            </mat-select>
                        </div>




                    </div>
                    <div class="form-row">

                        <div class="form-group">
                            <label for="campaign">Campaign</label>
                            <mat-select class="mb-3 form-control" formControlName="campaign" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option value="001">Town Storming and Leaflet campaign</mat-option>
                                <mat-option value="002">Dealer Promotions</mat-option>
                                <mat-option value="003">Mini Vahana Pola</mat-option>
                                <mat-option value="004">Mega Vahana Pola</mat-option>
                                <mat-option value="005">Branch Premises Promotion</mat-option>
                                <mat-option value="006">Client Segment Focus Promotion</mat-option>
                                <mat-option value="007">Yard Vahana Pola</mat-option>
                                <mat-option value="008">Caller Campaign</mat-option>
                                <mat-option value="009">Branch Digital Campaign</mat-option>

                            </mat-select>
                        </div>

                        <div class="form-group">
                            <label for="vehicleType">Interested Vehicle Type<span
                                    class="required-field">*</span></label>
                            <mat-select class="mb-3 form-control" formControlName="vehicleType"
                                placeholder="-- Select --" style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option value="1">Delivery Vans</mat-option>
                                <mat-option value="2">Tippers</mat-option>
                                <mat-option value="3">Motor Cars</mat-option>
                                <mat-option value="4">Three Wheelers</mat-option>
                                <mat-option value="5">Lorries/Trucks</mat-option>
                                <mat-option value="6">Busses/Motor Coaches</mat-option>

                                <mat-option value="7">Jeeps/Cabs</mat-option>
                                <mat-option value="8">Motor Bicycles</mat-option>
                                <mat-option value="9">Tractors</mat-option>

                                <mat-option
                                    value="10">Machinery/Equipment/Furnitures</mat-option>
                                <mat-option value="11">Consumer</mat-option>
                                <mat-option value="12">Other</mat-option>





                            </mat-select>
                        </div>

                        <div class="form-group">
                            <label for="productType">Product Type<span class="required-field">*</span></label>
                            <mat-select class="mb-3 form-control" formControlName="productType"
                                placeholder="-- Select --" style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option value="01">NEW LEASE </mat-option>
                                <mat-option value="02">LEASE REFINANCE </mat-option>
                                <mat-option value="03">AUTOLOAN </mat-option>
                                <mat-option value="04">AULOLOAN EXPRESS CASH </mat-option>
                                <mat-option value="05">EXPRESS CASH LEASE </mat-option>
                                <mat-option value="06">SUBLOAN / ISL</mat-option>
                                <mat-option value="07">TRANSFR REFINANCE</mat-option>
                                <mat-option value="08">RESCHEDULEMNT</mat-option>
                                <mat-option value="09">BUINESS LOAN</mat-option>
                                <mat-option value="10">EXPRESS OD </mat-option>
                            </mat-select>
                        </div>

                    </div>

                    <div class="form-row">
                        <div class="form-group">
                            <label for="facilityAmount">LMU Officer</label>
                            <mat-select style="background-color: #f0f0f0; cursor: pointer;" class="mb-3 form-control"
                                formControlName="lmuOfficer" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option *ngFor="let officer of officers" [value]="officer.userId">
                                    {{ officer.lmuOfficerName }}
                                </mat-option>
                            </mat-select>

                        </div>


                        <div class="form-group">
                            <label for="facilityAmount">Facility Amount</label>
                            <input class="mb-3 form-control" type="number" placeholder=""
                                formControlName="facilityAmount" style="background-color: #f0f0f0; cursor: pointer;" />
                        </div>

                        <div class="form-group">
                            <label for="facilityAmount">Next Follow Up Date<span class="required-field">*</span></label>
                            <input class="mb-3 form-control" type="date" placeholder="cf@.lk"
                                formControlName="followUpDate" style="background-color: #f0f0f0; cursor: pointer;" />

                        </div>

                    </div>

                    <div class="form-row">



                        <div class="form-group">
                            <label for="facilityAmount">Description<span class="required-field">*</span></label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"
                                formControlName="description"
                                style="background-color: #f0f0f0; cursor: pointer;"></textarea>
                        </div>



                        <div class="form-group">
                            <label class="custom-checkbox">Search for Vehicle</label>
                            <input type="checkbox" id="" class="checkboxx" formControlName="checkBoxControl">

                        </div>







                    </div>



                </div>

            </mat-card>





            <mat-card class="section-3">
                <div class="card-header w-100 p-2">
                    <span>Lead Assignment</span>
                </div>
                <div class="container-client-information">
                    <div class="form-row mt-3">
                        <div class="form-group">
                            <label for="leadType">Branch</label>
                            <mat-select class="mb-3 form-control " formControlName="branch" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>

                                <mat-option *ngFor="let branch of branches" [value]="branch.branchCode">
                                    {{ branch.branchName }}
                                </mat-option>
                            </mat-select>

                        </div>

                        <div class="form-group">
                            <label for="channel">Assignee </label>
                            <mat-select class="mb-3 form-control" formControlName="assignee" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>

                                <mat-option *ngFor="let assignee of assignees" [value]="assignee.userId">
                                    {{ assignee.assigneeName }}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="form-group">
                            <label for="origin">LeadRank</label>
                            <mat-select class="mb-3 form-control" formControlName="leadRank" placeholder="-- Select --"
                                style="background-color: #f0f0f0; cursor: pointer;">
                                <mat-option>-- Select --</mat-option>
                                <mat-option value="Cold">Cold</mat-option>
                                <mat-option value="Warm">Warm</mat-option>
                                <mat-option value="Hot"> Hot</mat-option>
                            </mat-select>
                        </div>

                    </div>
                </div>
            </mat-card>
            <div class="button">

                <button class="custom-button" mat-raised-button color="primary" type="submit" [disabled]="leadForm.invalid">
                    Create Lead
                </button>
              

            </div>
            <br>

        </form>
    </mat-card>


</div>