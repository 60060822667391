import { Component, OnInit } from '@angular/core';
import { HomeComponentV2Component } from '../home-component-v2/home-component-v2.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LeadCreationService } from 'src/app/services/lead-creation/lead-creation.service';
import { DatePipe } from '@angular/common';
import { ok } from 'assert';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { Console } from 'console';
import { ActivatedRoute } from '@angular/router';


interface Branch {
  branchName: string;
  branchCode: string;
}
interface ClientInfo {
  clientName: string;
  clientAddress: string;
  clientNic: string;
  clientEmail: string;
}


interface Channel {
  isChannel: boolean
}
interface LmuOfficer {
  userId: string;
  lmuOfficerName: string;
}

interface Assignee {
  userId: string;
  assigneeName: string;
}

@Component({
  selector: 'app-lead-creation',
  templateUrl: './lead-creation.component.html',
  styleUrls: ['./lead-creation.component.scss'],
  providers: [DatePipe],
})
export class LeadCreationComponent implements OnInit {

  channelShow: string;
  isChannel: boolean;
  contactNo: string;
  leadForm: FormGroup;
  checkBoxStatus: boolean = false;
  readonly headerTitle: string = "LEAD CREATION";
  branches: Branch[] = [];
  assignees: Assignee[] = [];
  officers: LmuOfficer[] = [];
  channel: Channel;
  clientInfo: ClientInfo;

  constructor(
    private route: ActivatedRoute,
    // private dialogRef: MatDialogRef<HomeComponentV2Component>,
    private auth: UserAuthenticationService,
    private datePipe: DatePipe,
    private leadCreationService: LeadCreationService,
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.contactNo = params.get('contactNumber');
    });

    this.leadForm = this.formBuilder.group({
      clientName: [''],
      contactNo: [this.contactNo],
      nicNo: ['', [conditionalMinLength()]],
      address: [''],
      email: [''],
      leadType: [''],
      channel: ['', Validators.required],
      origin: ['', Validators.required],
      campaign: [''],
      vehicleType: ['', Validators.required],
      productType: ['', Validators.required],
      lmuOfficer: [''],
      facilityAmount: [''],
      followUpDate: ['', Validators.required],
      description: ['', Validators.required],
      checkBoxControl: [false],
      branch: [''],
      assignee: [''],
      leadRank: [''],
    });
    //Lead Creation mobile number Validation
    if(this.contactNo.length>8){
      this.getClientInfo(this.contactNo);
    }

    this.getChannel();
    this.getAllBranchesList();
    this.getLmuOfficersList();

    this.leadForm.get('branch').valueChanges.subscribe(selectedBranchCode => {
      this.getAssigneeList(selectedBranchCode);
    });
    this.leadForm.get('contactNo').valueChanges.subscribe(value => {
      if (value && value.length > 8) {
        this.getClientInfo(value);
      }
    });


  }
  // hanldeClose() {
  //     this.dialogRef.close();
  //   }

  onSubmit() {

    if (this.leadForm.valid) {

      const checkBoxValue = this.leadForm.get('checkBoxControl').value;
      const checkBoxValueAsString = checkBoxValue ? "1" : "0";
      const formattedDate = this.datePipe.transform(this.leadForm.value.followUpDate, 'dd-MM-yyyy');
      // console.log(this.leadForm.get('channel').value + "post")
      const user_name = this.auth.currentUserValue.username;
      const formData = {
        ...this.leadForm.value,
        checkBoxControl: checkBoxValueAsString,
        followUpDate: formattedDate,
        user: user_name

      };


      const isConfirmed = window.confirm('Are you sure you want to submit the form?');
      if (isConfirmed) {


        //customer info   Post Service
        const user_name = this.auth.currentUserValue.username;
        const postData = {
          clName: this.leadForm.value.clientName,
          contactNo: this.leadForm.value.contactNo,
          nic:this.leadForm.value.nicNo ,
          address: this.leadForm.value.address,
          email: this.leadForm.value.email,

        }
        this.leadCreationService.updateCustomerInfo(postData)
          .subscribe(response => {
            console.log('POST Response:', response);
          });

        //Report Summary Post Service
        this.leadCreationService.updateLeadCreation(formData)
          .subscribe(response => {

            if (response.status === "true") {
              // console.log('POST Response:', response);

              // Lead creation successful
              alert('Lead has been created successfully!');
              this.leadForm.reset();
              this.getChannel();
            } else {
              // Lead creation failed
              alert('Lead creation failed. Please try again later.');
              console.error('Lead creation failed:', response?.message);
              // Optionally display an error message
            }
          }

          );
      }

    } else {
      alert('Lead creation failed. Please try again later.');
      console.error('Form is not valid');
    }

  }
  getAllBranchesList(): void {

    const user_name = this.auth.currentUserValue.username;
    this.leadCreationService.getBranchesList(user_name).subscribe(
      (data: any[]) => {

        if (data && data.length > 0) {
          this.branches = data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );

  }

  getAssigneeList(branchCode: string): void {

    this.leadCreationService.getAssigneeList(branchCode).subscribe(
      (data: any[]) => {
        if (data && data.length > 0) {
          console.log(data)
          this.assignees = data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }
  getLmuOfficersList(): void {

    const user_name = this.auth.currentUserValue.username;
    this.leadCreationService.getLmuOfficersList(user_name).subscribe(
      (data: any[]) => {

        if (data && data.length > 0) {
          this.officers = data;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );

  }
  getChannel(): void {

    const user_name = this.auth.currentUserValue.username;

    this.leadCreationService.getchannel(user_name).subscribe(
      (data: any) => {

        this.channel = data;


        this.channelShow = this.channel.isChannel ? "Branch" : "Center";


        this.leadForm.controls['channel'].setValue(this.channel.isChannel ? 'BR' : 'CN');


      },
      (error: any) => {
        console.error(error);
      }
    );

  }
  getClientInfo(contactNo: string): void {

    this.leadCreationService.getClientInfo(contactNo).subscribe(
      (data: any) => {


        this.clientInfo = data;

        this.leadForm.patchValue({ clientName: this.clientInfo.clientName });
        this.leadForm.patchValue({ nicNo: this.clientInfo.clientNic });
        this.leadForm.patchValue({ address: this.clientInfo.clientAddress });
        this.leadForm.patchValue({ email: this.clientInfo.clientEmail });

      },
      (error: any) => {
        console.error(error);
      }
    );

  }


}
function conditionalMinLength(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {

      return null;
    }
    return Validators.minLength(10)(control);
  };
}
