import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';

@Injectable({
  providedIn: 'root'
})
export class SingleContarctOutstandingService {
  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient
  ) {
    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl1;
    } else {
      return recoveryConfig.recoveryServiceUrl1;
    }
  }

  getSingleContractOutstanding(facNo: string, sDate: string): Observable<any> {
    const url = `${this.apiUrl}/singleContractOutstanding/${facNo}/${sDate}`;
    return this.http.get<any>(url);
  }

  geteqplotByFacno(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/eqplotByFacno/${facNo}`;
    return this.http.get<any>(url);
  }

  getClientDetails(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getClientsByfacNo/${facNo}`;
    return this.http.get<any>(url);
  }

  getLastPayDetails(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getLatPayment/${facNo}`;
    return this.http.get<any>(url);
  }

  getFacilityDetails(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getFacilityDetails/${facNo}`;
    return this.http.get<any>(url);
  }

  getContractDetailsMB(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getContractDetailsMB/${facNo}`;
    return this.http.get<any>(url);
  }

  getRentalInformation(facNo: string): Observable<any> {
    const url = `${this.apiUrl}/contract-details/getRentalInformation/${facNo}`;
    return this.http.get<any>(url);
  }

  // Facility Inquiry Details
  getFacilityInquiryDetailss(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/FacilityInquiry/getFacilityInquiryDetails/${contractNo}`;
    return this.http.get<any>(url);
  }

  getLastPayDatePayment(nic: string): Observable<any> {
    const url = `${this.apiUrl}/recovery/get-multiple-contract-by-nic/${nic}`;
    return this.http.get<any>(url);
  }
}
