<div class="header-container">
    <div class="row header-row">
        <div class="header-item header-title">   
            {{title}}
        </div>
        <div class="header-item header-close">
            <button mat-mini-fab color="white" (click)="closeDialog()">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </div>
    </div>
</div>
