import { Component, Inject, OnInit } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { CustomerContractListComponent } from '../../customer-contract-list/customer-contract-list.component';
import { SingleContarctOutstandingService } from 'src/app/services/single-contract-details/single-contarct-outstanding.service';
import { SingleContarctOutstandingRequestModel } from 'src/app/models/single-contract-details/single-contract-outstanding-request-model';
import { FacilityInquiryService } from 'src/app/services/facility-inquiry/facility-inquiry.service';
import { eqplotByFacnodetailsResponseModel } from 'src/app/models/single-contract-details/eqplotByFacno-details-response-model';
import { PopUpOpenNotepadComponent } from '../../pop-up-open-notepad/pop-up-open-notepad.component';
import { FacilityInquiryComponent } from '../../facility-inquiry/facility-inquiry.component';
import { TransactionSummaryComponent } from '../../transaction-summary/transaction-summary.component';
import { OtherDetailsComponent } from '../../other-details/other-details.component';
import { RentalInformationModel } from 'src/app/models/single-contract-details/rental-information-model.model';
import { DataService } from '../../../services/dataSendingService/data.service';
import { ActivatedRoute } from '@angular/router';
import { ContractNoService } from '../../../services/contractNo/contract-no.service';

eqplotTableDetails: new eqplotByFacnodetailsResponseModel();
interface test {
  dtype?: string;
  dueAmt?: string;
  totPaid?: string;
  waived?: string;
  totOutBalance?: string;
  amtDefered?: string;
  settleAmt?: string;
}

@Component({
  selector: 'app-contract-information',
  templateUrl: './contract-information.component.html',
  styleUrls: ['./contract-information.component.scss']
})
export class ContractInformationComponent implements OnInit {

  // contractNo = "4733018839";
  // strdate = "08-Jan-23";
  contractNo: string;
  branchname:string;
  strdate = this.getDateFormatted();
  In_Bal_Nof_Rentls = "8";

  // contractNo: string;
  // strdate: string;
  // In_Bal_Nof_Rentls: string;
  idNo: string;
  outstandingAmount: string = '';
  lstPayAmount: string = '';
  netrntl: string = '';
  facamt: string = '';
  arraes: string = '';
  settled_: string = '';
  totdebit_: string = '';
  perIod_: string = '';

  displayedColumnsRentalInfor: string[] = ['rentals', 'grossAmount', 'capitalAmount', 'capitaPaid', 'interestAmount', 'interestPaid', 'gstDue', 'gstPaid', 'stampDuty', 'grossPaid', 'dueDate', 'status', 'balance'];
  displayedColumns: string[] = ['dtype', 'dueAmt', 'totPaid', 'waived', 'totOutBalance'];
  dataSource2 = new MatTableDataSource<RentalInformationModel>(ELEMENT_DATA2);
  dataSource = new MatTableDataSource<test>(ELEMENT_DATA);
  // outDataTable: any;
  readonly headerTitle: string = "Single Contract Detail View";
  checkbox1: boolean = false;
  checkbox2: boolean = true;
  checkbox3: boolean = false;

  constructor(
    private contractService: ContractNoService,
    private route: ActivatedRoute,
    private facilityInquiryService: FacilityInquiryService,
    private singleContarctOutstandingService: SingleContarctOutstandingService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    // private dialogRef: MatDialogRef<CustomerContractListComponent>,
    private dataService: DataService
  ) { }

  // sendData(data: any) {
  //   this.dataService.setData(data, this.contractNo, this.idNo);
  // }

  ngOnInit(): void {
    this.contractNo = this.contractService.getContractNo(); // Get contractNo from service
    console.log('Contract Number:', this.contractNo);
    // this.route.params.subscribe(params => {

    //   this.contractNo = params['contractNo'];

    //   console.log('local',this.contractNo)
    //   // Save contractNo to local storage
    //   if (this.contractNo) {
    //     console.log('local',this.contractNo)
    //     localStorage.setItem('contractNo', this.contractNo);
    //     console.log('hi');
    //     console.log('Contract Number from local storage:', this.contractNo);
    //   }
    // });

    this.route.paramMap.subscribe(params => {
      this.contractNo = params.get('facNumber');
      console.log("favNumber", this,this.contractNo)
      if (this.contractNo) {
        console.log('local',this.contractNo)
        localStorage.setItem('contractNo', this.contractNo);
        console.log('Contract Number from local storage:', this.contractNo);
      }
    });

    // this.contractNo = this.data.contractNo;
    // this.strdate = this.data.strdate;
    // this.In_Bal_Nof_Rentls = this.data.In_Bal_Nof_Rentls;

    this.getOutTableData();
    this.geteqplotTableData();
    this.getClientDetails();
    this.getLastPayDetails();
    this.getFacilityDetails();
    this.getContractDetails();
    this.getRentalInformation();
    this.getFacilityInquiryDetails();

    //last pay date and amount
    // this.getLastPayDatePayment();
  }

  // close model function
  // hanldeClose() {
  //   this.dialogRef.close();
  // }

  //Qustion checkbox
  onCheckboxChange(checkboxNumber: number) {
    switch (checkboxNumber) {
      case 1:
        // Handle changes for Checkbox 1
        console.log('Checkbox 1 is now ' + this.checkbox1);
        break;
      case 2:
        // Handle changes for Checkbox 2
        console.log('Checkbox 2 is now ' + this.checkbox2);
        break;
      case 3:
        // Handle changes for Checkbox 3
        console.log('Checkbox 3 is now ' + this.checkbox3);
        break;
    }
  }


  //Total in table value
  getTotalDueAmount(): number {
    return this.dataSource.data.map(t => parseFloat(t.dueAmt) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalPaid(): number {
    return this.dataSource.data.map(t => parseFloat(t.totPaid) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalWaived(): number {
    return this.dataSource.data.map(t => parseFloat(t.waived) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
  getTotalOutstandingBalance(): number {
    return this.dataSource.data.map(t => parseFloat(t.totOutBalance) || 0)
      .reduce((acc, value) => acc + value, 0);
  }

  getOutTableData(): void {
    this.facilityInquiryService.getFacilityInquiryOutstanding(this.contractNo, this.strdate).subscribe(
      (data: any) => {
        // this.outDataTable = data;
        this.dataSource.data = data as test[];
        console.log(data + "data");
        console.log("Popup outstanding table data loaded");
        if(data && data.length > 0){

          const outstanding = data[0];
          // const outstandingAmount = document.getElementById('outstandingAmount');
          // outstandingAmount.innerText = outstanding.outstandingAmount;
          this.outstandingAmount = parseFloat(outstanding.outstandingAmount).toFixed(2);
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  geteqplotTableData(): void {
    this.singleContarctOutstandingService.geteqplotByFacno(this.contractNo).subscribe(
      (data: any) => {
        console.log("dats is: ", data);
        if (data && data.length > 0) {
          const dataSet = data[0];
          // document.getElementById('facamt')?.setAttribute('value', dataSet.facamt);
          // document.getElementById('descr')?.setAttribute('value', dataSet.descr);
          // const descrElement = document.getElementById('descr');
          // descrElement.innerText = dataSet.descr;
          document.getElementById('eqtype')?.setAttribute('value', dataSet.eqtype);
          this.facamt = parseFloat(dataSet.facamt).toFixed(2);
          const eqtypeElement = document.getElementById('eqtype');
          eqtypeElement.innerText = dataSet.eqtype;
          document.getElementById('facno')?.setAttribute('value', dataSet.facno);
          document.getElementById('cl_title_name')?.setAttribute('value', dataSet.cl_title_name);
          document.getElementById('Facno')?.setAttribute('value', dataSet.facno);

        } else {
          console.error("Data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }



  getLastPayDetails(): void {
    this.singleContarctOutstandingService.getLastPayDetails(this.contractNo).subscribe(
      (data: any) => {
        console.log("dats is: ", data);
        if (data && data.length > 0) {
          const dataSet = data[0];
          // document.getElementById('ldate')?.setAttribute('value', dataSet.date);
          // document.getElementById('lstPayAmount')?.setAttribute('value', dataSet.lstPayAmount);
          const ldateElement = document.getElementById('ldate');
          ldateElement.innerText = dataSet.date;
          this.lstPayAmount = parseFloat(dataSet.lstPayAmount).toFixed(2);
        } else {
          console.error("Data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }


  // getClientDetails(): void {
  //   this.singleContarctOutstandingService.getClientDetails(this.contractNo).subscribe(
  //     (data: any) => {
  //       console.log("dats is: ", data);
  //       if (data && data.length > 0) {
  //         const dataSet = data[0];
  //         this.idNo = dataSet.idno;
  //         document.getElementById('idno')?.setAttribute('value', dataSet.idno);
  //         document.getElementById('cltitle')?.setAttribute('value', dataSet.cltitle);
  //         document.getElementById('descr')?.setAttribute('value', dataSet.descr);
  //         document.getElementById('lname')?.setAttribute('value', dataSet.name);
  //         document.getElementById('ceft_ACCNO')?.setAttribute('value', dataSet.ceft_ACCNO);
  //         const initials = document.getElementById('initials');
  //         initials.innerText = dataSet.initials;

  //       } else {
  //         console.error("Data array is empty or undefined");
  //       }
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }

  //last payment date and last payment amount

  // getLastPayDatePayment(): void {
  //   console.log("nic loadinghhhhhhhhhhhhhhhhhhhhhhh", this.idNo)
  //   this.singleContarctOutstandingService.getLastPayDatePayment().subscribe(
  //     (data: any) => {
  //       console.log("dats is: ", data);
  //       if (data && data.length > 0) {
  //         const dataSet = data[0];
  //         document.getElementById('last_date')?.setAttribute('value', dataSet.lastPayDate);
  //         document.getElementById('last_PayAmount')?.setAttribute('value', dataSet.lastPaymentAmount);
  //       } else {
  //         console.error("Data array is empty or undefined");
  //       }
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }

  getClientDetails(): void {
    this.singleContarctOutstandingService.getClientDetails(this.contractNo).subscribe(
      (data: any) => {
        console.log("Client data: ", data);
        if (data && data.length > 0) {
          const clientData = data[0];
          this.idNo = clientData.idno;
          this.setClientDetailsInView(clientData);
          // Call to fetch last payment details after setting client ID
          this.getLastPayDatePayment(this.idNo);
        } else {
          console.error("Client data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error("Error fetching client details:", error);
      }
    );
  }

  setClientDetailsInView(clientData: any): void {

    // document.getElementById('idno')?.setAttribute('value', clientData.idno);
    // document.getElementById('cltitle')?.setAttribute('value', clientData.cltitle);
    // document.getElementById('lname')?.setAttribute('value', clientData.name);
    // document.getElementById('descr')?.setAttribute('value', clientData.descr);
    // document.getElementById('ceft_ACCNO')?.setAttribute('value', clientData.ceft_ACCNO);
    const idnoElement = document.getElementById('idno');
    idnoElement.innerText = clientData.idno;
    const ltitleElement = document.getElementById('cltitle');
    ltitleElement.innerText = clientData.cltitle;
    const lnameElement = document.getElementById('lname');
    lnameElement.innerText = clientData.name;
    const descrElement = document.getElementById('descr');
    descrElement.innerText = clientData.descr;
    const ceft_ACCNOElement = document.getElementById('ceft_ACCNO');
    ceft_ACCNOElement.innerText = clientData.ceft_ACCNO;

    const initialsElement = document.getElementById('initials');
    if (initialsElement) {
      initialsElement.innerText = clientData.initials;
    }
  }

    //last payment date and last payment amount
  getLastPayDatePayment(idNo: string): void {

    console.log("Fetching last payment details for ID:", idNo);
    this.singleContarctOutstandingService.getLastPayDatePayment(idNo).subscribe(
      (data: any) => {
        console.log("Last payment data: ", data);
        if (data && data.length > 0) {
          const paymentData = data[0];
          this.setLastPaymentDetailsInView(paymentData);
        } else {
          console.error("Payment data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error("Error fetching last payment details:", error);
      }
    );
  }

  setLastPaymentDetailsInView(paymentData: any): void {
    document.getElementById('last_date')?.setAttribute('value', paymentData.lastPayDate);
    document.getElementById('last_PayAmount')?.setAttribute('value', paymentData.lastPaymentAmount);
  }





  getFacilityDetails(): void {
    this.singleContarctOutstandingService.getFacilityDetails(this.contractNo).subscribe(
      (data: any) => {
        console.log("dats is: ", data);
        if (data && data.length > 0) {''
          const dataSet = data[0];
          // document.getElementById('branchname')?.setAttribute('value', dataSet.branchname);
          // document.getElementById('facsts')?.setAttribute('value', dataSet.facsts);
          // document.getElementById('vehicalNo')?.setAttribute('value', dataSet.vehicalNo);
          const branchnameElement = document.getElementById('branchname');
          branchnameElement.innerText = dataSet.branchname;
          const facstsElement = document.getElementById('facsts');
          facstsElement.innerText = dataSet.facsts;
          const vehicalNoElement = document.getElementById('vehicalNo');
          vehicalNoElement.innerText = dataSet.vehicalNo;

          document.getElementById('expirydte')?.setAttribute('value', dataSet.expirydte);

        } else {
          console.error("Data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  getContractDetails(): void {
    this.singleContarctOutstandingService.getContractDetailsMB(this.contractNo).subscribe(
      (data: any) => {
        console.log("dats is: ", data);
        if (data && data.length > 0) {
          const dataSet = data[0];
          // document.getElementById('intrte')?.setAttribute('value', dataSet.intrte);
          // document.getElementById('activatedDate')?.setAttribute('value', dataSet.activatedDate);
          // document.getElementById('duedte')?.setAttribute('value', dataSet.duedte);
          // document.getElementById('status')?.setAttribute('value', dataSet.facsts);
          const intrteElement = document.getElementById('intrte');
          intrteElement.innerText = dataSet.intrte;
          const activatedDateElement = document.getElementById('activatedDate');
          activatedDateElement.innerText = dataSet.activatedDate;
          const duedteElement = document.getElementById('duedte');
          duedteElement.innerText = dataSet.duedte;
          const statusElement = document.getElementById('status');
          statusElement.innerText = dataSet.facsts;


          document.getElementById('outstanding')?.setAttribute('value', dataSet.outstanding);
          // document.getElementById('netrntl')?.setAttribute('value', dataSet.netrntl);
          this.netrntl = parseFloat(dataSet.netrntl).toFixed(2);

          document.getElementById('totalrentals')?.setAttribute('value', dataSet.totalrentals);
          document.getElementById('rendebited')?.setAttribute('value', dataSet.rendebited);
          document.getElementById('settled')?.setAttribute('value', dataSet.settled);

        } else {
          console.error("Data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //link components
  openNotepad() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.contractNo,
      strdate: this.strdate,
      In_Bal_Nof_Rentls: this.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(PopUpOpenNotepadComponent, {
      data: data,
    });
  }

  facilityInquiry() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.contractNo,
      strdate: this.strdate,
      In_Bal_Nof_Rentls: this.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(FacilityInquiryComponent, dialogConfig);
    dialogRef.componentInstance.data = data;
  }

  // Transaction Summary open Button - Bilesh Sashin (2023/11/07)
  openTransaction() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.contractNo,
      strdate: this.strdate,
      In_Bal_Nof_Rentls: this.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(TransactionSummaryComponent, dialogConfig);
    dialogRef.componentInstance.data = data;
  }

  // Open other details view
  openOtherDetails() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.contractNo,
      idNo: this.idNo,
    };

    this.dataService.setData(data, this.contractNo, this.idNo);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(OtherDetailsComponent, dialogConfig);
    dialogRef.componentInstance.data = data;
  }

  getRentalInformation(): void {
    this.singleContarctOutstandingService.getRentalInformation(this.contractNo).subscribe(
      (data: any) => {
        this.dataSource2.data = data as RentalInformationModel[];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Facility Inquiry Details
  getFacilityInquiryDetails(): void {
    this.singleContarctOutstandingService.getFacilityInquiryDetailss(this.contractNo).subscribe(
      (data: any) => {

        console.log("Facility Inquiry Details data is: ", data);
        if (data && data.length > 0) {
          const firstItem = data[0];

          // document.getElementById('perIod_')?.setAttribute('value', firstItem.perIod);
          // document.getElementById('totdebit_')?.setAttribute('value', firstItem.totdebit);
          // document.getElementById('settled_')?.setAttribute('value', firstItem.settled);
          // document.getElementById('arraes_')?.setAttribute('value', firstItem.arraes);
          this.perIod_ = parseFloat(firstItem.perIod).toFixed(2);
          this.totdebit_ = parseFloat(firstItem.totdebit).toFixed(2);
          this.settled_ = parseFloat(firstItem.settled).toFixed(2);
          this.arraes = parseFloat(firstItem.arraes).toFixed(2);

        } else {
          console.error("Data array is empty or undefined.");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // Current Date
  getDateFormatted() {
    const date = new Date();
    const day = date.getDate();
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of the year
    return `${day.toString().padStart(2, '0')}-${month}-${year}`;
  }

}


const ELEMENT_DATA: test[] = [];
const ELEMENT_DATA2: RentalInformationModel[] = [];
