// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: "local",
  production: false,
  recoveryConfig: {
    env: "local",
    basePath: '',
    // appServerUrl: "http://localhost:4200",

   //Development

    // Local Enviroment
    // appServerUrl1: "http://localhost:8080/cf-crm-cc",
    // recoveryServiceUrl1: "http://localhost:8080/cf-crm-cc",

    // UAT Enviroment
    // appServerUrl1: "https://cfmobile.lk/cfuat-cf-crm-cc",
    // recoveryServiceUrl1: "https://cfmobile.lk/cfuat-cf-crm-cc",

    // Live Enviroment
    appServerUrl1: "https://cfmobile.lk/cf-crm-cc",
    recoveryServiceUrl1: "https://cfmobile.lk/cf-crm-cc",

    // appServerUrl: "https://cfmobile.lk/CFBBService.svc",
    recoveryServiceUrl: "https://cfmobile.lk/CFBBService.svc",
    CRMNotePadviewServiceUrl: "https://cfmobile.lk/crmesbtest/ESBInfotest/CRMNotePad/CRMNotePadview?FACNO=",
    openInquiryServiceUrl:"https://www.cfmobile.lk/crmesb/ESBInfo/CRMCallCenter/CRMCallCenter?OPPORTUNITYID=",
    ClientExposureServiceUrl: "https://www.cfmobile.lk/crmesb/ESBInfo/CRMClientExposure/ClientExposure?IDNO=",
    CRMTransactionSummeryServiceUrl: "https://cfmobile.lk/crmesbtest/ESBInfotest/CRMTransactionSummery/CRMTransactionSummery?OPPORTUNITYID=",
    idleTimeInMinutes: 60*12,

    // production
    appServerUrl: "https://cfmobile.lk/BB_APP_LIVE/CFBBService.svc"
    // recoveryServiceUrl: "https://cfmobile.lk/BB_APP_LIVE/CFBBService.svc",
    // CRMNotePadviewServiceUrl: "https://cfmobile.lk/crmesb/ESBInfo/CRMNotePad/CRMNotePadview?FACNO=",
    // openInquiryServiceUrl:"https://www.cfmobile.lk/crmesb/ESBInfo/CRMCallCenter/CRMCallCenter?OPPORTUNITYID=",
    // ClientExposureServiceUrl: "https://www.cfmobile.lk/crmesb/ESBInfo/CRMClientExposure/ClientExposure?IDNO=",
    // CRMTransactionSummeryServiceUrl: "https://cfmobile.lk/crmesb/ESBInfo/CRMTransactionSummery/CRMTransactionSummery?OPPORTUNITYID="

  }

};

