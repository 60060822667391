import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OtherDetailsModel } from 'src/app/models/other-details/OtherDetailModel';
import { OtherDetailsService } from 'src/app/services/otherDetailsService/otherDetails.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../services/dataSendingService/data.service';
@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.scss']
})
export class ActiveComponent implements OnInit {
  receivedData: any;
  selectedPage: string = ''; // Variable to hold the selected page value
  title: string = 'Other Details';
  list: string[] = ['contactno'];
  NIC: string;
  GPCODE: string = "GP119"
  group_list: any[];
  contractNo: string;
  ContractData: any;
  dataSource = new MatTableDataSource<OtherDetailsModel>();
  displayedColumns: string[] = ['contract', 'type', 'facsts', 'month', 'noarrs', 'dpd', 'amount'];

  constructor(private OtherDetailsService: OtherDetailsService, private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.receivedData = this.dataService.getData();
    this.contractNo = this.receivedData.contractNo;
    this.NIC = this.receivedData.idNo
    // alert(this.contractNo)
    // alert(this.NIC)
  }

  ngOnInit(): void {
    this.getOtherDetailsData()
    this.getFacilityList();
  }

  getOtherDetailsData(): void {
    this.OtherDetailsService.getActiveDetails(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the active data set :", dataset);
        document.getElementById('contractNumber')?.setAttribute('value', this.contractNo);

        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('NOOFCONTRACTS')?.setAttribute('value', data.details);
                document.getElementById('NOOFCONTRACTSValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('TOTALAMOUNTFINANCED')?.setAttribute('value', data.details);
                document.getElementById('TOTALAMOUNTFINANCEDValues')?.setAttribute('value', data.values);
                break;
              case "MONTHLY COMMITMENT":
                document.getElementById('MONTHLYCOMMITMENT')?.setAttribute('value', data.details);
                document.getElementById('MONTHLYCOMMITMENTValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('STOCKBALANCE')?.setAttribute('value', data.details);
                document.getElementById('STOCKBALANCEValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL ARREARS":
                document.getElementById('TOTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('TOTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "RENTAL ARREARS":
                document.getElementById('RENTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('RENTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT ARREARS":
                document.getElementById('DEFAULTARREARS')?.setAttribute('value', data.details);
                document.getElementById('DEFAULTARREARSValues')?.setAttribute('value', data.values);
                break;
              case "INSURANCE ARREARS":
                document.getElementById('INSURANCEARREARS')?.setAttribute('value', data.details);
                document.getElementById('INSURANCEARREARSValues')?.setAttribute('value', data.values);
                break;
              case "ARREARS < 3":
                document.getElementById('ARREARS<3')?.setAttribute('value', data.details);
                document.getElementById('ARREARS<3Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS 3-6":
                document.getElementById('ARREARS3-6')?.setAttribute('value', data.details);
                document.getElementById('ARREARS3-6Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS > 6":
                document.getElementById('ARREARS>6')?.setAttribute('value', data.details);
                document.getElementById('ARREARS>6Values')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  //facilty data list table feature
  getFacilityList(): void {
    this.OtherDetailsService.getODFacListActive(this.contractNo).subscribe(
      (data: any) => {
        // alert(this.contractNo)
        if (data.length > 0) {
          console.debug("otherdetails facility list loaded,", "Data:", data)
          // alert("No records to load.")
          this.dataSource.data = data as OtherDetailsModel[];
        }
        else if (data.length > 0) {
          alert("No records to load.")
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

}
