<div>
    <div class="row">
        <div class="col card-view card-veiw-tile my-2 pb-2">
            <div class="px-1 ">
                <h5 class="mt-2">Contact Type</h5>
            </div>
            <div class="px-1 ">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="contact_type">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <mat-radio-button color="primary" [value]="priCN" [disabled]="!priCN">
                                <span style="font-size: 14px; text-overflow:ellipsis">Primay Contact Number
                                    {{priCN || "---"}}</span>
                            </mat-radio-button>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-radio-button color="primary" [value]="secCN" [disabled]="!secCN">
                                <span style="font-size: 14px; text-overflow:ellipsis">Secondary Contact Number
                                    {{secCN || "---"}}</span>
                            </mat-radio-button>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-radio-button color="primary" [value]="tpCN" [disabled]="!tpCN">
                                <span style="font-size: 14px; text-overflow:ellipsis">Third Party Contact Number
                                    {{tpCN || "---"}}</span>
                            </mat-radio-button>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-radio-button color="primary" [value]="gauCN" [disabled]="!gauCN">
                                <span style="font-size: 14px; text-overflow:ellipsis"> Guarantor {{gauCN || "---"}}</span>
                            </mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class = "py-3 d-flex">
        <button mat-raised-button color = "primary" style="margin-right: 1rem;" (click)="dial()">Dial</button>
        <button mat-raised-button color = "warn" style="margin-right: 1rem;" (click)="closeDialog()">Close</button>
    </div>
</div>