import { Injectable,Inject} from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GrouprelatedService {
  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) { 
      this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);
      // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
    }
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
      if (recoveryConfig.env === 'local') {
        return recoveryConfig.appServerUrl1;
      } else {
        return recoveryConfig.recoveryServiceUrl1;
      }
    }
 
//Group related filtering Part
getGroupRelated(contractNo: string ): Observable<any> {
      const url = `${this.apiUrl}/other-details/group/group-id-list/${contractNo}`;
      return this.http.get<any>(url);
    }


//Group related Activity 
getActivietyGroup(NIC:string,GPCODE:string):Observable<any>{
      const url = `${this.apiUrl}/other-details/group/active/${GPCODE}/${NIC}`;
      return this.http.get<any>(url);
    }

getActivietyGroupDet(NIC:string,GPCODE:string): Observable<any>{
      const url = `${this.apiUrl}/other-details/group-det/active/${GPCODE}/${NIC}`;
      return this.http.get<any>(url);
    }

//Group related normmal clourses 

getNormalClourses(NIC:string,GPCODE:string):Observable<any>{
  const url = `${this.apiUrl}/other-details/group/normal/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
 }
getNormalCloursesDet(NIC:string,GPCODE:string): Observable<any>{
  const url = `${this.apiUrl}/other-details/group-det/normal/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
}

//Group Related Repossess
getRepossess(NIC:string,GPCODE:string):Observable<any>{
  const url = `${this.apiUrl}/other-details/group/repossess/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
 }

getRepossessDet(NIC:string,GPCODE:string):Observable<any>{
  const url = `${this.apiUrl}/other-details/group-det/repossess/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
 }
 //Group related  Guaranteed
getGuaranteed(NIC:string,GPCODE:string):Observable<any>{
  const url = `${this.apiUrl}/other-details/group/guaranteed/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
 }

getGuaranteedDet(NIC:string,GPCODE:string):Observable<any>{
  const url = `${this.apiUrl}/other-details/group-det/guaranteed/${GPCODE}/${NIC}`;
  return this.http.get<any>(url);
 }
 
// Active Arreares
  // getActiveAllGRArrears(NIC: string): Observable<any> {
  //   const url =`${this.apiUrl}/other-details/active/${NIC}/1`;
  //   return this.http.get<any>(url);
  // }

  // getActive0To3GRArrears(NIC: string): Observable<any> {
  //   const url =`${this.apiUrl}/other-details/active/${NIC}/2`;
  //   return this.http.get<any>(url);
  // }

  // getActive3To6GRArrears(NIC: string): Observable<any> {
  //   const url =`${this.apiUrl}/other-details/active/${NIC}/3`;
  //   return this.http.get<any>(url);
  // }

  // getActiveThan6GRArrears(NIC: string): Observable<any> {
  //   const url =`${this.apiUrl}/other-details/active/${NIC}/4`;
  //   return this.http.get<any>(url);
  // }

 //Guaranteed Arreares
//  getGuaranteedArrearesAll(NIC:string):  Observable<any> {
//   const url = `${this.apiUrl}/other-details/guaranteed/${NIC}/1 `;
//   return this.http.get<any>(url);
//  }
//  getGuaranteed0To3GRArrears(NIC:string):  Observable<any> {
//   const url = `${this.apiUrl}/other-details/guaranteed/${NIC}/2 `;
//   return this.http.get<any>(url);
//  }
//  getGuaranteed3To6GRArrears(NIC:string):  Observable<any> {
//   const url = `${this.apiUrl}/other-details/guaranteed/${NIC}/3 `;
//   return this.http.get<any>(url);
//  }
//  getGuaranteedThan6GRArrears(NIC:string):  Observable<any> {
//   const url = `${this.apiUrl}/other-details/guaranteed/${NIC}/4 `;
//   return this.http.get<any>(url);
//  }
 
}
