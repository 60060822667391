import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Config } from 'src/config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportSummaryService {
  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl;
    } else {
      return recoveryConfig.recoveryServiceUrl;
    }
  }

  // POST Report Summary Dashboard
  updateReportSummary(selectedRows: any[]): Observable<any> {
    const url = `${this.apiUrl}/updateReportSummary`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }

  //POST Question List
  updateQuestionList(selectedRows: any): Observable<any> {
    const url = `${this.apiUrl}/selectedQuestions`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }

  // GET Question List
  getQuestionList(userId: string): Observable<any> {
    const url = `${this.apiUrl}/get-selected-questions/${userId}`;
    return this.http.get<any>(url);
  }
}

