<div class="deep-container">
  <app-navigation-bar></app-navigation-bar>
  <div class="flex-wrap mt-5">
    <!-- <div class="row">
      <div class="col-12 text-center">
        <h2>Login to Recovery App</h2>
      </div>
    </div> -->

    <div class="">
      <div class="w-100">
        <div class="p-4 login-form-wrapper">
          <div class="w-100 form-wrapper-animated" *ngIf="logID == null" [@fadeInOut]>
            <div class="w-100 p-2 d-flex justify-content-center">
              <div class="form-title">Sign in</div>
              <div class="py-5"></div>
            </div>
            <form [formGroup]="loginFormFromAD" (submit)="submitHandler($event)">
              <div class="w-100 px-1 py-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Username</mat-label>
                  <input matInput placeholder="Username" formControlName="username" required>
                  <mat-error *ngIf="loginFormFromAD.controls['username'].invalid">Please fill your username</mat-error>
                </mat-form-field>
              </div>
              <div class="w-100 px-1 py-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Password</mat-label>
                  <input matInput placeholder="Password" type="password" formControlName="password" required>
                  <mat-error *ngIf="loginFormFromAD.controls['password'].invalid">Please fill your password</mat-error>
                </mat-form-field>
              </div>
              <div class="w-100 px-2">
                <button type="submit" mat-raised-button color="main-button" class="w-100">Sign in</button>
              </div>
            </form>
            <div class="w-100 py-3"></div>
          </div>

          <div class="w-100 form-wrapper-animated" *ngIf="logID != null" [@fadeInOut]>
            <div class="w-100 p-2 d-flex justify-content-center">
              <div class="form-title">Verify OTP code</div>
              <div class="py-5"></div>
            </div>
            <form [formGroup]="otp" (submit)="verifyOTP($event)">
              <div class="d-flex justify-content-center pb-3">
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.first.invalid }" name="otp-1" formControlName="first" id="otp-1" min="0" max="1" #first (input)="focusNextElement($event, second)" required>
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.second.invalid }" name="otp-2" formControlName="second" id="otp-2" min="0" max="1" #second (input)="focusNextElement($event, third)" required>
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.third.invalid }" name="otp-3" formControlName="third" id="otp-3" min="0" max="1" #third (input)="focusNextElement($event, forth)" required>
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.forth.invalid }" name="otp-4" formControlName="forth" id="otp-4" min="0" max="1" #forth (input)="focusNextElement($event, fifth)" required>
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.fifth.invalid }" name="otp-5" formControlName="fifth" id="otp-4" min="0" max="1" #fifth (input)="focusNextElement($event, sixth)" required>
                <input type="number" class="otp-input mx-1" [ngClass]="{ otp_error: otp.controls.sixth.invalid }" name="otp-6" formControlName="sixth" id="otp-4" min="0" max="1" #sixth (input)="focusNextElement($event, sixth)" required>
              </div>
              <div class="w-100 row">
                <div class="p-2 col-12 col-md-6 w-100">
                  <button type="button" mat-raised-button color="main-cancel-button" class="w-100" (click)="cancelHandler()">Cancel</button>
                </div>
                <div class="p-2 col-12 col-md-6 w-100">
                  <button type="submit" mat-raised-button color="main-button" class="w-100">Verify</button>
                </div>
              </div>
       
            </form>
          
            <div class="w-100 py-3"></div>
       
          </div>
      
        </div>
      </div>
     
    </div>



  </div>

</div>

