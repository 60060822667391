import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Observable } from 'rxjs';
import { Config } from "../../../config/config";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class OtherDetailsService {
  private readonly apiUrl: string;

  //dev
  private readonly contractDetailsURL: string;


  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient
  ) {

    //dev
    this.contractDetailsURL = this.getContractDetailsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  //API URL-private
  private getContractDetailsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return url;
  }

  // http://localhost:8080/cf-crm/other-details/client/2243006059/active
  // /cf-crm/other-details/client/active/198111304864
  getActiveDetails(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/active/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/normal
  // /cf-crm/other-details/client/normal/198111304864
  getNormalDetails(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/normal/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/writeoff
  // /cf-crm/other-details/client/writeoff/198111304864
  getWriteOffDetais(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/writeoff/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/repossess.
  // /cf-crm/other-details/client/repossess/198111304864
  getRepossessDetais(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/repossess/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/reschedule
  // /cf-crm/other-details/client/reschedule/198111304864
  getRescheduleDetais(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/reschedule/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/transferred/198111304864
  getDetaisTransferred(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/transferred/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/refinance
  // /cf-crm/other-details/client/refinance/198111304864
  getRefinanceDetais(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/refinance/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/guaranteed
  // /cf-crm/other-details/client/guaranteed/198111304864
  getGuaranteedDetais(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client/guaranteed/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client/198111304864/partner
  // getPartnerDetais(contractNo: string): Observable<any> {
  //   let jobURL: string = this.apiUrl + '/other-details/client/' + contractNo + '/partner';
  //   console.debug("URL : ", jobURL);
  //   return this.http.get<any>(jobURL);
  // }

  // /cf-crm/other-details/client/198111304864/partner
  // getGrouprelatedNormalClosureDetails(contractNo: string): Observable<any> {
  //   let jobURL: string = this.apiUrl + '/other-details/client/' + contractNo + '/partner';
  //   console.debug("URL : ", jobURL)
  //   return this.http.get<any>(jobURL);
  // }

  getAllGRArrears(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/' + contractNo + '1/';
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  get0To3GRArrears(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/' + contractNo + '2/';
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  get3To6GRArrears(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/' + contractNo + '3/';
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  getThan6GRArrears(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/' + contractNo + '4/';
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  //client contr detail table (The secondry table)

  // /cf-crm/other-details/client-det/active/198111304864
  // Get other details facility list active
  getODFacListActive(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/active/' + contractNo;
    console.debug("URL : ", jobURL)
    // alert(contractNo)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/normal/198111304864
  // Get other details facility list normal
  getODFacListNormal(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/normal/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/writeoff/198111304864
  // Get other details facility list writeoff
  getODFacListWriteOff(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/writeoff/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/repossess/198111304864
  // Get other details facility list repossess
  getODFacListRepossess(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/repossess/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/reschedule/198111304864
  // Get other details facility list reschedule
  getODFacListReschedule(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/reschedule/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/transferred/198111304864
  // Get other details facility list transferred
  getODFacListTransferred(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/transferred/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/refinance/198111304864
  // Get other details facility list refinance
  getODFacListRefinance(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/refinance/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }

  // /cf-crm/other-details/client-det/guaranteed/198111304864
  // Get other details facility list guaranteed
  getODFacListGuaranteed(contractNo: string): Observable<any> {
    let jobURL: string = this.apiUrl + '/other-details/client-det/guaranteed/' + contractNo;
    console.debug("URL : ", jobURL)
    return this.http.get<any>(jobURL);
  }
}
