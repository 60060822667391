import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Observable } from 'rxjs';
import { Config } from "../../../config/config";

// Notepad Service - Bilesh Sashin (2023/11/06)
@Injectable({
  providedIn: 'root'
})
export class NotepadEntryService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient
  ) {
    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl1;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl1;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl1;
    } else {
      return recoveryConfig.recoveryServiceUrl1;
    }
  }

  getNotepadHistory(contractNo: string): Observable<any> {
    const url = `${this.apiUrl}/notes/${contractNo}`;
    return this.http.get<any>(url);
  }
}
