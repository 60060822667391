import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TransactionSummaryComponent } from '../transaction-summary/transaction-summary.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OtherDetailsService } from 'src/app/services/otherDetailsService/otherDetails.service';
import { MatTableDataSource } from '@angular/material/table';
import { OtherDetailsModel } from 'src/app/models/other-details/OtherDetailModel';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GrouprelatedService } from 'src/app/services/grouprelated/grouprelated.service'
import { Arears } from 'src/app/models/grouprelated/arears.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-other-details',
  templateUrl: './other-details.component.html',
  styleUrls: ['./other-details.component.scss']
})

export class OtherDetailsComponent implements OnInit {
  selectedPage: string = ''; // Variable to hold the selected page value
  contractNo: string;
  ContractData: any;
  dataSource = new MatTableDataSource<OtherDetailsModel>();
  title: string = 'Other Details';
  list: string[] = ['contactno'];
  NIC: string;
  GPCODE: string;
  group_list: any[];
  idListOptions: { id: string; name: string; }[] = [];
  gpcodelist: { id: string; name: string; }[] = [];
  selectedOption: any;
  // NIC: string;
  // GPCODE: string = "GP119"
  // group_list: any[];
  dataSources = new MatTableDataSource<Arears>([]);
  displayedColumns: string[] = ['contract', 'month', 'amount',];

  onPageChange() {
    // Navigate to the selected page or perform any other action
    console.log('Selected page:', this.selectedPage);
    this.router.navigate([this.selectedPage]);
    // Add navigation logic here using Angular Router
  }

  constructor(private dialogRef: MatDialogRef<TransactionSummaryComponent>,
    private GrouprelatedService: GrouprelatedService,
    private router: Router, private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private OtherDetailsService: OtherDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private grouprelatedService: GrouprelatedService) {
    this.dataSource = new MatTableDataSource<OtherDetailsModel>();
  }

  ngOnInit(): void {
    // this.getFacilityList();
    this.getGroupRelatedActiveDet();
    this.getGroupRelatedGuaranteedDet();
    this.getGroupRelatedNormalCloursesDet();
    this.getGroupRelatedRepossessDet();
    this.getGroupRelatedActive();
    this.getGroupRelatedGuaranteed();
    this.getGroupRelatedNormalClourses();
    this.getGroupRelated();
    this.handlePassedData();
  }

  handlePassedData() {
    this.contractNo = this.data.contractNo;
    this.NIC = this.data.idNo
    // pass data to other components
    // alert(this.contractNo)
    // alert(this.NIC)
    const data = {
      contractNo: this.contractNo,
      idNo: this.NIC,
    };

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  handleNormalClosure(): void {
    this.OtherDetailsService.getNormalDetails(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("getOtherDetailsData:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);

        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('totAmountFinanced')?.setAttribute('value', data.details);
                document.getElementById('totAmountFinancedValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  handleWriteOff(): void {
    this.OtherDetailsService.getWriteOffDetais(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the WO dataset:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  handleRepossess(): void {
    this.OtherDetailsService.getRepossessDetais(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the repossesess dataset:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  handleReschedule(): void {
    this.OtherDetailsService.getRescheduleDetais(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the Reschedule dataset:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  handleTransferred(): void {
    this.OtherDetailsService.getDetaisTransferred(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the Reschedule dataset:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // getRefinanceDetais
  handleRefinance(): void {
    this.OtherDetailsService.getRefinanceDetais(this.contractNo).subscribe(
      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the Refinance dataset:", dataset);
        document.getElementById('contractNo')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalance')?.setAttribute('value', data.details);
                document.getElementById('stockBalanceValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaid')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaived')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedValues')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  handleGuaranteed(): void {
    this.OtherDetailsService.getGuaranteedDetais(this.contractNo).subscribe(

      (dataset: any) => {
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);
        console.log("handle the Guaranteed data set :", dataset);
        document.getElementById('contractNumber')?.setAttribute('value', this.contractNo);
        if (dataset && dataset.length > 0) {
          // alert()
          // Iterate through the dataset and set the values accordingly
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('NOOFCONTRACTS')?.setAttribute('value', data.details);
                document.getElementById('NOOFCONTRACTSValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('TOTALAMOUNTFINANCED')?.setAttribute('value', data.details);
                document.getElementById('TOTALAMOUNTFINANCEDValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('STOCKBALANCE')?.setAttribute('value', data.details);
                document.getElementById('STOCKBALANCEValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL ARREARS":
                document.getElementById('TOTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('TOTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "RENTAL ARREARS":
                document.getElementById('RENTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('RENTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT ARREARS":
                document.getElementById('DEFAULTARREARS')?.setAttribute('value', data.details);
                document.getElementById('DEFAULTARREARSValues')?.setAttribute('value', data.values);
                break;
              case "INSURANCE ARREARS":
                document.getElementById('INSURANCEARREARS')?.setAttribute('value', data.details);
                document.getElementById('INSURANCEARREARSValues')?.setAttribute('value', data.values);
                break;
              case "ARREARS < 3":
                document.getElementById('ARREARS<3')?.setAttribute('value', data.details);
                document.getElementById('ARREARS<3Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS 3-6":
                document.getElementById('ARREARS3-6')?.setAttribute('value', data.details);
                document.getElementById('ARREARS3-6Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS > 6":
                document.getElementById('ARREARS>6')?.setAttribute('value', data.details);
                document.getElementById('ARREARS>6Values')?.setAttribute('value', data.values);
                break;
            }
          });
        } else if (dataset && dataset.length === 0) {
          // Handle empty dataset
          console.log("No data available for the given contract number");
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  // handlePartner(): void {
  //   this.OtherDetailsService.getPartnerDetais(this.contractNo).subscribe(
  //     (dataset: any) => {
  //       console.log("handle the Partner data set :", dataset);
  //       document.getElementById('contractNumber')?.setAttribute('value', this.contractNo);
  //       if (dataset && dataset.length > 0) {
  //         // Iterate through the dataset and set the values accordingly
  //         dataset.forEach((data: any) => {
  //           switch (data.description) {
  //             case "NO OF CONTRACTS":
  //               document.getElementById('NOOFCONTRACTS')?.setAttribute('value', data.details);
  //               document.getElementById('NOOFCONTRACTSValues')?.setAttribute('value', data.values);
  //               break;
  //             case "TOTAL AMOUNT FINANCED":
  //               document.getElementById('TOTALAMOUNTFINANCED')?.setAttribute('value', data.details);
  //               document.getElementById('TOTALAMOUNTFINANCEDValues')?.setAttribute('value', data.values);
  //               break;
  //             case "MONTHLY COMMITMENT":
  //               document.getElementById('MONTHLYCOMMITMENT')?.setAttribute('value', data.details);
  //               document.getElementById('MONTHLYCOMMITMENTValues')?.setAttribute('value', data.values);
  //               break;
  //             case "STOCK BALANCE":
  //               document.getElementById('STOCKBALANCE')?.setAttribute('value', data.details);
  //               document.getElementById('STOCKBALANCEValues')?.setAttribute('value', data.values);
  //               break;
  //             case "TOTAL ARREARS":
  //               document.getElementById('TOTALARREARS')?.setAttribute('value', data.details);
  //               document.getElementById('TOTALARREARSValues')?.setAttribute('value', data.values);
  //               break;
  //             case "RENTAL ARREARS":
  //               document.getElementById('RENTALARREARS')?.setAttribute('value', data.details);
  //               document.getElementById('RENTALARREARSValues')?.setAttribute('value', data.values);
  //               break;
  //             case "DEFAULT ARREARS":
  //               document.getElementById('DEFAULTARREARS')?.setAttribute('value', data.details);
  //               document.getElementById('DEFAULTARREARSValues')?.setAttribute('value', data.values);
  //               break;
  //             case "INSURANCE ARREARS":
  //               document.getElementById('INSURANCEARREARS')?.setAttribute('value', data.details);
  //               document.getElementById('INSURANCEARREARSValues')?.setAttribute('value', data.values);
  //               break;
  //             case "ARREARS < 3":
  //               document.getElementById('ARREARS<3')?.setAttribute('value', data.details);
  //               document.getElementById('ARREARS<3Values')?.setAttribute('value', data.values);
  //               break;
  //             case "ARREARS 3-6":
  //               document.getElementById('ARREARS3-6')?.setAttribute('value', data.details);
  //               document.getElementById('ARREARS3-6Values')?.setAttribute('value', data.values);
  //               break;
  //             case "ARREARS > 6":
  //               document.getElementById('ARREARS>6')?.setAttribute('value', data.details);
  //               document.getElementById('ARREARS>6Values')?.setAttribute('value', data.values);
  //               break;
  //           }
  //         });
  //       } else if (dataset && dataset.length === 0) {
  //         // Handle empty dataset
  //         console.log("No data available for the given contract number");
  //       }
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }

  // Other details - client - facilitylist table functions (start)

  //facilty data list table feature
  // getFacilityList(): void {
  //   this.OtherDetailsService.getODFacListActive(this.data.contractNo).subscribe(
  //     (data: any) => {
  //       alert()

  //       if (data.length > 0) {
  //         console.debug("otherdetails facility list loaded,", "Data:", data)
  //         alert("otherdetails facility list loaded")
  //         this.dataSource.data = data as OtherDetailsModel[];
  //       }
  //       else if (data.length > 0) {
  //         alert("No records to load.")
  //       }
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }


  // Other details - client - facilitylist table functions (end)

  disableSelect = new FormControl(false);
  // idListOptions: string[] = []; // Define idListOptions to hold the options for the dropdown

  getGroupRelated(): void {
    this.GrouprelatedService.getGroupRelated(this.contractNo).subscribe(
      (data: any[]) => {
        console.log("Data received from service:", data);
        if (data && data.length > 0) {
        
          this.idListOptions = data.map(item => ({ id: item.id_list, name: item.id_list }));
          this.gpcodelist = data.map(gpitem => ({ id: gpitem.gp_code, name: gpitem.gp_code }));
          console.debug(this.gpcodelist)
        } else {
          console.error("Data array is empty or undefined");
        }
      },
      (error: any) => {
        console.error(error);
       
      }
    );
  }
  //GroupRelated Activity
  getGroupRelatedActive(): void {

    this.GrouprelatedService.getActivietyGroup(this.NIC, this.GPCODE).subscribe(
      (dataset: any) => {
        console.log("getOtherDetailsData:", dataset);
        document.getElementById('gp_code')?.setAttribute('value', this.GPCODE);
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);

        if (dataset && dataset.length > 0) {
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('GROUPNOOFCONTRACTS')?.setAttribute('value', data.details);
                document.getElementById('GROUPNOOFCONTRACTSValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('GROUPTOTALAMOUNTFINANCED')?.setAttribute('value', data.details);
                document.getElementById('GROUPTOTALAMOUNTFINANCEDValues')?.setAttribute('value', data.values);
                break;
              case "MONTHLY COMMITMENT":
                document.getElementById('GROUPMONTHLYCOMMITMENT')?.setAttribute('value', data.details);
                document.getElementById('GROUPMONTHLYCOMMITMENTValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('GROUPSTOCKBALANCE')?.setAttribute('value', data.details);
                document.getElementById('GROUPSTOCKBALANCEValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL ARREARS":
                document.getElementById('GROUPTOTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUPTOTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "RENTAL ARREARS":
                document.getElementById('GROUPRENTALARREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUPRENTALARREARSValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT ARREARS":
                document.getElementById('GROUPDEFAULTARREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUPDEFAULTARREARSValues')?.setAttribute('value', data.values);
                break;
              case "INSURANCE ARREARS":
                document.getElementById('GROUPINSURANCEARREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUPINSURANCEARREARSValues')?.setAttribute('value', data.values);
                break;
              case "ARREARS < 3":
                document.getElementById('GROUPARREARS<3')?.setAttribute('value', data.details);
                document.getElementById('GROUPARREARS<3Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS 3-6":
                document.getElementById('GROUPARREARS3-6')?.setAttribute('value', data.details);
                document.getElementById('GROUPARREARS3-6Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS > 6":
                document.getElementById('GROUPARREARS>6')?.setAttribute('value', data.details);
                document.getElementById('GROUPARREARS>6Values')?.setAttribute('value', data.values);
                break;
            }
          });
        }
      }
    )
  }
  getGroupRelatedActiveDet(): void {
    this.GrouprelatedService.getActivietyGroupDet(this.NIC, this.GPCODE).subscribe(
      (data: any) => {
        this.dataSources.data = data as Arears[];
      },
      (error: any) => {
        console.error(error);
      }
    )
  }

  //Group related Normal Clourses
  getGroupRelatedNormalClourses(): void {
    this.GrouprelatedService.getNormalClourses(this.NIC, this.GPCODE).subscribe(
      (dataset: any) => {
        document.getElementById('gp_code')?.setAttribute('value', this.GPCODE);
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);


        if (dataset && dataset.length > 0) {
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('normalnumberOfContracts')?.setAttribute('value', data.details);
                document.getElementById('normalnumberOfContractsValues')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('normaltotAmountFinanced')?.setAttribute('value', data.details);
                document.getElementById('normaltotAmountFinancedValues')?.setAttribute('value', data.values);
                break;

              case "STOCK BALANCE":
                document.getElementById('normalstockBalance')?.setAttribute('value', data.details);
                document.getElementById('normalstockBalanceValues')?.setAttribute('value', data.values);
                break;


              case "DEFAULT PAID":
                document.getElementById('normaldefault')?.setAttribute('value', data.details);
                document.getElementById('normaldefaultValues')?.setAttribute('value', data.details);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('normaldefaultWaived')?.setAttribute('value', data.details);
                document.getElementById('normaldefaultPaidValues')?.setAttribute('value', data.values);
                break;

            }
          });
        }
      }

    )
  }
  getGroupRelatedNormalCloursesDet(): void {
    this.GrouprelatedService.getNormalCloursesDet(this.NIC, this.GPCODE).subscribe(
      (data: any) => {
        this.dataSources.data = data as Arears[];
      },
      (error: any) => {
        console.error(error);
      }
    )
  }

  //Group related Guaranteed
  getGroupRelatedGuaranteed(): void {

    this.GrouprelatedService.getGuaranteed(this.NIC, this.GPCODE).subscribe(
      (dataset: any) => {
        console.log("getOtherDetailsData:", dataset);
        document.getElementById('gp_code')?.setAttribute('value', this.GPCODE);
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);

        if (dataset && dataset.length > 0) {
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('GROUP_GRANT_NO_OF_CONTRACT')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_NO_OF_CONTRACT-Values')?.setAttribute('value', data.values);
                break;
              case "TOTAL AMOUNT FINANCED":
                document.getElementById('GROUP_GRANT_TOTAL_AMOUNT_FINANCED')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_TOTAL_AMOUNT_FINANCED-Values')?.setAttribute('value', data.values);
                break;
              case "MONTHLY COMMITMENT":
                document.getElementById('GROUP_GRANT_MONTHLY_COMMITMENTS')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_MONTHLY_COMMITMENT-Values')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('GROUP_GRANT_STOCK_BALANCE')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_STOCK_BALANCE-Values')?.setAttribute('value', data.values);
                break;
              case "TOTAL ARREARS":
                document.getElementById('GROUP_GRANT_TOTAL_AREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_TOTAL_AREARS-Values')?.setAttribute('value', data.values);
                break;
              case "RENTAL ARREARS":
                document.getElementById('GROUP_GRANT_RENTAL_AREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_RENTAL_AREARS-Values')?.setAttribute('value', data.values);
                break;
              case "DEFAULT ARREARS":
                document.getElementById('GROUP_GRANT_DEFAULT_AREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_DEFAULT_AREARS-Values')?.setAttribute('value', data.values);
                break;
              case "INSURANCE ARREARS":
                document.getElementById('GROUP_GRANT_INSURANCE_AREARS')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_INSURANCE_AREARS-Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS < 3":
                document.getElementById('GROUP_GRANT_AREARS<3')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_AREARS<3-Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS 3-6":
                document.getElementById('GROUP_GRANT_AREARS-3-6')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_AREARS-3-6-Values')?.setAttribute('value', data.values);
                break;
              case "ARREARS > 6":
                document.getElementById('GROUP_GRANT_AREARS>6')?.setAttribute('value', data.details);
                document.getElementById('GROUP_GRANT_AREARS>6-Values')?.setAttribute('value', data.values);
                break;
            }
          });
        }
      }
    )
  }
  getGroupRelatedGuaranteedDet(): void {
    this.GrouprelatedService.getGuaranteedDet(this.NIC, this.GPCODE).subscribe(
      (data: any) => {
        this.dataSources.data = data as Arears[];
      },
      (error: any) => {
        console.error(error);
      }
    )
  }
  //Group related Repossess
  getGroupRelatedRepossess(): void {
    this.GrouprelatedService.getRepossess(this.NIC, this.GPCODE).subscribe(
      (dataset: any) => {
        document.getElementById('gp_code')?.setAttribute('value', this.GPCODE);
        document.getElementById('idListddd')?.setAttribute('value', this.NIC);


        if (dataset && dataset.length > 0) {
          dataset.forEach((data: any) => {
            switch (data.description) {
              case "NO OF CONTRACTS":
                document.getElementById('numberOfContractsrepossess')?.setAttribute('value', data.details);
                document.getElementById('numberOfContractsrepossessValues')?.setAttribute('value', data.values);
                break;
              case "STOCK BALANCE":
                document.getElementById('stockBalancerepossess')?.setAttribute('value', data.details);
                document.getElementById('stockBalancerepossessValues')?.setAttribute('value', data.values);
                break;
              case "DEFAULT PAID":
                document.getElementById('defaultPaidrepossess')?.setAttribute('value', data.details);
                document.getElementById('defaultPaidrepossessValues')?.setAttribute('value', data.details);
                break;
              case "DEFAULT WAIVED":
                document.getElementById('defaultWaivedrepossess')?.setAttribute('value', data.details);
                document.getElementById('defaultWaivedrepossessValues')?.setAttribute('value', data.values);
                break;

            }
          });
        }
      }

    )

  }
  getGroupRelatedRepossessDet(): void {
    this.GrouprelatedService.getRepossessDet(this.NIC, this.GPCODE).subscribe(
      (data: any) => {
        this.dataSources.data = data as Arears[];
      },
      (error: any) => {
        console.error(error);
      }
    )
  }

  // Group related active all
  // getActivityAll():void{
  //   this.GrouprelatedService.getActiveAllGRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //       // alert(this.dataSources);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }
  //Group related 3<6

  // getActivity3lessthan6():void{
  //   this.GrouprelatedService. getActive0To3GRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   )
  // }
  //Group related  3- 6
  // getActivity3to6():void{
  //   this.GrouprelatedService. getActive3To6GRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   )
  // }
  //Group related  3>6
  // getAcivity3GreaterThan6():void{
  //     this.GrouprelatedService. getActiveThan6GRArrears(this.NIC).subscribe(
  //       (data: any) =>{
  //         this.dataSources.data = data as Arears[];
  //       },
  //       (error: any) => {
  //         console.error(error);
  //       }
  //     )
  //   }

  //Group related Guaranteed Arreares

  // getGuaranteedAll():void{
  //   this.GrouprelatedService.getGuaranteedArrearesAll(this.NIC).subscribe(
  //     (data: any) =>{
  //       console.log("dats is: ", data);
  //        document.getElementById('idListddd')?.setAttribute('value',this.NIC);
  //        if(data && data.length > 0) {
  //         const idList = data[0].facno;
  //         document.getElementById('contractNo')?.setAttribute('value', idList);
  //        }else{
  //         console.error("Data array is empty or undefined");
  //        }
  //     }
  //   )
  // }
  // getGuaranteed3lessthan6():void{
  //   this.GrouprelatedService.getGuaranteed0To3GRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   )
  // }
  // getGuaranteed3to6():void{
  //   this.GrouprelatedService.getGuaranteed3To6GRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   )
  // }
  // getGuaranteed3GreaterThan6():void{
  //   this.GrouprelatedService.getGuaranteedThan6GRArrears(this.NIC).subscribe(
  //     (data: any) =>{
  //       this.dataSources.data = data as Arears[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   )
  // }

}
const ELEMENT_DATA: Arears[] = [];
