import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerContractListComponent } from '../customer-contract-list/customer-contract-list.component';
import { FacilityInquiryComponent } from '../facility-inquiry/facility-inquiry.component';
import { PopUpOpenNotepadComponent } from '../pop-up-open-notepad/pop-up-open-notepad.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TransactionSummaryComponent } from '../transaction-summary/transaction-summary.component';
import { OtherDetailsComponent } from '../other-details/other-details.component';
import { RentalInformationModel } from 'src/app/models/single-contract-details/rental-information-model.model';
import { SingleContarctOutstandingService } from 'src/app/services/single-contract-details/single-contarct-outstanding.service';




@Component({
  selector: 'app-single-contract-detail-view',
  templateUrl: './single-contract-detail-view.component.html',
  styleUrls: ['./single-contract-detail-view.component.scss']
})
export class SingleContractDetailViewComponent implements OnInit {

  // contractNo = "2243006059";
  // cType = "CR";
  contractNo: string;
  strdate: string;
  In_Bal_Nof_Rentls: string;

  displayedColumnsRentalInfor: string[] = ['rentals', 'grossAmount', 'capitalAmount', 'capitaPaid', 'interestAmount', 'interestPaid', 'gstDue', 'gstPaid', 'stampDuty', 'grossPaid', 'dueDate', 'status', 'balance'];


  dataSource = new MatTableDataSource<RentalInformationModel>(ELEMENT_DATA);

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'out'];


  readonly headerTitle: string = "Single Contract Detail View";
  checkbox1: boolean = false;
  checkbox2: boolean = true;
  checkbox3: boolean = false;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private dialogRef: MatDialogRef<SingleContractDetailViewComponent>, private dialog: MatDialog, private singleContarctOutstandingService: SingleContarctOutstandingService) { }

  ngOnInit(): void {
    // this.getRentalInformation();
  }

  // close model function
  hanldeClose() {
    this.dialogRef.close();
  }

  //Qustion checkbox
  onCheckboxChange(checkboxNumber: number) {
    switch (checkboxNumber) {
      case 1:
        // Handle changes for Checkbox 1
        console.log('Checkbox 1 is now ' + this.checkbox1);
        break;
      case 2:
        // Handle changes for Checkbox 2
        console.log('Checkbox 2 is now ' + this.checkbox2);
        break;
      case 3:
        // Handle changes for Checkbox 3
        console.log('Checkbox 3 is now ' + this.checkbox3);
        break;
    }
  }


  openSingleContract() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(SingleContractDetailViewComponent, dialogConfig);
  }


  openNotepad() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.data.contractNo,
      strdate: this.data.strdate,
      In_Bal_Nof_Rentls: this.data.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(PopUpOpenNotepadComponent, {
      data: data,
    });
  }


  facilityInquiry() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.data.contractNo,
      strdate: this.data.strdate,
      In_Bal_Nof_Rentls: this.data.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(FacilityInquiryComponent, dialogConfig);
    dialogRef.componentInstance.data = data;
  }

  // Transaction Summary open Button - Bilesh Sashin (2023/11/07)
  openTransaction() {
    // const user_name = this.auth.currentUserValue.username;
    const data = {
      contractNo: this.data.contractNo,
      strdate: this.data.strdate,
      In_Bal_Nof_Rentls: this.data.In_Bal_Nof_Rentls,
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(TransactionSummaryComponent, dialogConfig);
    dialogRef.componentInstance.data = data;
  }

  // Open other details view
  openOtherDetails() {
    // const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(OtherDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  // getRentalInformation(): void {
  //   this.singleContarctOutstandingService.getRentalInformation(this.contractNo).subscribe(
  //     (data: any) => {
  //       this.dataSource.data = data as RentalInformationModel[];
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // }


  
}

const ELEMENT_DATA: RentalInformationModel[] = [];
