import { RouterModule, Routes } from '@angular/router';
import { UserLoginComponent } from "./components/login/user-login/user-login.component";
import { CreateUserRolesComponent } from "./components/user-registration/create-user-roles/create-user-roles.component";
import { AuthGuardGuard } from './shared/AuthGuard/auth-guard.guard';
import { HomeComponentV2Component } from './components/home-component-v2/home-component-v2.component';
import { ContractInformationComponent } from './components/single-contract-detail-view/contract-information/contract-information.component';
import { ReportSummaryDashboardComponent } from './components/report-summary-dashboard/report-summary-dashboard.component';
import { LeadCreationComponent } from './components/lead-creation/lead-creation.component';
import { InfacilideatailsComponent } from './components/infacilideatails/infacilideatails.component';
import { InReportSummaryComponent } from './components/in-report-summary/in-report-summary.component';
import {InleadCreationComponent} from './components/inlead-creation/inlead-creation.component'
export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponentV2Component,
    canActivate: [AuthGuardGuard]
  },
  {
    path:'reportSummary/:facNumber',
    component: InReportSummaryComponent,
  },
  {
    path: 'inreportSummary/:facNumber',
    component: ReportSummaryDashboardComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'infacilityDetails/:facNumber',
    component: ContractInformationComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path:'facilityDetails/:facNumber',
    component:InfacilideatailsComponent,
    
  },
  {
    path:'misscallleedCreation',
    component:LeadCreationComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path:'leadCreation/:facNumber',
     component:InleadCreationComponent,
  },

  {
    path: 'inleadCreation/:contactNumber',
    component: LeadCreationComponent,
    canActivate: [AuthGuardGuard]
    // :contactNumber
  },
  
  {
    path: 'create-user-role',
    component: CreateUserRolesComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'login',
    component: UserLoginComponent
  },
  { path: '**', redirectTo: 'login' }
];

