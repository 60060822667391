import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdleService } from 'src/app/idle.service';

import { User } from 'src/app/models/user/user-model';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
  private readonly userAuthJobsUrl: string;
  private readonly openInquiryServiceUrl: string;
  private readonly CRMNotePadviewServiceUrl: string;
  private readonly ClientExposureServiceUrl: string;
  private readonly CRMTransactionSummeryServiceUrl: string;

  // auth subjects
  private readonly apiUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private rest: RestRequestService, private idleService: IdleService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {
    this.userAuthJobsUrl = this.getUserAuthJobsUrl(recoveryConfig);
    this.openInquiryServiceUrl = this.getOpenInquiryServiceUrl(recoveryConfig);
    this.CRMNotePadviewServiceUrl = this.getCRMNotePadviewServiceUrl(recoveryConfig);
    this.CRMTransactionSummeryServiceUrl = this.getCRMTransactionSummeryServiceUrl(recoveryConfig);
    this.ClientExposureServiceUrl = this.getClientExposureServiceUrl(recoveryConfig);
    this.apiUrl = recoveryConfig.appServerUrl1;

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('branchless-user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
     * Get User Auth job service Url from User injectable object.
     * @param recoveryConfig
     * @returns userAuthUrl:string
     */
  private getUserAuthJobsUrl(recoveryConfig: any = {}): string {
    let userAuthUrl = '';

    if (recoveryConfig.env === 'local') {
      userAuthUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      userAuthUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return userAuthUrl;
  }

  private getOpenInquiryServiceUrl(recoveryConfig: any = {}): string {
    let openInquiryServiceUrl = '';

    openInquiryServiceUrl = `${recoveryConfig.openInquiryServiceUrl}`;

    return openInquiryServiceUrl;
  }

  private getCRMNotePadviewServiceUrl(recoveryConfig: any = {}): string {
    let CRMNotePadviewServiceUrl = '';

    CRMNotePadviewServiceUrl = `${recoveryConfig.CRMNotePadviewServiceUrl}`;

    return CRMNotePadviewServiceUrl;
  }

  private getCRMTransactionSummeryServiceUrl(recoveryConfig: any = {}): string {
    let CRMTransactionSummeryServiceUrl = '';

    CRMTransactionSummeryServiceUrl = `${recoveryConfig.CRMTransactionSummeryServiceUrl}`;

    return CRMTransactionSummeryServiceUrl;
  }

  private getClientExposureServiceUrl(recoveryConfig: any = {}): string {
    let ClientExposureServiceUrl = '';

    ClientExposureServiceUrl = `${recoveryConfig.ClientExposureServiceUrl}`;

    return ClientExposureServiceUrl;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  userLogin(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.userAuthJobsUrl}/vUserLogin`;
    return this.rest.post<any>(url,
      {
        EMAIL: email,
        PASSWORD: password
      },
      {
        headers: headers,
      }).pipe(
        map(user => {
          let loggedInUser;
          if (user) {
            console.log(user);
            loggedInUser = User.fromJSON(user);
            localStorage.setItem('branchless-user', JSON.stringify(loggedInUser));
            this.currentUserSubject.next(loggedInUser);
          }

          return loggedInUser;
        })
      );
  }

  openInquiryService(OPPORTUNITYID: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    // console.log (this.openInquiryServiceUrl);
    let jobURL: string = this.openInquiryServiceUrl + `${OPPORTUNITYID}`;
    // console.log (OPPORTUNITYID);
    // console.log (jobURL);
    console.log("URL   " + jobURL);
    window.open(jobURL, '_blank')

  }

  openNotepadService(FACNO: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.CRMNotePadviewServiceUrl + `${FACNO}`;
    console.log("URL   " + jobURL);
    window.open(jobURL, '_blank')

  }

  CRMTransactionSummery(OPPORTUNITYID: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.CRMTransactionSummeryServiceUrl + `${OPPORTUNITYID}`;
    console.log("URL   " + jobURL);
    window.open(jobURL, '_blank')

  }

  ClientExposure(IDNO: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.ClientExposureServiceUrl + `${IDNO}`;
    console.log("URL   " + jobURL);
    window.open(jobURL, '_blank')

  }

  userLoginVerificationInit(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.userAuthJobsUrl}/vUserADLogin`;
    return this.rest.post<any>(url,
      {
        EMAIL: username,
        PASSWORD: password
      },
      {
        headers: headers,
      });
  }

  userVerifyOTP(username: string, otp: string, logID: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.apiUrl}/login/getOtp/${username}/${otp}/${logID}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
      }).pipe(
        map(value => {
          // if (value?.content[0]?.SMG == "LOGINSUCCESS") {
            if (value[0]?.smg == "LOGINSUCCESS") {
            let loggedInUser: any = User.fromJSON(value);
            loggedInUser = {
              ...loggedInUser,
              username: username
            }
            console.log("login user " + loggedInUser)
            localStorage.setItem('branchless-user', JSON.stringify(loggedInUser));
            this.currentUserSubject.next(loggedInUser);

            return {
              isLoggedIn: true
            }
          } else {
            return {
              isLoggedIn: false
            }
          }
        })
      );
  }

  logout(router: Router) {
    localStorage.removeItem('branchless-user');
    this.currentUserSubject.next(null);
    router.navigateByUrl('/login');
  }
}
