<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
  <div id="loadingView">
    <img
      class="loadingGif"
      src="../../../../assets/loading.gif"
      alt="loadingLogo"
    />
  </div>
</div>
<!-- loadingView -->
<app-dialog-header
  [title]="headerTitle"
  (closeDialogEvent)="hanldeClose()"
></app-dialog-header>
<div class="py-4" style="position: relative">
  <!-- Temp button for single contract view -->


  <div>
    <div>
      <section>
        <mat-checkbox
          [(ngModel)]="enableSearch"
          (change)="handleSearchEnable($event)"
          >Enable search</mat-checkbox
        >
      </section>
    </div>
    <div class="search-container d-flex align-items-baseline">
      <div class="col w-auto">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Search</mat-label>
          <mat-placeholder>Search reports</mat-placeholder>
          <input
            matInput
            type="text"
            [(ngModel)]="search"
            [disabled]="!enableSearch"
          />
          <mat-select matSuffix [(ngModel)]="searchType">
            <mat-option value="Vehicle">Vehicle</mat-option>
            <mat-option value="Contract">Contract</mat-option>
            <mat-option value="NIC">NIC</mat-option>
            <mat-option value="Chassi">Chassi</mat-option>
            <mat-option value="sss">Name</mat-option>
          </mat-select>
          <mat-hint align="start"
            ><strong
              >Select a search type from the dropdown icon</strong
            ></mat-hint
          >
          <mat-hint align="end">Search by: {{ searchType }}</mat-hint>
        </mat-form-field>
      </div>
      <div class="px-2">
        <button
          [disabled]="search.length < 1 || !enableSearch"
          mat-mini-fab
          color="primary"
          (click)="searchCallList()"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <br />
  <div>
    <div class="w-100 card-container my-2">
      <div class="card-header w-100 p-2">
        <span>Customer Information</span>
      </div>
      <div class="card-content p-2 w-100">
        <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">NAME</div>
            <div class="col-12 card-content-description" >

              <input type="text" id="name" name="name" disabled/>
            </div>
          </div>
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">NIC</div>
            <div class="col-12 card-content-description" >
              <input type="text" id="nic" name="nic" disabled/>
            </div>
          </div>
        </div>
        <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">


          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">CONTACT NUMBER</div>
            <div class="col-12 card-content-description" >
              <input type="text" id="contactNo" name="contactNo" disabled/>
            </div>
          </div>
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">E-MAIL</div>
            <div class="col-12 card-content-description" >
              <input type="text" id="mail" name="mail" disabled/>
            </div>
          </div>
        </div>

        <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">ADDRESS</div>
            <div class="col-12 card-content-description" >
              <input type="text" id="address" name="address" disabled/>
            </div>
          </div>
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">OCCUPATION</div>
            <div class="col-12 card-content-description">
              <input type="text"  id="testOccupation" name="testOccupation" disabled/>
            </div>
          </div>
          <div class="col-12 col-md-6 py-1 py-md-2">
            <div class="col-12 card-content-leading">POSTAL CODE</div>
            <div class="col-12 card-content-description" >
              <input type="text"  id="testPostal" name="testPostal" disabled/>
            </div>
          </div>

          <div class="col-12 col-md-6 py-md-2">

              <div class="col-12 card-content-leading">
                <mat-card-content>
                  <div class="col-12 card-content-leading">BRANCH</div>
                  <div class="col-12 card-content-description" >
                    <input type="text"  id="branch" name="baranch" disabled/>
                  </div>
                </mat-card-content>
              </div>

          </div>
      </div>
    </div>
  </div>
  <br/>

  <button mat-button class="custom-button" (click)="openSingleContract()">
     Report Summary View
  </button>

  <div class="row" style="padding-top: 0.5rem">
    <div class="col-12" style="padding-top: 10px; z-index: 0">
      <angular-slickgrid
        gridId="grid2"
        style="width: 95%"
        [columnDefinitions]="columnDefinitions"
        [gridOptions]="gridOptions"
        [dataset]="dataset"
      >
      </angular-slickgrid>
    </div>
  </div>

</div>
