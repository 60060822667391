import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotepadEntryService } from 'src/app/services/notepad-entry/notepad-entry.service';
import { NotepadEntry } from 'src/app/models/notepad-entry/notepad-entry.model';

// Notepad Details - Bilesh Sashin (2023/11/06)

@Component({
  selector: 'app-pop-up-open-notepad',
  templateUrl: './pop-up-open-notepad.component.html',
  styleUrls: ['./pop-up-open-notepad.component.scss']
})
export class PopUpOpenNotepadComponent implements OnInit {
  contractNo: string;
  // cType: string;
  appNo: string;

  notepadHistory: any;
  dataSource: MatTableDataSource<NotepadEntry>;
  displayedColumns: string[] = ['serno', 'usrid', 'dte_of_nt', 'descr'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private notepadEntryService: NotepadEntryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<PopUpOpenNotepadComponent>
  ) {
    this.dataSource = new MatTableDataSource<NotepadEntry>();
    this.contractNo = data.contractNo;
    // this.cType = data.cType;
  }

  title: string = 'Notepad Details';

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.getNotepadData();
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getNotepadData(): void {
    this.notepadEntryService.getNotepadHistory(this.contractNo).subscribe(
      (data: any) => {
        console.log("dats is: ", data);
        this.notepadHistory = data;
        this.dataSource.data = data as NotepadEntry[];
        // console.log(data + "data");
        console.log("Popup notepad data loaded")
        // console.log(this.notepadHistory + "history");

        if (data.length > 0) {
          this.appNo = data[0].appNo;
        }
        else if (data.length > 0) {
          this.contractNo = data[0].conractNo;
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

}

// interface NotepadEntry {
//   contractNo?: string;
//   cType?: string;

//   appNo?: string;
//   serno?: string;
//   usrid?: string;
//   dte_of_nt?: string;
//   descr?: string;
// }
