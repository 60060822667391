<div class="navbar w-100">
    <div class="navbar-leading">
        <img class="navbar-img" src="../../../assets/cf-logo.jpg" alt="logo">
        <h5 class="navbar-title">CF CRM</h5>
    </div>
    <div class="navbar-options">
        <mat-icon *ngIf="loggedIn" class="close-icon" matTooltip="Logout" appearance="fill" color="primary" (click)="logout()">logout</mat-icon>
    </div>
</div>
<div class="first-container"></div>
