import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user-model';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { CustomerContractListComponent } from '../customer-contract-list/customer-contract-list.component';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { PopUpOpenNotepadComponent } from '../pop-up-open-notepad/pop-up-open-notepad.component';
import { TransactionSummaryComponent } from '../transaction-summary/transaction-summary.component';
import { OtherDetailsComponent } from '../other-details/other-details.component';
import { FacilityInquiryComponent } from '../facility-inquiry/facility-inquiry.component';
import { ReportSummaryDashboardComponent } from '../report-summary-dashboard/report-summary-dashboard.component';
import { ContractInformationComponent } from '../single-contract-detail-view/contract-information/contract-information.component';
import { LeadCreationComponent } from '../lead-creation/lead-creation.component';

@Component({
  selector: 'app-home-component-v2',
  templateUrl: './home-component-v2.component.html',
  styleUrls: ['./home-component-v2.component.scss']
})
export class HomeComponentV2Component implements OnInit {

  // contractNo = "2243006059";
  // cType = "CR";

  constructor(private snackbar: MatSnackBar, private auth: UserAuthenticationService, private dialog: MatDialog, private authService: SocialAuthService, private router: Router) { }

  user: string = "";
  currentUser: User = null
  currentUserSubscription: Subscription = null

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.user_id
    this.currentUserSubscription = this.auth.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    )
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  };

  openCallCenterDash() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(CustomerContractListComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  // Open facility details view
  facilityDetails() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(ContractInformationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  lead_creation() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(LeadCreationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }


  // Admin portal open Button - Bilesh Sashin (2023/11/06)
  adminPortel() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(AdminPortalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );

  }

  // Notepad open Button - Bilesh Sashin (2023/11/06)
  openNotepad() {
    const user_name = this.auth.currentUserValue.username;
    // const data = {
    //   contractNo: this.contractNo,
    //   cType: this.cType,
    // };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(PopUpOpenNotepadComponent, {
      // data: data,
    });
  }

  // Transaction Summary open Button - Bilesh Sashin (2023/11/07)
  openTransaction() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(TransactionSummaryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );

  }

  // Open other details view
  openOtherDetails() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(OtherDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }


  openSummartDashboard() {
    const user_name = this.auth.currentUserValue.username;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(ReportSummaryDashboardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );

  }

  logout() {
    this.authService.signOut();
    this.auth.logout(this.router);
  }
}
