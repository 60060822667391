import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractNoService } from '../../services/contractNo/contract-no.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inlead-creation',
  templateUrl: './inlead-creation.component.html',
  styleUrls: ['./inlead-creation.component.scss']
})
export class InleadCreationComponent implements OnInit {

  contractNo: string;
  constructor(private router: Router,private route: ActivatedRoute, private contractService: ContractNoService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.contractNo = params.get('facNumber');
      console.log("favNumber", this,this.contractNo)
      if (this.contractNo) {
        
        localStorage.setItem('contractNo', this.contractNo);
        this.contractService.setContractNo(this.contractNo);
        console.log('Contract Number from local storage:', this.contractNo);
        this.router.navigateByUrl(`/inleadCreation/${this.contractNo}`);
      
      }

    });

  }

}
