import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private data: any;

  setData(data: any, contractNo: string, idNo: string) {
    this.data = { ...data, contractNo, idNo };
  }

  getData() {
    return this.data;
  }
}
