<table class="table">
    <thead>
      <tr class="table-primary ">
        <th scope="col" class="table_col">Rentals</th>
        <th scope="col" class="table_col">Gross Amount</th>
        <th scope="col" class="table_col">Capital Amount</th>
        <th scope="col" class="table_col">Interest Amount</th>
        <th scope="col" class="table_col">Interest (New)</th>
        <th scope="col" class="table_col">Stamp Duty</th>
        <th scope="col" class="table_col">Balance</th>
        <th scope="col" class="table_col">Balance (New)</th>
        <th scope="col" class="table_col">Due Date</th>
        <th scope="col" class="table_col">Status</th>
        <th scope="col" class="table_col">Capital Paid</th>
        <th scope="col" class="table_col">Interest Paid</th>
        <th scope="col" class="table_col">GST Paid</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>

      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
      <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
       <tr>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0</td>
        <td>0</td>
        <td>0.00</td>
        <td>0.00</td>
      </tr>
    </tbody>
  </table>
